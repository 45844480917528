import { SET_CUSTOMER_SERVICE } from './constants';
import { WithCustomerServiceType } from './types';

export const initialState: WithCustomerServiceType = {
  contactChannel: '',
  isPhoneEnabled: true,
  isEmailEnabled: true,
  phoneNumber: '',
  emailAddress: '',
  isAllContactChannel: true,
  privacyProtectionEmailAddress: '',
  privacyProtectionPostalAddress: '',
};

function WithCustomerServiceReducer(state = initialState, action): WithCustomerServiceType {
  switch (action.type) {
    case SET_CUSTOMER_SERVICE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export default WithCustomerServiceReducer;
