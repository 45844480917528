import { Redirect } from 'react-router-dom';
import { lazy } from 'react';

const Prefill = lazy(() => import('src/components/pages/Prefill'));

const redirectTo = ({ condition, component }) => {
  if (!condition || Object.values(condition).every((element) => element === null)) {
    return <Redirect to="/" />;
  }
  return component;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const goToPrefill = (rest: { [x: string]: any }) => {
  if (rest.location.search.includes('?data=')) {
    return <Prefill {...rest} />;
  }
  return <Redirect to="/" />;
};

export { redirectTo, goToPrefill };
