import { SaveEmailRequestType } from 'src/hocs/WithSubscriptionNew/types';

import * as c from './SouscrireNew.constants';
import * as t from './SouscrireNew.types';

export function onUserSubmitAction(
  payload: t.SouscrireFormType,
  formData: t.FormDataSouscrireForm,
): t.OnUserSubmitActionType {
  return {
    type: c.ON_USER_SUBMIT,
    payload,
    meta: { formData },
  };
}

export interface SaveELDSubscriptionActionType {
  type: string;
  payload: SaveEmailRequestType;
}

export function saveELDSubscribeAction(data: SaveEmailRequestType): SaveELDSubscriptionActionType {
  return {
    type: c.ELD_IN_WINTER_BREAK_NON_ELIGIBLE,
    payload: data,
  };
}
