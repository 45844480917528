import { semantical } from 'src/theme/tokens/semantical';
import { customComponents } from 'src/theme/tokens/components/custom';

const { radius } = customComponents;

export const tooltipTheme = {
  boxShadow: semantical.shadows.none,
  background: semantical.colors.background.neutral.weaker,
  iconColor: semantical.colors.border.interaction.active,
  iconTextColor: semantical.colors.text.neutral.strongest,
  textColor: semantical.colors.text.neutral.strongest,
  padding: semantical.spacing.m,
  radius: semantical.radius[radius.tooltip],
} as const;
