import { createSelector } from 'reselect';
import get from 'lodash/get';
import { AppStateType } from 'src/store/appstate.type';

const selectWithForm = (state: AppStateType) => state.WithForm;

const makeSelectWithForm = () => createSelector(selectWithForm, (subState) => subState);

const makeSelectWithFormPage = (pageName) =>
  createSelector(selectWithForm, (subState) => get(subState, pageName));

export const makeSelectFormField = (formName, formField) =>
  createSelector(makeSelectWithFormPage(formName), (formValues = {}) => get(formValues, formField));

export default makeSelectWithForm;
export { makeSelectWithFormPage };
