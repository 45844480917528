export const WITHSERVICES = 'WithServices';
export const SERVICES_BY_FAMILY = 'servicesByFamily';
export const FAMILY_SERVICES = {
  VOLTALIS: 'BOXEFFACEMENT',
  ASSURANCE: 'ASSURANCE',
  REDUCTION: 'REDUCTION',
};

export const SET_SERVICE_ID_LOADING = 'app/WithServices/SET_SERVICE_ID_LOADING';
export const GET_SERVICES_REQUEST = 'app/WithServices/GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'app/WithServices/GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'app/WithServices/GET_SERVICES_FAILURE';
