import { blur } from './blur';
import { colors } from './colors';
import { fonts } from './fonts';
import { breakpoints } from './breakpoints';
import { radius } from './radius';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { spacing } from './spacing';
import { transitions } from './transitions';
import { zindex } from './elevation';
import { customComponents } from '../components/custom';

export const core = {
  blur,
  colors,
  customComponents,
  fonts,
  breakpoints,
  radius,
  shadows,
  sizes,
  spacing,
  transitions,
  zindex,
};
