import type { MyDeliveryReducer } from './MyDelivery.types';
import * as C from './MyDelivery.constants';

export const initialState: MyDeliveryReducer = {
  subscriptionType: null,
  deliveryChoice: '',
  indexIntegrity: null,
  energyCommissioningType: {
    electricity: '',
    gas: '',
  },
};

// Todo: refactoring - replace local redux state by useState (or move to App reducer if not possible)
function myDeliveryReducer(state = initialState, action): MyDeliveryReducer {
  switch (action.type) {
    case C.REQUEST_SUBSCRIPTION_TYPES_SUCCESS:
      return {
        ...state,
        subscriptionType: action.payload,
      };
    case C.REQUEST_INDEX_INTEGRITY_SUCCESS:
      return {
        ...state,
        indexIntegrity: action.payload,
      };
    case C.SAVE_INFO_MES_OPTIONS:
      return {
        ...state,
        energyCommissioningType: {
          electricity: action.payload.electricityRelocationCommissioningType,
          gas: action.payload.gasRelocationCommissioningType,
        },
      };
    default:
      return state;
  }
}

export default myDeliveryReducer;
