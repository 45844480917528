import { useStore } from 'react-redux';

export function useSelectState() {
  const store = useStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (selector: (state: any) => any) => {
    const state = store.getState();
    return selector(state);
  };
}
