import { useState, useEffect } from 'react';

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = (): void => {
      const width = window.innerWidth;

      if (width > 1024) {
        return setIsDesktop(true);
      }
      return setIsDesktop(false);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isDesktop;
};

export default useIsDesktop;
