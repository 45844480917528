import { typedDefineMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

export default typedDefineMessage({
  errorHappened: {
    id: 'app.errorHappened',
    defaultMessage: 'app.errorHappened',
  },
  noPostalCode: {
    id: 'app.errorNoPostalCode',
    defaultMessage: 'Code postal non trouvé, veuillez compléter votre adresse.',
  },
  UNABLE_TO_MODIFY_SUBSCRIPTION: {
    id: 'app.UNABLE_TO_MODIFY_SUBSCRIPTION',
    defaultMessage: 'Impossible de sauvegarder les informations de votre souscription !',
  },
  INVALID_OPTION_HEAD: {
    id: 'app.INVALID_OPTION_HEAD',
    defaultMessage: 'Vous avez choisi des services associés à cette énergie',
  },
  INVALID_OPTION: {
    id: 'app.INVALID_OPTION',
    defaultMessage:
      "Pour supprimer cette énergie, vous devez d'abord supprimer les services associés à l'énergie dans votre panier.",
  },
  NO_COMPATIBLE_ACTIVE_OFFERS: {
    id: 'app.NO_COMPATIBLE_ACTIVE_OFFERS',
    defaultMessage: "Votre offre partenaire n'est plus disponible.",
  },
  PODNonValid: {
    id: 'app.AutoCheckPOD.PODNonValid',
    defaultMessage: '{POD} non Valide !',
  },
  invalidIBAN: {
    id: 'app.modeFacturation.invalidIBAN',
    defaultMessage: 'L’IBAN que vous avez saisi n’est pas valide',
  },
  consumptionTooHighTitle: {
    id: 'app.errorManager.consumptionTooHighTitle',
    defaultMessage: 'La consommation annuelle est très élevée',
  },
  consumptionTooHighIndexMessage: {
    id: 'app.errorManager.consumptionTooHighIndexMessage',
    defaultMessage: 'Êtes-vous sûr que ce n’est pas plutôt l’index ?',
  },
  consumptionTooHighElecMessage: {
    id: 'app.errorManager.consumptionTooHighElecMessage',
    defaultMessage:
      'La consommation annuelle que vous avez renseignée est trop élevée par rapport à la puissance souscrite',
  },
  consumptionTooHighGasMessage: {
    id: 'app.errorManager.consumptionTooHighGasMessage',
    defaultMessage:
      'Nous ne pouvons pas réaliser un devis pour une consommation annuelle supérieure à 300 000kWh…',
  },
  PODAddressMismatch: {
    id: 'app.errorManager.PODAddressMismatch',
    defaultMessage:
      "Attention, pour ce {POD} nous trouvons cette commune : {commune}, souhaitez-vous corriger l'adresse saisie précédemment ou bien renseigner un nouveau {POD} ?",
  },
  modifyAddress: {
    id: 'app.errorManager.modifyAddress',
    defaultMessage: 'Je corrige mon adresse',
  },
  modifyPOD: {
    id: 'app.errorManager.modifyPOD',
    defaultMessage: 'Je rentre un nouveau {POD}',
  },
  noCompatibleOfferTitle: {
    id: 'app.errorManager.noCompatibleOfferTitle',
    defaultMessage:
      'Toutes les bonnes choses ont une fin, c’est le cas de cette offre qui est terminée 😞',
  },
  promoCodeUnavailable: {
    id: 'app.errorManager.promoCodeUnavailable',
    defaultMessage:
      'Aïe, impossible de vérifier si ce code est le bon. On vous invite à continuer pour obtenir un devis et retenter ultérieurement.',
  },
  felicitationsErrorTitle: {
    id: 'app.errorManager.felicitationsErrorTitle',
    defaultMessage: 'Félicitations, vous avez fait un pas de plus vers un mode de vie plus vert !',
  },
  felicitationsErrorMessage1: {
    id: 'app.errorManager.felicitationsErrorMessage1',
    defaultMessage:
      'Votre contrat est bien finalisé mais votre espace client n’a pas pu être créé. Un peu de patience, notre équipe est sur le coup et se charge de tout !',
  },
  felicitationsErrorMessage2: {
    id: 'app.errorManager.felicitationsErrorMessage2',
    defaultMessage: 'Vous recevrez un mail avec vos identifiants très rapidement.',
  },
  subscriptionLockedTitle: {
    id: 'app.errorManager.subscriptionLockedTitle',
    defaultMessage: 'app.errorManager.subscriptionLockedTitle',
  },
  subscriptionLockedBody02: {
    id: 'app.errorManager.subscriptionLockedBody02',
    defaultMessage: 'app.errorManager.subscriptionLockedBody02',
  },
  weCantFindYourAddressEnterZipCode: {
    id: 'app.souscrire.weCantFindYourAddressEnterZipCode',
    defaultMessage:
      'Nous avons des difficultés à localiser votre adresse, merci de saisir votre code postal',
  },
  invalidOldName: {
    id: 'app.consumptionByName.invalidOldName',
    defaultMessage: 'app.consumptionByName.invalidOldName',
  },
});
