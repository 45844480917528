import {
  saveProgression,
  resumeSubscription,
  phoneClick,
  pageChange,
  submitHomepageForm,
  submitEnergyChoiceForm,
  usageEstimatorEvent,
  housingEstimatorEvent,
  submitQuotationSummary,
  quotationHelpClick,
  submitQuotationPOD,
  quotationKnowConsumption,
  occupantNameConsumption,
  emailClick,
  submitSubscriptionPOD,
  meterEstimatorEvent,
} from './events';

export default {
  saveProgression,
  resumeSubscription,
  phoneClick,
  pageChange,
  submitHomepageForm,
  submitEnergyChoiceForm,
  usageEstimatorEvent,
  housingEstimatorEvent,
  submitQuotationSummary,
  quotationHelpClick,
  submitQuotationPOD,
  quotationKnowConsumption,
  occupantNameConsumption,
  emailClick,
  submitSubscriptionPOD,
  meterEstimatorEvent,
};
