import { createSelector } from 'reselect';
import { AppStateType } from 'src/store/appstate.type';
import { CustomerTypeType, CustomerTypeEnum } from 'src/hocs/WithUserInformations/types';
import { WITH_USER_INFORMATIONS } from './constants';

export const selectUserInformations = (state: AppStateType) => state?.[WITH_USER_INFORMATIONS];

export const makeSelectUserInformations = () =>
  createSelector(selectUserInformations, (substate) => substate);

export const makeSelectIsUserAnonymous = () =>
  createSelector(selectUserInformations, (substate) => substate.isUserAnonymous);

export const selectCustomerType = createSelector(
  selectUserInformations,
  (appState): CustomerTypeType =>
    appState?.isProfessional ? CustomerTypeEnum.PROFESSIONAL : CustomerTypeEnum.PRIVATE,
);

export const selectIsProfessional = createSelector(
  selectUserInformations,
  (appState) => appState?.isProfessional,
);

export const makeSelectCustomerType = () => selectCustomerType;

export const makeIsProfessional = () => selectIsProfessional;

export const makeSelectCustomerId = () =>
  createSelector(selectUserInformations, (substate) => substate.customerId);
