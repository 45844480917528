import { css } from '@emotion/react/macro';
import { theme } from 'src/theme';

const { fonts } = theme;

export const fontFaces = css`
  @font-face {
    font-family: ${fonts.family.heading};
    src: url(${fonts.urls.headingRegular}) format('${fonts.format}');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fonts.family.heading};
    src: url(${fonts.urls.headingBold}) format('${fonts.format}');
    font-weight: 700;
  }

  @font-face {
    font-family: ${theme.fonts.family.body};
    src: url(${fonts.urls.bodyRegular}) format('${fonts.format}');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fonts.family.body};
    src: url(${fonts.urls.bodySemibold}) format('${fonts.format}');
    font-weight: 600;
  }

  @font-face {
    font-family: ${theme.fonts.family.body};
    src: url(${fonts.urls.bodyBold}) format('${fonts.format}');
    font-weight: 700;
  }
`;
