import * as c from './constants';
import * as t from './types';

export const initialState: t.BillingReducerType = {
  billingMethodsLoading: false,
  billingMethodsError: null,
  billingMethods: null,
  monthlyBillLoading: false,
  monthlyBillError: null,
  monthlyBill: null,
  mandateLoading: false,
  mandateError: null,
  mandate: null,
};

function withBillingReducer(state = initialState, { type, payload }): t.BillingReducerType {
  switch (type) {
    case c.GET_BILLING_METHODS_REQUEST:
      return {
        ...state,
        billingMethodsLoading: true,
        billingMethodsError: null,
      };
    case c.GET_BILLING_METHODS_SUCCESS:
      return {
        ...state,
        billingMethods: payload,
        billingMethodsLoading: false,
      };
    case c.GET_BILLING_METHODS_FAILURE:
      return {
        ...state,
        billingMethodsLoading: false,
        billingMethodsError: payload,
      };
    case c.GET_MONTHLY_BILL_REQUEST:
      return {
        ...state,
        monthlyBillLoading: true,
        monthlyBillError: null,
      };
    case c.GET_MONTHLY_BILL_SUCCESS:
      return {
        ...state,
        monthlyBill: payload,
        monthlyBillLoading: false,
      };
    case c.GET_MONTHLY_BILL_FAILURE:
      return {
        ...state,
        monthlyBillLoading: false,
        monthlyBillError: payload,
      };
    case c.GET_MANDATE_REQUEST:
      return {
        ...state,
        mandateLoading: true,
        mandateError: null,
      };
    case c.GET_MANDATE_SUCCESS:
      return {
        ...state,
        mandate: payload,
        mandateLoading: false,
      };
    case c.GET_MANDATE_FAILURE:
      return {
        ...state,
        mandateLoading: false,
        mandateError: payload,
      };
    case c.RESET_MANDATE:
      return {
        ...state,
        mandate: payload,
      };
    default:
      return state;
  }
}

export default withBillingReducer;
