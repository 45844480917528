import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'app.loading',
    defaultMessage: 'Chargement en cours...',
  },
  errorHappened: {
    id: 'app.errorHappened',
    defaultMessage: 'app.errorHappened',
  },
  noPostalCode: {
    id: 'app.errorNoPostalCode',
    defaultMessage: 'Code postal non trouvé, veuillez compléter votre adresse.',
  },
  'No data found for this address': {
    id: 'app.nodataadresse',
    defaultMessage: 'Impossible de retrouver les informations pour cette adresse.',
  },
  UNABLE_TO_MODIFY_SUBSCRIPTION: {
    id: 'app.UNABLE_TO_MODIFY_SUBSCRIPTION',
    defaultMessage: 'Impossible de sauvegarder les informations de votre souscription !',
  },
  INVALID_OPTION_HEAD: {
    id: 'app.INVALID_OPTION_HEAD',
    defaultMessage: 'Vous avez choisi des services associés à cette énergie',
  },
  INVALID_OPTION: {
    id: 'app.INVALID_OPTION',
    defaultMessage:
      "Pour supprimer cette énergie, vous devez d'abord supprimer les services associés à l'énergie dans votre panier.",
  },
  NO_COMPATIBLE_ACTIVE_OFFERS: {
    id: 'app.NO_COMPATIBLE_ACTIVE_OFFERS',
    defaultMessage: "Votre offre partenaire n'est plus disponible.",
  },
  CUSTOMER_ALREADY_SUBSCRIBED: {
    id: 'app.AlreadyClient.explanation1',
    defaultMessage: 'app.AlreadyClient.explanation1',
  },
});
