import { fontFace } from 'src/theme/tokens/core/font-face';

export const fonts = {
  weights: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
  sizes: {
    '4xs': '9px',
    '3xs': '12px',
    '2xs': '13px',
    xs: '14px',
    s: '16px',
    m: '18px',
    l: '20px',
    xl: '24px',
    '2xl': '28px',
    '3xl': '32px',
    '4xl': '40px',
  },
  lineHeights: {
    '6xs': '14px',
    '5xs': '16px',
    '4xs': '18px',
    '3xs': '20px',
    '2xs': '22px',
    xs: '24px',
    s: '26px',
    m: '28px',
    l: '30px',
    xl: '34px',
    '2xl': '36px',
    '3xl': '40px',
    '4xl': '48px',
  },
  ...fontFace,
};
