/**
 * Take a date or a valid or not DateString and return a
 * DateString with a correct format for the Input HTMLElement
 * @param {Date|DateString} date
 */
export const index = (date: Date | string | null) => {
  if (date == null || date == 'Invalid Date') return null; // eslint-disable-line
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export function formatDate(date: Date | string | null) {
  if (date == null || date == 'Invalid Date') return null; // eslint-disable-line
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[day, month, year].join('/')}`;
}
