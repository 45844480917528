import { EnergyEnum, EnergyType } from 'src/hocs/App/types';
import { BUSINESS_PARTS, BusinessPartType } from 'src/hocs/App/constants';
import routes from 'src/utils/routes/routes';

export const getGroupPurchaseEnergyType = (groupPuchase: BusinessPartType): EnergyType => {
  if (groupPuchase === BUSINESS_PARTS.UFC2024) {
    return EnergyEnum.GAS;
  }
  return EnergyEnum.ELECTRICITY;
};

export const isGroupPurchase = (): boolean => {
  const currentLocation = window.location.pathname;
  const groupPurchaseRoutes = [routes.groupPurchaseLanding, routes.groupPurchaseSummary];
  return groupPurchaseRoutes.some((route) => currentLocation.includes(route));
};
