import { SubscriptionReducerType } from './types';
import * as C from './constants';

export const initialState: SubscriptionReducerType = {
  subscription: null,
  loading: false,
  error: null,
  isFraudOrDoublon: false,
  isEmailSaved: false,
  activationSteps: [],
};

// TODO: replace old WithSubscription reducer with this reducer
function WithSubscriptionReducer(state = initialState, { payload, type }): SubscriptionReducerType {
  switch (type) {
    case C.REQUEST_SUBSCRIPTION_NEW:
      return {
        ...state,
        loading: true,
      };
    case C.REQUEST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: payload,
        error: null,
      };
    case C.REQUEST_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case C.REQUEST_FINALIZE_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case C.REQUEST_FINALIZE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: payload.subscription,
        activationSteps: payload.activationSteps || [],
      };
    case C.REQUEST_FINALIZE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case C.SET_FRAUD_DOUBLON:
      return {
        ...state,
        isFraudOrDoublon: payload,
      };
    case C.REQUEST_SAVE_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case C.REQUEST_SAVE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isEmailSaved: true,
      };
    case C.REQUEST_SAVE_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case C.REQUEST_START_PRESUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case C.REQUEST_START_PRESUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case C.REQUEST_START_PRESUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case C.SWITCH_INVOICE_FREQUENCY:
      return {
        ...state,
        subscription: {
          ...payload.subscription,
          invoiceFrequency: payload,
        },
      };
    default:
      return state;
  }
}

export default WithSubscriptionReducer;
