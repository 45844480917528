export const sizes = {
  '3xs': 1,
  '2xs': 2,
  xs: 16,
  s: 18,
  m: 24,
  l: 32,
  xl: 36,
  '2xl': 40,
  '3xl': 46,
  '4xl': 48,
  '5xl': 56,
  '6xl': 60,
  '7xl': 64,
  '8xl': 86,
  '9xl': 100,
} as const;
