import routes from 'src/utils/routes/routes';
import { POD_BY_ADDRESS_FIELDS, POD_BY_POD_FIELDS } from 'src/hocs/WithPODInfo/constants';
import { GET_MANDATE_FIELDS } from 'src/hocs/WithBilling/constants';
import { HOME_FORM_FIELDS } from 'src/components/pages/SouscrireNew/SouscrireNew.constants';
import { images } from 'src/theme/assets/images';
import { ApiErrorMessagesEnum, DestinationsEnum, ErrorDictionaryType, OriginsEnum } from './types';
import messages from './messages';
import { ErrorHtmlKeys } from './errors';

export const dictionary: ErrorDictionaryType = {
  [OriginsEnum.UNKNOWN]: {
    [ApiErrorMessagesEnum.UNKNOWN]: {
      html: ErrorHtmlKeys.GENERIC_ERROR,
      headlineMessage: messages.genericErrorTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
    [ApiErrorMessagesEnum.CUSTOMER_SERVICE_ACTION_REQUIRED]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.unPaid,
    },
    [ApiErrorMessagesEnum.CUSTOMER_ALREADY_SUBSCRIBED]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.alreadyClient,
    },
    [ApiErrorMessagesEnum.SUBSCRIPTION_LOCKED]: {
      ...messages.subscriptionLockedBody02,
      headlineMessage: messages.subscriptionLockedTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
    // error thrown artificially by WithPodInfo Middleware
    [ApiErrorMessagesEnum.NO_POD_INFO_AVAILABLE]: {
      destination: DestinationsEnum.NONE,
    },
  },
  [OriginsEnum.HOME_PAGE]: {
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      html: ErrorHtmlKeys.GENERIC_ERROR,
      headlineMessage: messages.genericErrorTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
    [ApiErrorMessagesEnum.NOT_ELIGIBLE]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.FLUX_SERVICE_ERROR]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    [ApiErrorMessagesEnum.SERVICE_PCE_UNAVAILABLE]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    [ApiErrorMessagesEnum.SERVICE_PDL_UNAVAILABLE]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    [ApiErrorMessagesEnum.SERVICE_PROMOCODE_UNAVAILABLE]: {
      ...messages.promoCodeUnavailable,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: HOME_FORM_FIELDS.PROMO_CODE,
    },
    [ApiErrorMessagesEnum.NO_COMPATIBLE_ACTIVE_OFFERS]: {
      destination: DestinationsEnum.POP_UP,
      headlineMessage: messages.noCompatibleOfferTitle,
      customErrorImage: images.incompatibleOfferError,
      html: ErrorHtmlKeys.NO_COMPATIBLE_OFFER,
    },
    [ApiErrorMessagesEnum.INSUFFICIENT_DATA_FOR_ELECTRICITY_QUOTATION]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.INSUFFICIENT_DATA_FOR_GAS_QUOTATION]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.MUST_GIVE_CAR_ELECTRICITY]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.MUST_GIVE_CAR_GAS]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.NO_POD_INFO_AVAILABLE]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.choixEnergie,
    },
    // Todo: wait for ticket PSBO-1365 to be done, to deal with error on https://api-so.ekwateur.fr/quotations/code
    //  https://joul.myjetbrains.com/youtrack/issue/PSBO-1365
    [ApiErrorMessagesEnum.CITY_NOT_FOUND]: {
      ...messages.weCantFindYourAddressEnterZipCode,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: HOME_FORM_FIELDS.SELECTED_BILLING_ADDRESS,
    },
    [ApiErrorMessagesEnum.DISALLOW_ELD_DURING_WINTER_BREAK]: {
      destination: DestinationsEnum.POP_UP,
      html: ErrorHtmlKeys.DISALLOW_ELD_DURING_WINTER_BREAK,
      customErrorImage: images.customerContact,
    },
  },
  [OriginsEnum.LIVRAISON_POD]: {
    [ApiErrorMessagesEnum.INVALID_PDL]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: POD_BY_POD_FIELDS.PDL,
    },
    [ApiErrorMessagesEnum.INVALID_PCE]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: POD_BY_POD_FIELDS.PCE,
    },
    [ApiErrorMessagesEnum.FLUX_SERVICE_ERROR]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.SERVICE_PCE_UNAVAILABLE]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.SERVICE_PDL_UNAVAILABLE]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.NO_POD_INFO_AVAILABLE]: {
      ...messages.invalidOldName,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: POD_BY_ADDRESS_FIELDS.CONSUMER_LAST_NAME,
    },
  },
  [OriginsEnum.CHOIX_ENERGIE]: {
    [ApiErrorMessagesEnum.INVALID_PDL]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: POD_BY_POD_FIELDS.PDL,
    },
    [ApiErrorMessagesEnum.INVALID_PCE]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: POD_BY_POD_FIELDS.PCE,
    },
    // this error is handled by ChoixEnergie's saga
    [ApiErrorMessagesEnum.FLUX_SERVICE_ERROR]: {
      destination: DestinationsEnum.NONE,
    },
    // this error is handled by ChoixEnergie's saga
    [ApiErrorMessagesEnum.SERVICE_PDL_UNAVAILABLE]: {
      destination: DestinationsEnum.NONE,
    },
    // this error is handled by ChoixEnergie's saga
    [ApiErrorMessagesEnum.SERVICE_PCE_UNAVAILABLE]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    [ApiErrorMessagesEnum.INSUFFICIENT_DATA_FOR_ELECTRICITY_QUOTATION]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    [ApiErrorMessagesEnum.INSUFFICIENT_DATA_FOR_GAS_QUOTATION]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
    // error thrown artificially by WithPodInfo Middleware
    [ApiErrorMessagesEnum.NO_POD_INFO_AVAILABLE]: {
      destination: DestinationsEnum.NONE,
    },
    // All other unknown errors should redirect user, so he/she does not leave the app
    [ApiErrorMessagesEnum.UNKNOWN]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.estimation,
    },
  },
  [OriginsEnum.QUOTATION]: {
    [ApiErrorMessagesEnum.FLUX_SERVICE_ERROR]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.INVALID_OPTION]: {
      ...messages.INVALID_OPTION,
      destination: DestinationsEnum.POP_UP,
      isClosable: true,
      headlineMessage: messages.INVALID_OPTION_HEAD,
      customErrorImage: images.invalidOptionError,
    },
    [ApiErrorMessagesEnum.NO_COMPATIBLE_SERVICES]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.contrat,
    },
  },
  [OriginsEnum.SERVICES_OPTIONS]: {
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.INVALID_OPTION]: {
      html: ErrorHtmlKeys.GENERIC_ERROR,
      headlineMessage: messages.genericErrorTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
      htmlProps: { isTryAgainLinkAvailable: false },
    },
  },
  [OriginsEnum.CONTRAT]: {
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.VALIDATION_FAILED]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.CUSTOMER_ALREADY_SUBSCRIBED]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.alreadyClient,
    },
    [ApiErrorMessagesEnum.CUSTOMER_SERVICE_ACTION_REQUIRED]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.unPaid,
    },
    [ApiErrorMessagesEnum.AGE_LIMIT_EXCEEDED]: {
      html: ErrorHtmlKeys.AGE_LIMIT_EXCEEDED,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.invalidOptionError,
    },
  },
  [OriginsEnum.MY_DELIVERY]: {
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
  },
  [OriginsEnum.MODE_FACTURATION]: {
    [ApiErrorMessagesEnum.INVALID_IBAN]: {
      ...messages.invalidIBAN,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: GET_MANDATE_FIELDS.IBAN,
    },
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.API_PAY_TIMEOUT]: {
      destination: DestinationsEnum.POP_UP,
      html: ErrorHtmlKeys.API_PAY_TIMEOUT_FORM,
    },
    [ApiErrorMessagesEnum.CUSTOMER_ALREADY_SUBSCRIBED]: {
      destination: DestinationsEnum.REDIRECTION,
      redirectionPath: routes.alreadyClient,
    },
  },
  [OriginsEnum.SUMMARY]: {
    [ApiErrorMessagesEnum.EXTERNAL_SERVICE_ERROR]: {
      ...messages.PODNonValid,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.networkError,
      html: ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM,
    },
    [ApiErrorMessagesEnum.SUBSCRIPTION_LOCKED]: {
      ...messages.subscriptionLockedBody02,
      headlineMessage: messages.subscriptionLockedTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
  },
  [OriginsEnum.PAYMENT]: {
    [ApiErrorMessagesEnum.SUBSCRIPTION_LOCKED]: {
      ...messages.subscriptionLockedBody02,
      headlineMessage: messages.subscriptionLockedTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
  },
  [OriginsEnum.PAIEMENT_SUCCESS]: {
    [ApiErrorMessagesEnum.UNKNOWN]: {
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.customerSuccessAlternative,
      headlineMessage: messages.felicitationsErrorTitle,
      html: ErrorHtmlKeys.CONGRATULATION_ERROR,
    },
  },
  [OriginsEnum.NON_ELIGIBLE]: {
    [ApiErrorMessagesEnum.UNKNOWN]: {
      html: ErrorHtmlKeys.GENERIC_ERROR,
      headlineMessage: messages.genericErrorTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
    },
    [ApiErrorMessagesEnum.POSSIBLE_SPAM_ATTEMPT]: {
      destination: DestinationsEnum.NONE,
    },
    [ApiErrorMessagesEnum.VALIDATION_FAILED]: {
      destination: DestinationsEnum.NONE,
    },
  },
  [OriginsEnum.MANDATE_SIGNATURE]: {
    [ApiErrorMessagesEnum.UNKNOWN]: {
      html: ErrorHtmlKeys.GENERIC_ERROR,
      headlineMessage: messages.genericErrorTitle,
      destination: DestinationsEnum.POP_UP,
      customErrorImage: images.genericError,
      htmlProps: { isTryAgainLinkAvailable: false },
    },
    [ApiErrorMessagesEnum.INVALID_IBAN]: {
      ...messages.invalidIBAN,
      destination: DestinationsEnum.INPUT_ERROR,
      inputFields: GET_MANDATE_FIELDS.IBAN,
    },
  },
};
