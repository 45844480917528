import AppConfig from 'src/config/config';

const { REACT_APP_GOOGLE_API_KEY = '' } = AppConfig;

// prettier-ignore
export const initiateGoogleMaps = () =>
(g=>{let h; let a; let k; const p='The Google Maps JavaScript API'; const c='google'; const l='importLibrary'; const q='__ib__'; const m=document; let b=window;b=b[c]||(b[c]={});const d=b.maps||(b.maps={}); const r=new Set; const e=new URLSearchParams; const u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement('script'));e.set('libraries',`${[...r]}`);for(k in g)e.set(k.replace(/[A-Z]/g,t=>`_${t[0].toLowerCase()}`),g[k]);e.set('callback',`${c}.maps.${q}`);a.src=`https://maps.${c}apis.com/maps/api/js?${e}`;d[q]=f;a.onerror=()=>h=n(Error(`${p} could not load.`));a.nonce=m.querySelector('script[nonce]')?.nonce||'';m.head.append(a)}));d[l]?console.warn(`${p} only loads once. Ignoring:`,g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({ // eslint-disable-line
    key:REACT_APP_GOOGLE_API_KEY,
  });

// method from the google maps api https://developers.google.com/maps/documentation/javascript/overview?hl=fr#bootstrap_loader
// used as is and not in a script because React sanitazes the script, which does not load correctly
