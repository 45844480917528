import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'src/utils/saga/injectSaga';
import { DAEMON } from 'src/utils/saga/constants';
import { makeSelectDevis } from './selectors';

import { updateQuotationAction } from './actions';
import saga from './saga';
import { WithDevisProps } from './types';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/* @deprecated in favor of react-redux hooks useSelector and useDispatch */
const WithDevis = (WrappedComponent) => {
  class HOC extends React.PureComponent<WithDevisProps> {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  // @ts-ignore TODO js migration
  HOC.displayName = `WithDevis(${getDisplayName(WrappedComponent)})`;
  return compose(withSaga, withConnect)(HOC);
};

const mapStateToProps = () =>
  createStructuredSelector({
    devis: makeSelectDevis(),
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateQuotation: (devis) => dispatch(updateQuotationAction(devis)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore TODO js migration
const withSaga = injectSaga({ key: 'WithDevis', saga, mode: DAEMON });

export default WithDevis;
