import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import { EligibilityRequestType } from 'src/hocs/WithEligibilityNew/types';

export const ENEDIS = 'ENEDIS';
export const GRDF = 'GRDF';
export const WITHELIGIBILITY = 'WithEligibility';

const buildQuotationFields = fieldsTypeCheckerFactory<EligibilityRequestType>();

export const ELIGIBILITY_FIELDS = buildQuotationFields({
  CITY: 'city',
  ZIP_CODE: 'zipCode',
  CUSTOMER_TYPE: 'customerType',
  PROMO_CODE: 'offerCode',
});
