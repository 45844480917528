import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const REQUEST_INFO_ELEC_CENTRAL_SUCCESS =
  'app/ServicesOptions/GET_INFO_ELEC_CENTRAL_SUCCESS';
export const SAVE_INFO_SERVICES_OPTIONS = 'app/ServicesOptions/SAVE_INFO_SERVICES_OPTIONS';
export const UPDATE_INFO_SERVICES_OPTIONS = 'app/ServicesOptions/UPDATE_INFO_SERVICES_OPTIONS';
export const SET_CENTRAL_VALIDATION = 'app/ServicesOptions/SET_CENTRAL_VALIDATION';
export const GET_CLOSEST_POWER_STATION = 'app/ServicesOptions/GET_CLOSEST_POWER_STATION';
export const SWITCH_ELECTRICITY_OPTION = 'app/ServicesOptions/SWITCH_ELECTRICITY_OPTION';
export const SERVICES_OPTIONS_REDUCER = 'ServicesOptions';

export const ELECTRICITY_OPTIONS = {
  DEFAULT_OPTION: 'ELEC_100_PERCENT_RENEWABLE_EU',
  FULLY_RENEWABLE_FR_OPTION: 'ELEC_100_PERCENT_RENEWABLE_FR',
} as const;
export type ElectricityOptionsType = ValueOf<typeof ELECTRICITY_OPTIONS>;

export const GAS_OPTIONS = {
  DEFAULT_OPTION: 'NULL',
  FULLY_RENEWABLE_OPTION: 'GGO',
  FULLY_RENEWABLE_AND_FREE_OPTION: 'GGO0',
  GAS_5_PERCENT_RENEWABLE: 'GAS_5_PERCENT_RENEWABLE',
  GAS_100_PERCENT_RENEWABLE: 'GAS_100_PERCENT_RENEWABLE',
} as const;

export type GasOptionsType = ValueOf<typeof GAS_OPTIONS>;

export const FORM_FIELDS = {
  POWER_STATION: 'AGO',
} as const;

export const SERVICES_OPTIONS_FORM_INPUTS = {
  ELECTRICITY_OPTION: 'electricityOption',
  AGO: FORM_FIELDS.POWER_STATION,
  ELECTRICITY_PRODUCT_ITEM_TYPE: 'electricityProductItemType',
  GAS_OPTION: 'gasOption',
  GAS_PRODUCT_ITEM_TYPE: 'gasProductItemType',
} as const;
