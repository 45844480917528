import { GET_CARBON_IMPACT_EMISSIONS_SAVINGS_REQUEST, GET_CARBON_IMPACT_EMISSIONS_SAVINGS_SUCCESS, GET_CARBON_IMPACT_EMISSIONS_SAVINGS_FAILURE } from './constants';
import { EmissionsSavingsReducer } from './types';

export const initialState: EmissionsSavingsReducer = {
  carbonImpact: null,
  error: null,
};

const carbonImpactReducer = (state = initialState, action): EmissionsSavingsReducer => {
  switch (action.type) {
    case GET_CARBON_IMPACT_EMISSIONS_SAVINGS_REQUEST:
      return {
        ...state,
      };

    case GET_CARBON_IMPACT_EMISSIONS_SAVINGS_SUCCESS:
      return {
        ...state,
        carbonImpact: action.payload,
      };

    case GET_CARBON_IMPACT_EMISSIONS_SAVINGS_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    default:
      return { ...state };
}
};

export default carbonImpactReducer;