import { ELECTRICITY, GAZ } from 'src/hocs/WithDevis/constants';

export const SELLING_CONDITIONS_REDUCER = 'sellingConditions';
export const GET_SELLING_CONDITIONS_SUCCESS =
  'app/SELLING_CONDITIONS/GET_SELLING_CONDITIONS_SUCCESS';
export const SERVICE = 'SERVICE';
export const DEP = 'DEP';
const VOL = 'VOL';
export const TARIFF_GRID = 'TARIFF_GRID';
const INFO = 'INFO';
const CPV = 'CPV';
const IPID = 'IPID';
const SCS = 'SCS';
const PRIVACY_POLICY = 'PRIVACY_POLICY';

const ELECTRICITY_ID = 'cgvElec';
const GAZ_ID = 'cgvGaz';
const DEP_ID = 'dep';
const VOL_ID = 'vol';
const TARIFF_GRID_ID = 'tariffGrid';
const INFO_ID = 'info';
const CPV_ID = 'cpv-contract';
const IPID_ID = 'ipid';
const SCS_ID = 'cpv';
const PRIVACY_POLICY_ID = 'politiqueConfidentialite';

export const SELLING_CONDITIONS_LABELS = [
  {
    id: ELECTRICITY_ID,
    label: ELECTRICITY,
  },
  {
    id: GAZ_ID,
    label: GAZ,
  },
  {
    id: DEP_ID,
    label: DEP,
  },
  {
    id: VOL_ID,
    label: VOL,
  },
  {
    id: TARIFF_GRID_ID,
    label: TARIFF_GRID,
  },
  {
    id: INFO_ID,
    label: INFO,
  },
  {
    id: CPV_ID,
    label: CPV,
  },
  {
    id: IPID_ID,
    label: IPID,
  },
  {
    id: SCS_ID,
    label: SCS,
  },
  {
    id: PRIVACY_POLICY_ID,
    label: PRIVACY_POLICY,
  },
];
