import { type ObjectSchema, object, string } from 'yup';
import { emailRegex } from 'src/utils/validator';
import messages from 'src/hocs/App/messages';
import { FormatMessageType } from 'src/components/utils/LanguageProvider/intl.types';
import * as T from './types';
import * as C from './constants';

export const SendEmailFormSchema = (
  formatMessage: FormatMessageType,
): ObjectSchema<T.SendEmailFormType> =>
  object({
    [C.FORM_FIELDS.NON_ELIGIBLE_EMAIL]: string()
      .matches(emailRegex, {
        message: formatMessage(messages.badFormat),
      })
      .nullable()
      .required(formatMessage(messages.required)),
  });
