import { semantical } from 'src/theme/tokens/semantical';
import { customComponents } from 'src/theme/tokens/components/custom';

const { radius, borderWidth } = customComponents;

export const buttonTheme = {
  borderWidth: `${semantical.sizes.border[borderWidth]}px`,
  variants: {
    filled: {
      background: {
        default: semantical.colors.background.interaction.default,
        interaction: semantical.colors.background.interaction.active,
        disabled: semantical.colors.background.state.disabled.strong,
      },
      border: {
        default: semantical.colors.border.interaction.default,
        interaction: semantical.colors.border.interaction.active,
        disabled: semantical.colors.border.state.disabled.strong,
      },
      color: {
        default: semantical.colors.text.onPrimary,
        interaction: semantical.colors.text.onPrimary,
        disabled: semantical.colors.text.state.disabled.weakest,
      },
    },
    outlined: {
      background: {
        default: semantical.colors.background.special.transparent,
        interaction: semantical.colors.background.special.transparent,
        disabled: semantical.colors.background.special.transparent,
      },
      border: {
        default: semantical.colors.border.interaction.default,
        interaction: semantical.colors.border.interaction.active,
        disabled: semantical.colors.border.state.disabled.weakest,
      },
      color: {
        default: semantical.colors.text.interaction.default,
        interaction: semantical.colors.text.interaction.active,
        disabled: semantical.colors.text.state.disabled.weakest,
      },
    },
    transparent: {
      background: {
        default: semantical.colors.background.special.transparent,
        interaction: semantical.colors.background.special.transparent,
        disabled: semantical.colors.background.special.transparent,
      },
      border: {
        default: semantical.colors.border.special.transparent,
        interaction: semantical.colors.border.special.transparent,
        disabled: semantical.colors.border.special.transparent,
      },
      color: {
        default: semantical.colors.text.interaction.default,
        interaction: semantical.colors.text.interaction.active,
        disabled: semantical.colors.text.state.disabled.weakest,
      },
    },
  },
  radius: semantical.radius[radius.button],
  sizes: {
    s: {
      height: `${semantical.sizes.l}px`,
      padding: semantical.spacing.s,
      width: `${semantical.sizes.l}px`,
      fontSizes: semantical.fonts.sizes['2xs'],
      icon: `${semantical.sizes.icon.xs}px`,
    },
    m: {
      height: `${semantical.sizes['4xl']}px`,
      padding: semantical.spacing.m,
      width: `${semantical.sizes['4xl']}px`,
      fontSizes: semantical.fonts.sizes.xs,
      icon: `${semantical.sizes.icon.s}px`,
    },
    l: {
      height: `${semantical.sizes['5xl']}px`,
      padding: semantical.spacing.l,
      width: `${semantical.sizes['5xl']}px`,
      fontSizes: semantical.fonts.sizes.s,
      icon: `${semantical.sizes.icon.l}px`,
    },
  },
} as const;
