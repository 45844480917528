import { AxiosResponse } from 'axios';

import * as c from './constants';
import * as t from './types';

export function getBillingMethodsRequestAction(): t.BaseActionType {
  return {
    type: c.GET_BILLING_METHODS_REQUEST,
  };
}

export function getBillingMethodsSuccessAction(
  data: t.BillingMethodsResponseType,
): t.GetBillingMethodsSuccessActionType {
  return {
    type: c.GET_BILLING_METHODS_SUCCESS,
    payload: data,
  };
}

export function getBillingMethodsFailureAction(error: AxiosResponse): t.FailureActionType {
  return {
    type: c.GET_BILLING_METHODS_FAILURE,
    payload: error,
  };
}

export function getMonthlyBillRequestAction(): t.BaseActionType {
  return {
    type: c.GET_MONTHLY_BILL_REQUEST,
  };
}

export function getMonthlyBillSuccessAction(
  data: t.MonthlyBillResponseType,
): t.GetMonthlyBillingSuccessActionType {
  return {
    type: c.GET_MONTHLY_BILL_SUCCESS,
    payload: data,
  };
}

export function getMonthlyBillFailureAction(error: AxiosResponse): t.FailureActionType {
  return {
    type: c.GET_MONTHLY_BILL_FAILURE,
    payload: error,
  };
}

export function getMandateRequestAction(): t.BaseActionType {
  return {
    type: c.GET_MANDATE_REQUEST,
  };
}

export function getMandateSuccessAction(
  data: t.MandateResponseType,
): t.GetMandateSuccessActionType {
  return {
    type: c.GET_MANDATE_SUCCESS,
    payload: data,
  };
}

export function getMandateFailureAction(error: AxiosResponse): t.FailureActionType {
  return {
    type: c.GET_MANDATE_FAILURE,
    payload: error,
  };
}


export function resetMandate() {
  return {
    type: c.RESET_MANDATE,
    payload: null,
  };
}
