import { SET_FORM, AUTO_FILL_FORMS, SET_FORM_FIELD } from './constants';

export const initialState = {};

function withFormReducer(state = initialState, { type, payload, formName, formField }) {
  switch (type) {
    case SET_FORM:
      return {
        ...state,
        [formName]: payload,
      };
    case AUTO_FILL_FORMS:
      return {
        ...state,
        ...payload,
      };
    case SET_FORM_FIELD:
      return {
        ...state,
        [formName]: {
          ...state[formName],
          [formField]: payload,
        },
      };
    default:
      return state;
  }
}

export default withFormReducer;
