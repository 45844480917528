import styled from '@emotion/styled/macro';

export const InputWithButtonContainer = styled.div<{
  spacing?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ spacing, theme }) => (spacing ? `${spacing}px` : theme.spacing.s)};

  ${({ theme }) => theme.breakpoints.upTablet} {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: ${({ spacing }) => `${spacing}px`};
    gap: ${({ theme }) => theme.spacing.s};
  }
`;
