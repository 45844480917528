import { createSelector } from 'reselect';

import { AppStateType } from 'src/store/appstate.type';

import * as t from './types';
import * as c from './constants';

export const selectPromoCodeState = (state: AppStateType) => state[c.WITH_PROMO_CODE];

export const selectPromoCode = createSelector(
  selectPromoCodeState,
  (promoCodeState) => promoCodeState?.promoCode,
);

export const selectPromoCodeError = createSelector(
  selectPromoCodeState,
  (promoCodeState) => promoCodeState?.error,
);

/*
  This selector returns PromoCodeResponse with the code of the user
 */
export const selectPromoCodeEnhanced = createSelector(
  selectPromoCodeState,
  (promoCodeState): t.PromoCodeEnhancedType => ({
    ...promoCodeState?.promoCode,
    code: promoCodeState?.code,
  }),
);

export const selectPromoCodeLoading = createSelector(
  selectPromoCodeState,
  (promoCodeState) => promoCodeState?.loading,
);

// TODO use this selector instead of src/containers/PromoCode/selectors/makeSelectPromoCode
export const makeSelectPromoCodeEnhanced = () => selectPromoCodeEnhanced;

export const makeSelectPromoCodeLoading = () => selectPromoCodeLoading;

export const makeSelectPromoCode = () => selectPromoCode;
