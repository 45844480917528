import { PaymentReducerType } from 'src/components/pages/Payment/types';
import { REQUEST_PAYMENT_TOKEN_SUCCESS } from './constants';

export const initialState: PaymentReducerType = {
  token: null,
};

// Todo: refactoring - replace local redux state by useState (or move to App reducer if not possible)
function PaymentReducer(state = initialState, action): PaymentReducerType {
  switch (action.type) {
    case REQUEST_PAYMENT_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token.answer.formToken,
      };
    }
    default:
      return state;
  }
}

export default PaymentReducer;
