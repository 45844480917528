import { useTheme } from '@emotion/react/macro';
import { IconSizeType } from 'src/theme/theme.types';

export const useGetIconThemeSize = (size: IconSizeType): number => {
  const theme = useTheme();

  if (size) {
    return theme.sizes.icon[size];
  }

  return theme.sizes.icon.l;
};
