import { AxiosResponse } from 'axios';
import { QuotationResponseType } from 'src/hocs/WithDevis/types';
import { ContractFormData } from 'src/components/pages/Contrat/types';

/*
    Enums
*/

export enum SubscriptionStatusEnum {
  IS_QUOTATION = 'IS_QUOTATION',
  IS_SERVICES_OPTIONS = 'IS_SERVICES_OPTIONS',
  IS_PROFILE = 'IS_PROFILE',
  IS_WAITING_FOR_POD = 'IS_WAITING_FOR_POD',
  IS_DELIVERY = 'IS_DELIVERY',
  IS_BILLING_METHOD = 'IS_BILLING_METHOD',
  IS_SIGNED = 'IS_SIGNED',
  IS_PAYMENT = 'IS_PAYMENT',
  IS_SUBSCRIPTION_COMPLETED = 'IS_SUBSCRIPTION_COMPLETED',
  FINALISATION_ERROR = 'FINALISATION_ERROR',
  IS_MASS_FINALIZATION = 'IS_MASS_FINALIZATION',
  IS_STARTING_PRESUBSCRIPTION = 'IS_STARTING_PRESUBSCRIPTION',
  SUBSCRIPTION_FINALIZED = 'SUBSCRIPTION_FINALIZED',
}

// TODO: check with backend's team if this is not the same enum than SubscriptionStatusEnum
export enum FrontStatusEnum {
  IS_QUOTATION = 'IS_QUOTATION',
  IS_SERVICES_OPTIONS = 'IS_SERVICES_OPTIONS',
  IS_PROFILE = 'IS_PROFILE',
  IS_WAITING_FOR_POD = 'IS_WAITING_FOR_POD',
  IS_DELIVERY = 'IS_DELIVERY',
  IS_BILLING_METHOD = 'IS_BILLING_METHOD',
  IS_SIGNED = 'IS_SIGNED',
  IS_PAYMENT_REQUIRED = 'IS_PAYMENT_REQUIRED',
  IS_PAYMENT_OK = 'IS_PAYMENT_OK',
}

export enum CivilityEnum {
  M = 'M.',
  MME = 'Mme',
}

export enum PaymentDayEnum {
  DAY_5 = 5,
  DAY_15 = 15,
  DAY_25 = 25,
}

export enum InvoiceFrequencyEnum {
  MENS = 'MENS',
  ANN = 'ANN',
  BIM = 'BIM',
}

export enum InvoiceSendingChannelEnum {
  PAPER = 'Papier',
  EMAIL = 'Mail',
}

export enum InvoicePaymentMethodEnum {
  CB = 'CB',
  DOM = 'DOM',
}

export enum ElectricityRelocationCommissioningTypeEnum {
  CLASSIC = 'CLASSIC',
  EXPRESS = 'EXPRESS',
  URGENT = 'URGENT',
  PMES = 'PMES',
  ELD = 'ELD',
}

export enum GasRelocationCommissioningTypeEnum {
  CLASSIC = 'CLASSIC',
  EXPRESS = 'EXPRESS',
  URGENT = 'URGENT',
  PMES = 'PMES',
  ELD = 'ELD',
}

export enum ActivationStepsNameEnum {
  SUBSCRIPTION_FINALIZED = 'SUBSCRIPTION_FINALIZED',
  CONTRACT_FINALIZED = 'CONTRACT_FINALIZED',
  SWITCH_REQUEST = 'SWITCH_REQUEST',
  WELCOME_EMAIL = 'WELCOME_EMAIL',
}

/*
    Reducer
 */

export interface SubscriptionReducerType {
  subscription: SubscriptionResponseType;
  loading: boolean;
  error: AxiosResponse;
  isFraudOrDoublon: boolean;
  isEmailSaved: boolean;
  activationSteps: Array<ActivationStepType>;
}

/*
    Redux Action Types
 */

export interface BaseSubscriptionAction {
  type: string;
}

export interface RequestSubscriptionActionType extends BaseSubscriptionAction {
  payload?: { id: string };
}

export interface RequestSubscriptionSuccessActionType extends BaseSubscriptionAction {
  payload: SubscriptionResponseType;
}

export interface RequestFinalizeSubscriptionSuccessActionType extends BaseSubscriptionAction {
  payload: FinalizeResponseType;
}

export interface RequestSubscriptionFailureActionType extends BaseSubscriptionAction {
  payload: AxiosResponse;
}

/*
    API Call Types
 */

export interface ConsentCollectionType {
  [key: string]: boolean;
}

export interface SubscriptionResponseType {
  bic?: string;
  id: string;
  status: SubscriptionStatusEnum;
  frontStatus: SubscriptionStatusEnum;
  quotation: QuotationResponseType;
  civility: CivilityEnum;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  phoneNumber?: string;
  birthdate: string;
  isSecondName: boolean;
  civility2?: CivilityEnum;
  firstName2?: string;
  lastName2?: string;
  isConsumerDifferent: boolean;
  consumptionStreetNumber: string;
  consumptionStreetName: string;
  consumptionZipCode: string;
  consumptionCity: string;
  isInvoiceAddressDifferent: false;
  invoiceStreetNumber: string;
  invoiceStreetName: string;
  invoiceZipCode: string;
  invoiceCity: string;
  isBypassWithdrawalPeriod: boolean;
  sellerId: string;
  sellerChannelId: string; // TODO check with backend dev if it's an enum value
  isSigned: boolean;
  hasEnergyDataConsent: boolean;
  consentCollection: ConsentCollectionType;
  siret: string;
  companyName: string;
  invoiceFrequency?: InvoiceFrequencyEnum;
  iban?: string;
  contractId?: string;
  isMandateSignature?: boolean;
  isNewClient?: boolean;
  mandateId?: string;
  preSubscription?: boolean;
  signatureDate?: string;
  invoicePaymentMethod?: InvoicePaymentMethodEnum; // TODO check if this property
  consumerEmail?: string;
}

export interface SubscriptionRequestType {
  quotation?: string | SubscriptionResponseType;
  civility?: CivilityEnum;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  isSecondName?: boolean;
  civility2?: CivilityEnum;
  firstName2?: string;
  lastName2?: string;
  email?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  siret?: string;
  codeApe?: string;
  companyName?: string;
  consumptionStreetNumber?: string;
  consumptionStreetName?: string;
  consumptionZipCode?: string;
  consumptionCity?: string;
  consumptionBuilding?: string;
  consumptionStaircase?: string;
  consumptionFloor?: number;
  consumptionApartmentNumber?: string;
  consumptionAdditionalAddress?: string;
  isInvoiceAddressDifferent?: boolean;
  invoiceStreetNumber?: string;
  invoiceStreetName?: string;
  invoiceZipCode?: string;
  invoiceCity?: string;
  invoiceBuilding?: string;
  invoiceStaircase?: string;
  invoiceFloor?: number;
  invoiceApartmentNumber?: string;
  invoiceAdditionalAddress?: string;
  consumerCivility?: CivilityEnum;
  isConsumerDifferent?: boolean;
  consumerFirstName?: string;
  consumerLastName?: string;
  consumerPhoneNumber?: string;
  consumerMobileNumber?: string;
  consumerEmail?: string;
  isSigned?: boolean;
  isBypassWithdrawalPeriod?: boolean;
  paymentDay?: PaymentDayEnum;
  invoiceFrequency?: InvoiceFrequencyEnum;
  invoiceSendingChannel?: InvoiceSendingChannelEnum;
  invoicePaymentMethod?: InvoicePaymentMethodEnum;
  electricityRelocationCommissioningType?: ElectricityRelocationCommissioningTypeEnum;
  gasRelocationCommissioningType?: GasRelocationCommissioningTypeEnum;
  electricityIndexBase?: number;
  electricityIndexLow?: number;
  gasIndex?: number;
  saveAndResume?: boolean;
  frontStatus?: FrontStatusEnum;
  electricityDesiredActivationDate?: string;
  gasDesiredActivationDate?: string;
  hasEnergyDataConsent?: boolean;
  consentCollection?: ConsentCollectionType;
  externalLeadId?: string;
  isMandateSignature?: boolean;
  isNewClient?: boolean;
  mandateId?: string;
  preSubscription?: boolean;
  signatureDate?: string;
}

export type RequestSubscriptionRequestConfig = ContractFormData;

export interface ActivationStepType {
  delay: number;
  name: string | ActivationStepsNameEnum;
}

export interface FinalizeResponseType {
  subscription: SubscriptionResponseType;
  activationSteps: Array<ActivationStepType>;
}

export interface SaveEmailRequestType {
  email: string;
}

export type SaveEmailResponseType = SaveEmailRequestType;

export interface BaseSaveEmailAction {
  type: string;
}

export interface RequestSaveEmailActionType extends BaseSaveEmailAction {
  payload?: SaveEmailRequestType;
}

export interface RequestSaveEmailSuccessActionType extends BaseSaveEmailAction {
  payload: SaveEmailResponseType;
}

export interface RequestSaveEmailFailureActionType extends BaseSaveEmailAction {
  payload: AxiosResponse;
}
