import { semantical } from 'src/theme/tokens/semantical';

export const loaderTheme = {
  strokeColor: {
    default: semantical.colors.background.primary.strong,
    onPrimary: semantical.colors.background.onPrimary,
  },
  strokeWidth: '8px',
  variants: {
    xl: {
      width: semantical.spacing['3xl'],
      height: semantical.spacing['3xl'],
    },
    l: {
      width: semantical.spacing.xl,
      height: semantical.spacing.xl,
    },
    m: {
      width: semantical.spacing.l,
      height: semantical.spacing.l,
    },
    s: {
      width: semantical.spacing.m,
      height: semantical.spacing.m,
    },
  },
};
