import { requestJSON as SharedRequestJSON } from 'src/utils/api/requestUtil';

import Token from 'src/components/utils/Token';
import { select } from 'redux-saga/effects';
import { selectOffre } from 'src/hocs/App/selectors';

import AppConfig from 'src/config/config';
import { HeadersName } from 'src/utils/api/api.constants';

const { REACT_APP_API_BASE_URL, REACT_APP_TENANT } = AppConfig;

export function* getOptions(url: string, opt) {
  const token = Token.get().token; // eslint-disable-line
  const { codeApporteur, codeCanal } = yield select(selectOffre);

  const options = {
    ...opt,
    // credentials: 'include',
  };

  const externalUrl = url.indexOf('http') !== -1;
  const accessToken = token ? token.id : null;
  if (
    accessToken &&
    !options.headers &&
    options.headers &&
    !options.headers.Authorization &&
    !externalUrl
  ) {
    options.headers.Authorization = accessToken;
  }
  const safeurl = externalUrl ? url : `${REACT_APP_API_BASE_URL}/${url.replace('//', '/')}`;
  if (!externalUrl) {
    options.headers = {
      ...options.headers,
      [HeadersName.X_SELLER_ID]: codeApporteur,
      [HeadersName.X_SELLER_CHANNEL_ID]: codeCanal,
      [HeadersName.X_TENANT_ID]: REACT_APP_TENANT,
    };
  }
  return [safeurl, options];
}

/*
 * @deprecated use requestSOWithHeaders instead
 */
export function* requestJSON(url, opt) {
  const [safeurl, options] = yield getOptions(url, opt);
  return yield SharedRequestJSON(safeurl, options);
}
