import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import AppConfig from 'src/config/config';

import createReducer, { staticReducers } from './reducers';

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error(error);
  },
});

export default function configureStoreMethod(initialState = {}, history: History) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    AppConfig.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    // @ts-ignore TODO js migration
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-ignore TODO js migration
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          trace: true,
        })
      : compose;
  /* eslint-enable */

  const dumbReducers = Object.keys(initialState)
    // We always have static reducers loaded
    .filter((reducerKey) => !Object.keys(staticReducers).includes(String(reducerKey)))
    .reduce((result, reducerKey) => {
      // Create empty reducers for keys that don't have loaded dynamic reducer yet
      // They will be replaced by the real one
      // eslint-disable-next-line no-param-reassign
      result[reducerKey] = (state = null) => state;
      return result;
    }, {});

  const store = configureStore({
    reducer: createReducer(dumbReducers),
    preloadedState: initialState,
    enhancers: [composeEnhancers(...enhancers)],
    devTools: false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(),
  });

  // Extensions
  // @ts-ignore TODO js migration
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore TODO js migration
  store.injectedReducers = { ...dumbReducers }; // Reducer registry
  // @ts-ignore TODO js migration
  store.injectedSagas = {}; // Saga registry
  // @ts-ignore TODO js migration
  store.createReducer = createReducer;

  /**
   * store persistor in store to gobal further use
   */
  // @ts-ignore TODO js migration
  store.persistor = persistStore(store, null);
  // store.persistor.persist();

  return store;
}
