import { useDispatch, useSelector } from 'react-redux';
import { isFeatureEnabled } from 'src/utils/featureFlag';
import { FEATURES_NAMES } from 'src/utils/featureFlag/types';
import { selectBusinessPart } from 'src/hocs/App/selectors';
import { setCustomerServicePhoneNumber } from 'src/hocs/WithCustomerService/logic.helper';
import { useEffect } from 'react';
import { setCustomerServiceAction } from 'src/hocs/WithCustomerService/actions';
import { makeIsProfessional } from 'src/hocs/WithUserInformations/selectors';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import { ContactChannelEnum, WithCustomerServiceType as ContactChannelHookResponse } from './types';

export const useContactChannel = (): ContactChannelHookResponse => {
  const dispatch = useDispatch();
  const { intlMessage } = useIntlMessage();
  const isProfessional = useSelector(makeIsProfessional());
  const businessPart = useSelector(selectBusinessPart);
  const isPhoneEnabled = isFeatureEnabled(FEATURES_NAMES.FF_CUSTOMER_SERVICE_PHONE);
  const isEmailEnabled = isFeatureEnabled(FEATURES_NAMES.FF_CUSTOMER_SERVICE_EMAIL);

  let contactChannel = null;
  let isAllContactChannel = false;

  if (isPhoneEnabled && isEmailEnabled) {
    contactChannel = ContactChannelEnum.ALL;
    isAllContactChannel = true;
  } else if (isPhoneEnabled) {
    contactChannel = ContactChannelEnum.PHONE;
  } else if (isEmailEnabled) {
    contactChannel = ContactChannelEnum.EMAIL;
  }

  const phoneNumber = setCustomerServicePhoneNumber({
    isProfessional,
    businessPart,
    intlMessage,
  });

  const emailAddress = intlMessage('shared.i18n.customerServiceEmailContact');
  const privacyProtectionEmailAddress = intlMessage('shared.i18n.privacyProtectionEmailAddress');
  const privacyProtectionPostalAddress = intlMessage('shared.i18n.privacyProtectionPostalAddress');

  const contactChannelInformations = {
    contactChannel,
    isPhoneEnabled,
    isEmailEnabled,
    phoneNumber,
    emailAddress,
    isAllContactChannel,
    privacyProtectionEmailAddress,
    privacyProtectionPostalAddress,
  };

  useEffect(() => {
    if (contactChannelInformations) {
      dispatch(setCustomerServiceAction(contactChannelInformations));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPart, dispatch, isProfessional]);

  return contactChannelInformations;
};
