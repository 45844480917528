import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const ErrorHtmlKeys = {
  PAUSE_SUBSCRIPTION_FORM: 'PAUSE_SUBSCRIPTION_FORM',
  API_PAY_TIMEOUT_FORM: 'API_PAY_TIMEOUT_FORM',
  CONGRATULATION_ERROR: 'CONGRATULATION_ERROR',
  GENERIC_ERROR: 'GENERIC_ERROR',
  NO_COMPATIBLE_OFFER: 'NO_COMPATIBLE_OFFER',
  DISALLOW_ELD_DURING_WINTER_BREAK: 'DISALLOW_ELD_DURING_WINTER_BREAK',
  AGE_LIMIT_EXCEEDED: 'AGE_LIMIT_EXCEEDED',
} as const;
export type ErrorHtmlKeysType = ValueOf<typeof ErrorHtmlKeys>;
