import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const ESTIMATION_FORM_REDUCER = 'EstimationFormReducer';
export const ESTIMATION_MAXIMUM_UNKNOWN_ANSWERS = 3;
export const MINIMUM_HEATING_TEMPERATURE = 12;
export const MAXIMUM_HEATING_TEMPERATURE = 29;
export const ROW_BOTTOM = 20;
export const NULL = 'NULL' as const;
export const OTHER = 'OTHER' as const;

export const ESTIMATION_FORM_INPUT = {
  CUSTOMER_SEGMENT: 'customerSegment',
  INSEE_CODE: 'inseeCode',
  ZIP_CODE: 'zipCode',
  SESSION_ID: 'sessionId',
  // Step 1 = lodgment
  HOUSING_TYPE: 'housingType',
  FLOOR: 'floor',
  HOUSE_SHAPE: 'houseShape',
  HOUSE_POSITION: 'housePosition',
  APARTMENT_POSITION: 'apartmentPosition',
  SURFACE: 'surface',
  CONSTRUCTION_YEAR: 'constructionYear',
  INSULATION_YEAR: 'insulationYear',
  HAS_INSULATION_WORK: 'hasInsulationWork',
  PEOPLE_COUNT: 'peopleCount',
  OCCUPATION_TIME: 'occupationTime',
  // Step 2 = usages
  HEATING_TEMPERATURE: 'heatingTemperature',
  HOT_WATER_USE: 'hotWaterUse',
  CITY_GAS_USE: 'cityGasUse',
  HOUSE_HEATING_TYPE: 'houseHeatingType',
  ALTERNATIVE_HEATING_TYPE: 'alternativeHeatingType',
  ELECTRIC_HEATING_TYPE: 'electricHeatingType',
  WATER_HEATING_TYPE: 'waterHeatingType',
  HOUSING_COOKING: 'housingCooking',
  LIGHTNING_TYPE: 'lightningType',
  // Step 3 = meter and equipment
  SUBSCRIBED_POWER: 'subscribedPower',
  TARIFF_OPTION: 'tariffOption',
  WASHING_MACHINE: 'washingMachine',
  FREEZER: 'freezer',
  DISHWASHER: 'dishwasher',
  DRYER: 'dryer',
  GAS_OVEN: 'gasOven',
  HAS_ELECTRIC_VEHICLE: 'hasElectricVehicle',
  ELECTRIC_VEHICLE_CHARGING: 'electricVehicleCharging',
  AIR_CONDITIONING_TYPE: 'airConditioningType',
  HAS_POOLS: 'hasPools',
  POOL_HEATING_TYPE: 'poolHeatingType',
} as const;

export const EstimatorSections = {
  LODGMENT: 'lodgment',
  USAGE: 'usage',
  METER: 'meter',
  EQUIPMENT: 'equipment',
} as const;

export const HousingType = {
  APARTMENT: 'APARTMENT',
  HOUSE: 'HOUSE',
} as const;

export type HousingType = ValueOf<typeof HousingType>;

export const Floor = {
  FLOOR_O1: '01',
  FLOOR_O2: '02',
  FLOOR_O3: '03',
  APPT: 'APPT',
  NULL,
} as const;

export type FloorType = ValueOf<typeof Floor>;

export const HouseShape = {
  SQUARE: 'SQUARE',
} as const;

export type HouseShapeType = ValueOf<typeof HouseShape>;

export const HousePosition = {
  POSITION_1SIDE: '1SIDE',
  POSITION_2SIDES: '2SIDES',
  INDEPENDENT: 'INDEPENDENT',
  NULL,
} as const;

export type HousePositionType = ValueOf<typeof HousePosition>;

export const ApartmentPosition = {
  GROUND_FLOOR: 'GROUND_FLOOR',
  CURRENT_FLOOR: 'CURRENT_FLOOR',
  TOP_FLOOR: 'TOP_FLOOR',
  NEITHER_TOP_OR_BOTTOM: 'NEITHER_TOP_OR_BOTTOM',
  NULL,
} as const;

export type ApartmentPositionType = ValueOf<typeof ApartmentPosition>;

export type SurfaceType = HeatingTemperatureType;

export const ConstructionYear = {
  BEFORE_1974: 'BF1974',
  BEFORE_1981: 'BF1981',
  BEFORE_1989: 'BF1989',
  BEFORE_2000: 'BF2000',
  BEFORE_2005: 'BF2005',
  BEFORE_2012: 'BF2012',
  BEFORE_2020: 'BF2020',
  AFTER_2020: 'AF2020',
  NULL,
} as const;

export type ConstructionYearType = ValueOf<typeof ConstructionYear>;

export const InsulationYear = {
  BEFORE_2012: 'BF2012',
  BEFORE_2020: 'BF2020',
  AFTER_2020: 'AF2020',
  NULL,
};
export type InsulationYearType = ValueOf<typeof InsulationYear>;

export type PeopleCountType = string;

export const OccupationTime = {
  ALWAYS: 'ALWAYS',
  EVENING_WEEKEND: 'EVENING_WEEKEND',
  WEEKEND_VACATIONS: 'WEEKEND_VACATIONS',
  SUMMER: 'SUMMER',
  NULL,
};
export type OccupationTimeType = ValueOf<typeof OccupationTime>;

export const TariffOption = {
  BASE: 'BASE',
  HPHC: 'HPHC',
} as const;

export type TariffOptionType = ValueOf<typeof TariffOption>;

export const HouseHeatingType = {
  ELECTRICITY: 'ELECTRICITY',
  GAS: 'GAS',
  WOOD: 'WOOD',
  OTHER,
  CHUR: 'CHUR',
  NULL,
} as const;

export type HouseHeatingType = ValueOf<typeof HouseHeatingType>;

export const ElectricHeating = {
  CONVECTOR: 'CONVECTOR',
  INERTIA: 'INERTIA',
  HEAT_PUMP: 'HEAT_PUMP',
} as const;

export type ElectricHeatingType = ValueOf<typeof ElectricHeating>;

export const PoolHeating = {
  ELECTRIC_RADIANT: 'ELECTRIC_RADIANT',
  HEAT_PUMP: 'HEAT_PUMP',
} as const;

export type PoolHeatingType = ValueOf<typeof PoolHeating>;

export const AlternativeHeating = {
  NO: 'NO',
  WOOD: 'WOOD',
} as const;

export type AlternativeHeatingType = ValueOf<typeof AlternativeHeating>;

export type HeatingTemperatureType = string | typeof NULL;

export const HotWaterUse = {
  SHOWER: 'SHOWER',
  SHOWER_BATH: 'SHOWER_BATH',
  BATH: 'BATH',
  HOT_BATH: 'HOT_BATH',
  NULL,
} as const;
export type HotWaterUseType = ValueOf<typeof HotWaterUse>;

export const HousingCookingType = {
  ELGA: 'ELGA',
  GAS: 'GAS',
  ELECTRICITY: 'ELECTRICITY',
  OTHER,
  NULL,
} as const;
export type HousingCookingType = ValueOf<typeof HousingCookingType>;

export const LightningType = {
  ECO: 'ECO',
  NORMAL: 'NORMAL',
  HALOGEN: 'HALOGEN',
} as const;
export type LightningType = ValueOf<typeof LightningType>;

export const ElectricVehicleCharging = {
  YES: 'YES',
  NO: 'NO',
  SOMETIMES: 'SOMETIMES',
} as const;
export type ElectricVehicleChargingType = ValueOf<typeof ElectricVehicleCharging>;

export const CustomerSegment = {
  INDIVIDUAL: 'INDIVIDUAL',
  PROFESSIONAL: 'PROFESSIONAL',
} as const;

export const WaterHeatingType = {
  ELECTRICITY_STORAGE_TANK: 'ELECTRICITY_STORAGE_TANK',
  GAS_BOILER: 'GAS_BOILER',
  OTHER,
  NULL,
} as const;

export type WaterHeatingType = ValueOf<typeof WaterHeatingType>;

export type SubscribedPower = '3' | '6' | '9' | '12' | '15' | '18' | '24' | '30' | '36';

export const AirConditioning = {
  AIR_CONDITIONING_MOB: 'MOB',
  AIR_CONDITIONING_1ZO: '1ZO',
  AIR_CONDITIONING_NZO: 'NZO',
  AIR_CONDITIONING_NO: 'NO',
} as const;

export type AirConditioningType = ValueOf<typeof AirConditioning>;
