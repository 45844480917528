import { AxiosResponse } from 'axios';

import * as c from './constants';
import * as t from './types';

// TODO: remove this action

export function loadingStepAction(step) {
  return {
    type: c.LOADING_STEP,
    payload: step,
  };
}

// TODO: remove this action
export function updateQuotationAction(devis) {
  return {
    type: c.UPDATE_QUOTATION,
    payload: devis,
  };
}

// TODO: remove this action
export function devisSuccessAction(devis: t.QuotationResponseType): t.QuotationSuccessActionType {
  return {
    type: c.DEVIS_SUCCESS_ACTION,
    payload: devis,
  };
}

/*
  NEW ACTIONS
 */

export function createQuotationRequestAction(): t.CreateQuotationRequestActionType {
  return {
    type: c.CREATE_QUOTATION_REQUEST,
  };
}

export function createQuotationSuccessAction(
  quotation: t.QuotationResponseType,
): t.CreateQuotationSuccessActionType {
  return {
    type: c.CREATE_QUOTATION_SUCCESS,
    payload: quotation,
  };
}

export function createQuotationFailureAction(
  error: AxiosResponse,
): t.CreateQuotationFailureActionType {
  return {
    type: c.CREATE_QUOTATION_FAILURE,
    payload: error,
  };
}

export function updateQuotationRequestAction(): t.UpdateQuotationRequestActionType {
  return {
    type: c.UPDATE_QUOTATION_REQUEST,
  };
}

export function updateQuotationSuccessAction(
  quotation: t.QuotationResponseType,
): t.UpdateQuotationSuccessActionType {
  return {
    type: c.UPDATE_QUOTATION_SUCCESS,
    payload: quotation,
  };
}

export function updateQuotationFailureAction(
  error: AxiosResponse,
): t.UpdateQuotationFailureActionType {
  return {
    type: c.UPDATE_QUOTATION_FAILURE,
    payload: error,
  };
}
