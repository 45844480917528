/*
 *
 * LivraisonPod slice
 *
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as t from './types';
import * as c from './constants';

export const initialState: t.LivraisonPodReducerType = {
  optionModalOpen: false,
};

const { actions, reducer } = createSlice({
  name: c.LIVRAISON_POD,
  initialState,
  reducers: {
    setOptionModalOpenAction: (state, action: PayloadAction<boolean>) => ({
      optionModalOpen: action.payload,
    }),
  },
});

export const { setOptionModalOpenAction } = actions;

export default reducer;
