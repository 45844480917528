import { MessageDescriptor, PrimitiveType, IntlFormatters } from 'react-intl';
import strings from 'src/translations/strings.json';

export const LANGUAGES = {
  FR: 'fr',
} as const;

export type TranslationKeys = keyof typeof strings;
export type FormatXMLElementFn<T, R = string | T | (string | T)[]> = (
  parts: Array<string | T>,
) => R;

export type MessageOptions = Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
export type MessageOptionsElement<T> = Record<string, PrimitiveType | T | FormatXMLElementFn<T>>;

export interface CustomMessageDescriptor extends MessageDescriptor {
  id: TranslationKeys;
}

export type MessagesType = Record<string | number | symbol, CustomMessageDescriptor>;

export interface LanguageProviderProps {
  children: React.ReactElement | React.ReactNode;
}

export type IntlMessageType = (key: TranslationKeys, options?: MessageOptions) => string;

export type FormatMessageType = IntlFormatters['formatMessage'];
