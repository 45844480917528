import { resetErrorNewAction } from 'src/hocs/App/actions';
import { postQuotation } from 'src/hocs/WithDevis/logic.helper';
import { BUSINESS_PARTS } from 'src/hocs/App/constants';
import routes from 'src/utils/routes/routes';
import type { QuotationResponseType } from 'src/hocs/WithDevis/types';
import type { Dispatch } from '@reduxjs/toolkit';
import type { CallHistoryMethodAction } from 'connected-react-router';
import { switchElectricityOptionAction } from 'src/components/pages/ServicesOptions/actions';
import { ELECTRICITY_OPTIONS } from 'src/components/pages/ServicesOptions/constants';
import { setCode } from 'src/hocs/WithPromoCode/actions';

interface HandleCloseModalArgs {
  dispatch: Dispatch;
  quotation: QuotationResponseType;
}

interface HandleChangeOfferArgs extends HandleCloseModalArgs {
  navigateTo: (
    route: string,
    urlParams?: Record<string, unknown>,
    state?: Record<string, unknown>,
  ) => CallHistoryMethodAction<[string, Record<string, unknown>?]>;
}

export const handleCloseModal = async ({ dispatch, quotation }: HandleCloseModalArgs) => {
  try {
    await postQuotation({ quotation, dispatch });
  } catch (error) {
    console.error('Something went wrong in handleCloseModal()', error);
  } finally {
    dispatch(resetErrorNewAction());
  }
};

export const handleChangeOffer = async ({
  dispatch,
  quotation,
  navigateTo,
}: HandleChangeOfferArgs) => {
  try {
    const quotationToPost = {
      ...quotation,
      promoCode: null,
      options: [],
      services: [],
    };
    await postQuotation({
      quotation: quotationToPost,
      dispatch,
      xSellerId: BUSINESS_PARTS.EKWATEUR,
    });
  } catch (error) {
    console.error('Something went wrong in handleChangeOffer()', error);
  } finally {
    dispatch(switchElectricityOptionAction(ELECTRICITY_OPTIONS.DEFAULT_OPTION));
    dispatch(setCode(null));
    dispatch(resetErrorNewAction());
    navigateTo(routes.devis);
  }
};
