import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import { Url } from 'src/utils/routes/routes';
import { constrainRequestPayload } from 'src/utils/typescript/typescript.helper';

import { useRequest } from 'src/hooks/useRequest';
import { useErrorCatcher } from 'src/hooks/useErrorCatcher';
import { useSelectState } from 'src/hooks/useSelectState';

import { makeSelectDevisNew } from 'src/hocs/WithDevis/selectors';
import { QuotationResponseType } from 'src/hocs/WithDevis/types';
import { selectWithAddress } from 'src/hocs/WithAddress/selectors';
import { CurrentAddressType } from 'src/hocs/WithAddress/types';

import API_ROUTES from 'src/utils/api/api-routes';
import { makeSelectUserInformations } from 'src/hocs/WithUserInformations/selectors';
import { makeSelectSubscription, selectCurrentSubscription } from './selectors';
import {
  requestStartPresubscriptionAction,
  requestStartPresubscriptionSuccessAction,
  requestStartPresubscriptionFailureAction,
  requestSubscriptionAction,
  requestSubscriptionFailureAction,
  requestSubscriptionSuccessAction,
  requestFinalizeSubscriptionAction,
  requestFinalizeSubscriptionSuccessAction,
  requestFinalizeSubscriptionFailureAction,
} from './actions';
import { getSubscriptionStatus } from './business.helper';
import * as t from './types';
import * as c from './constants';

const { subscriptions = '' } = API_ROUTES;
const subscriptionUrl = `${subscriptions}/:id`;
const startPreSubscriptionUrl = `${subscriptions}/start-presubscription/:id`;
const finalizeSubscriptionUrl = `${subscriptions}/:id/finalize`;

export function useGetSubscription(id: string) {
  const dispatch = useDispatch();
  const onRequest = () => dispatch(requestSubscriptionAction());
  const onSuccess = (response: t.SubscriptionResponseType) =>
    dispatch(requestSubscriptionSuccessAction(response));
  const onFailure = (error: AxiosResponse) => dispatch(requestSubscriptionFailureAction(error));

  return useRequest(Url(subscriptions, { id }), 'GET', null, onRequest, onSuccess, onFailure);
}

export function useUpdateSubscription(option?: { mergePayloadWithStore: boolean }) {
  const mergePayloadWithStore = option?.mergePayloadWithStore !== false;
  const selectState = useSelectState();
  const dispatch = useDispatch();

  const quotation: QuotationResponseType = selectState(makeSelectDevisNew());
  const oldSubscription: t.SubscriptionResponseType = selectState(makeSelectSubscription());
  const subscriptionId = oldSubscription?.id;
  const url = subscriptionId ? Url(subscriptionUrl, { id: subscriptionId }) : subscriptions;
  const method = subscriptionId ? 'put' : 'post';

  const getBody = (newSubscription) => {
    const updatedSubscription: t.SubscriptionRequestType = mergePayloadWithStore
      ? {
          ...oldSubscription,
          ...newSubscription,
          quotation: quotation?.id,
        }
      : {
          ...newSubscription,
        };
    return constrainRequestPayload<t.SubscriptionRequestType>(
      updatedSubscription,
      c.SUBSCRIPTION_FIELDS,
    );
  };

  const onRequest = () => dispatch(requestSubscriptionAction());
  const onSuccess = (response: t.SubscriptionResponseType) =>
    dispatch(requestSubscriptionSuccessAction(response));
  const onFailure = (error: AxiosResponse) => dispatch(requestSubscriptionFailureAction(error));

  return useRequest(url, method, getBody, onRequest, onSuccess, onFailure);
}

// Save user e-mail information, in order to continue the subscription later on
export const usePauseSubscription = () => {
  const selectState = useSelectState();
  const { fetchUrl: fetchSubscription, error } = useUpdateSubscription();
  const { firstName, lastName, companyName } = useSelector(makeSelectUserInformations());
  useErrorCatcher(error);

  // Get subscription address format in case user has not a subscription yet (before Page Contrat)
  const currentAddress: CurrentAddressType = selectState(selectWithAddress);
  const currentSubscription: t.SubscriptionResponseType = selectState(selectCurrentSubscription);
  let subscriptionAddress = {};
  if (!currentSubscription) {
    subscriptionAddress = {
      consumptionZipCode: currentAddress?.zipCode,
      consumptionStreetName: currentAddress?.streetName,
      consumptionCity: currentAddress?.city,
      consumptionStreetNumber: currentAddress?.streetNumber,
    };
  }

  return (email?: string) =>
    fetchSubscription({
      ...subscriptionAddress,
      saveAndResume: true,
      frontStatus: getSubscriptionStatus(),
      email,
      firstName,
      lastName,
      companyName,
    });
};

export function useStartPresubscription(id: string) {
  const dispatch = useDispatch();
  const onRequest = () => dispatch(requestStartPresubscriptionAction());
  const onSuccess = () => dispatch(requestStartPresubscriptionSuccessAction());
  const onFailure = (error: AxiosResponse) =>
    dispatch(requestStartPresubscriptionFailureAction(error));

  return useRequest(
    Url(startPreSubscriptionUrl, { id }),
    'PUT',
    null,
    onRequest,
    onSuccess,
    onFailure,
  );
}

export function useFinalizeSubscription(id: string) {
  const dispatch = useDispatch();
  const onRequest = () => dispatch(requestFinalizeSubscriptionAction());
  const onSuccess = (payload) => dispatch(requestFinalizeSubscriptionSuccessAction(payload));
  const onFailure = (error: AxiosResponse) =>
    dispatch(requestFinalizeSubscriptionFailureAction(error));

  return useRequest(
    `${finalizeSubscriptionUrl.replace(':id', id)}`,
    'POST',
    null,
    onRequest,
    onSuccess,
    onFailure,
  );
}
