export const REQUEST_SUBSCRIPTION_SUCCESS = 'app/Subscription/REQUEST_SUBSCRIPTION_SUCCESS';
export const REQUEST_SUBSCRIPTION = 'app/Subscription/REQUEST_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'app/Subscription/UPDATE_SUBSCRIPTION';
export const WITHSUBSCRIPTION = 'WithSubscription';
export const SET_FRAUD_DOUBLON = 'SET_FRAUD_DOUBLON';
export const MES = 'MES';
export const CHF = 'CHF';
export const PMES = 'PMES';
export const STATUS = {
  IS_QUOTATION: 'IS_QUOTATION', // avant service option
  IS_SERVICES_OPTIONS: 'IS_SERVICES_OPTIONS', // service option
  IS_PROFILE: 'IS_PROFILE', // contrat
  IS_WAITING_FOR_POD: 'IS_WAITING_FOR_POD', // malivraison/pod
  IS_DELIVERY: 'IS_DELIVERY', // myDelivery
  IS_BILLING_METHOD: 'IS_BILLING_METHOD', // recap
  IS_SIGNED: 'IS_SIGNED', // payment success
  IS_PAYMENT: 'IS_PAYMENT', // payment
  IS_SUBSCRIPTION_COMPLETED: 'IS_SUBSCRIPTION_COMPLETED', // user already subscribed
  IS_QUICK_SIGNATURE_PAGE: 'IS_QUICK_SIGNATURE_PAGE',
};
export const USER_STATUS = {
  IS_493: 1,
  ALREADY_CLIENT: 2,
};
