import * as T from 'src/utils/featureFlag/types';

// With localhost, default variable are used
// On all others environments you need to change thoses variables on gitlab : Settings > CI/CD > Variables

const featureFlags = {
  [T.FEATURES_NAMES.FF_HOMEPAGE_AUTOCOMPLETE]: JSON.parse(
    process.env.REACT_APP_FF_HOMEPAGE_AUTOCOMPLETE || 'true',
  ),
  [T.FEATURES_NAMES.FF_HAS_WITHDRAWAL_PERIOD_BYPASS]: JSON.parse(
    process.env.REACT_APP_FF_HAS_WITHDRAWAL_PERIOD_BYPASS || 'true',
  ),
  [T.FEATURES_NAMES.FF_SCRIPT_DATA_DOG]: JSON.parse(
    process.env.REACT_APP_FF_SCRIPT_DATA_DOG || 'false',
  ),
  [T.FEATURES_NAMES.FF_SCRIPT_ZD_ASSETS]: JSON.parse(
    process.env.REACT_APP_FF_SCRIPT_ZD_ASSETS || 'true',
  ),
  [T.FEATURES_NAMES.FF_SCRIPT_GOOGLE_TAG_MANAGER]: JSON.parse(
    process.env.REACT_APP_FF_SCRIPT_GOOGLE_TAG_MANAGER || 'true',
  ),
  [T.FEATURES_NAMES.FF_SCRIPT_DIDOMI]: JSON.parse(process.env.REACT_APP_FF_SCRIPT_DIDOMI || 'true'),
  [T.FEATURES_NAMES.FF_HIDE_MIN_PRICES]: JSON.parse(
    process.env.REACT_APP_FF_HIDE_MIN_PRICES || 'false',
  ),
  [T.FEATURES_NAMES.FF_ELD_WINTER_BREAK]: JSON.parse(
    process.env.REACT_APP_FF_ELD_WINTER_BREAK || 'false',
  ),
  [T.FEATURES_NAMES.FF_SHOW_GAS_PRICE]: JSON.parse(
    process.env.REACT_APP_FF_SHOW_GAS_PRICE || 'false',
  ),
  [T.FEATURES_NAMES.FF_HIDE_ALTERNATIVE_CONSUMPTION_LINK]: JSON.parse(
    process.env.REACT_APP_FF_HIDE_ALTERNATIVE_CONSUMPTION_LINK || 'true',
  ),
  [T.FEATURES_NAMES.FF_PRO_JOURNEY]: JSON.parse(process.env.REACT_APP_FF_PRO_JOURNEY || 'true'),
  [T.FEATURES_NAMES.FF_CUSTOMER_SERVICE_ON_SATURDAY]: JSON.parse(
    process.env.REACT_APP_FF_CUSTOMER_SERVICE_ON_SATURDAY || 'true',
  ),
  [T.FEATURES_NAMES.FF_SHOW_PREMIUM_PLUS_SUPPLIERS]: JSON.parse(
    process.env.REACT_APP_FF_SHOW_PREMIUM_PLUS_SUPPLIERS || 'true',
  ),
  [T.FEATURES_NAMES.FF_CARBON_IMPACT_NOTIFICATION]: JSON.parse(
    process.env.REACT_APP_FF_CARBON_IMPACT_NOTIFICATION || 'true',
  ),
  [T.FEATURES_NAMES.FF_QUOTATION_SAVED_AMOUNT_EXPLANATION]: JSON.parse(
    process.env.REACT_APP_FF_QUOTATION_SAVED_AMOUNT_EXPLANATION || 'true',
  ),
  [T.FEATURES_NAMES.FF_CONTRACT_PARTNER_NEWSLETTER]: JSON.parse(
    process.env.REACT_APP_FF_CONTRACT_PARTNER_NEWSLETTER || 'true',
  ),
  [T.FEATURES_NAMES.FF_CONTRACT_BIRTHDAY_GIFT_CARD]: JSON.parse(
    process.env.REACT_APP_FF_CONTRACT_BIRTHDAY_GIFT_CARD || 'true',
  ),
  [T.FEATURES_NAMES.FF_CUSTOMER_SERVICE_PHONE]: JSON.parse(
    process.env.REACT_APP_FF_CUSTOMER_SERVICE_PHONE || 'true',
  ),
  [T.FEATURES_NAMES.FF_CUSTOMER_SERVICE_EMAIL]: JSON.parse(
    process.env.REACT_APP_FF_CUSTOMER_SERVICE_EMAIL || 'true',
  ),
};

export default featureFlags;
