import routes from 'src/utils/routes/routes';

import { FrontStatusEnum } from 'src/hocs/WithSubscriptionNew/types';

export const getSubscriptionStatus = (): FrontStatusEnum => {
  const path: string = window.location.pathname;
  if (RegExp(`${routes.souscrire}$`).test(path)) {
    return FrontStatusEnum.IS_QUOTATION;
  }
  if (path.match(`${routes.devis}`)) {
    return FrontStatusEnum.IS_QUOTATION;
  }
  if (path.match(`${routes.choixEnergie}`)) {
    return FrontStatusEnum.IS_QUOTATION;
  }
  if (path.match(`${routes.serviceOption}`)) {
    return FrontStatusEnum.IS_SERVICES_OPTIONS;
  }
  if (path.match(`${routes.livraisonPod}`)) {
    return FrontStatusEnum.IS_PROFILE;
  }
  if (path.match(`${routes.myDelivery}`)) {
    return FrontStatusEnum.IS_PROFILE;
  }
  if (path.match(`${routes.contrat}`)) {
    return FrontStatusEnum.IS_PROFILE;
  }
  if (path.match(`${routes.modeFacturation}`)) {
    return FrontStatusEnum.IS_PROFILE;
  }
  if (path.match(`${routes.summary}`)) {
    return FrontStatusEnum.IS_SIGNED;
  }
  if (path.match(`${routes.resumeWithoutPOD}`)) {
    return FrontStatusEnum.IS_PROFILE;
  }
  return FrontStatusEnum.IS_QUOTATION;
};
