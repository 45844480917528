import { SET_USER_INFORMATIONS } from './constants';
import { CustomerTypeEnum, WithUserInformationsType } from './types';

export const initialState: WithUserInformationsType = {
  customerType: CustomerTypeEnum.PROFESSIONAL,
  isProfessional: false,
  isUserAnonymous: true,
  firstName: '',
  lastName: '',
  customerId: null,
};

function WithUserInformationsReducer(state = initialState, action): WithUserInformationsType {
  switch (action.type) {
    case SET_USER_INFORMATIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export default WithUserInformationsReducer;
