/* eslint-disable @typescript-eslint/no-explicit-any -- too complex to solve these any */
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';
import getInjectors, { CustomStore } from './sagaInjectors';

interface SagaInjectorProps {
  key: string;
  saga: () => void;
  mode: string;
}

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.DAEMON) the saga will be started
 * on component mount and never canceled or started again. Another two options:
 *   - constants.RESTART_ON_REMOUNT — the saga will be started on component mount and
 *   cancelled with `task.cancel()` on component unmount for improved performance,
 *   - constants.ONCE_TILL_UNMOUNT — behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 */
export default ({ key, saga, mode }: SagaInjectorProps) =>
  <TProps extends Record<string, any>>(WrappedComponent: React.ComponentType<TProps>) => {
    class InjectSaga extends React.Component<TProps> {
      static WrappedComponent = WrappedComponent;

      // eslint-disable-next-line react/static-property-placement
      static contextType = ReactReduxContext;

      // eslint-disable-next-line react/static-property-placement
      static displayName = `withSaga(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      injectors: ReturnType<typeof getInjectors>;

      constructor(props: TProps, context: any) {
        super(props, context);

        this.injectors = getInjectors(context.store);

        this.injectors.injectSaga(
          key,
          {
            saga,
            mode,
          },
          this.props,
        );
      }

      componentWillUnmount() {
        this.injectors.ejectSaga(key);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
  };

const useInjectSaga = ({ key, saga, mode = null }) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    const injectors = getInjectors(context.store as CustomStore);
    injectors.injectSaga(key, {
      saga,
      mode,
    });

    return () => {
      injectors.ejectSaga(key);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const SAGA_KEYS = {
  CHOIX_ENERGIE: 'CHOIX_ENERGIE',
  LIVRAISON_POD: 'LIVRAISON_POD',
  SOUSCRIRE: 'SOUSCRIRE',
  FELICITATION: 'FELICITATION',
  NON_ELIGIBLE: 'NonEligible',
  QUOTATION: 'QUOTATION',
  SERVICES_OPTIONS: 'SERVICES_OPTIONS',
  ESTIMATION: 'ESTIMATION',
  LANGUAGE_PROVIDER: 'LANGUAGE_PROVIDER',
  MENSUALITY_SAGA: 'MENSUALITY_SAGA',
  PAYMENT: 'PAYMENT',
  SUMMARY: 'SUMMARY',
  GROUP_PURCHASE_SUMMARY: 'GROUP_PURCHASE_SUMMARY',
  MY_DELIVERY: 'MY_DELIVERY',
  REPRISE: 'Reprise',
};

export { useInjectSaga, SAGA_KEYS };
