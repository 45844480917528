import { semantical } from 'src/theme/tokens/semantical';
import { customComponents } from 'src/theme/tokens/components/custom';

const { radius, borderWidth } = customComponents;

export const inputTheme = {
  borderWidth: `${semantical.sizes.border[borderWidth]}px`,
  colors: {
    background: {
      default: semantical.colors.background.special.transparent,
      interaction: semantical.colors.background.special.transparent,
      error: semantical.colors.background.state.error,
      disabled: semantical.colors.background.state.disabled.strong,
      checkedButtonActive: semantical.colors.background.neutral.stronger,
    },
    border: {
      default: semantical.colors.border.neutral.weakest,
      interaction: semantical.colors.border.interaction.active,
      checkedButtonActive: semantical.colors.border.interaction.active,
      disabled: semantical.colors.border.state.disabled.weakest,
      error: semantical.colors.border.state.error,
    },
    text: {
      default: semantical.colors.text.neutral.weakest,
      interaction: semantical.colors.text.interaction.active,
      disabled: semantical.colors.text.state.disabled.weak,
      error: semantical.colors.text.state.error,
    },
  },
  radius: semantical.radius[radius.inputText],
};
