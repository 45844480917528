import { AxiosResponse } from 'axios';

import * as c from './constants';
import * as t from './types';

export const createPromoCodeRequestAction = (): t.CreatePromoCodeRequestActionType => ({
  type: c.CREATE_PROMO_CODE_REQUEST,
});

export const createPromoCodeSuccessAction = (
  data: t.PromoCodeResponseType,
): t.CreatePromoCodeSuccessActionType => ({
  type: c.CREATE_PROMO_CODE_SUCCESS,
  payload: data,
});

export const createPromoCodeFailureAction = (
  error: AxiosResponse,
): t.CreatePromoCodeFailureActionType => ({
  type: c.CREATE_PROMO_CODE_FAILURE,
  payload: error,
});

export const setCode = (promoCode?: string): t.SetCodeActionType => ({
  type: c.SET_CODE,
  payload: promoCode,
});

export const triggerPromoCodeRequestAction = (
  code: string,
): t.TriggerPromoCodeRequestActionType => ({
  type: c.TRIGGER_PROMO_CODE_REQUEST,
  payload: code,
});
