import { FEATURES_NAMES } from 'src/utils/featureFlag/types';

export const tenantScripts = [
  {
    async: true,
    'data-purposes': 'stockerde-tkrqz823',
    'data-vendor': 'c:zendesk-K4w2R3Ft',
    id: 'ze-snippet',
    isFeatureFlagEnabled: FEATURES_NAMES.FF_SCRIPT_ZD_ASSETS,
    src: 'https://static.zdassets.com/ekr/snippet.js?key=923c9c85-6cd7-45e8-9fed-9ca0212e26ca',
    type: 'didomi/javascript',
  },
  {
    async: true,
    'data-purposes': 'mesurerla-mJZhZzxD',
    'data-vendor': 'c:datadog-XyNLtrNh',
    isFeatureFlagEnabled: FEATURES_NAMES.FF_SCRIPT_DATA_DOG,
    src: `${process.env.PUBLIC_URL}/dd.js`,
    type: 'didomi/javascript',
  },
  {
    isFeatureFlagEnabled: FEATURES_NAMES.FF_SCRIPT_GOOGLE_TAG_MANAGER,
    innerHTML: `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://harry.ekwateur.fr/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY}');
    `,
  },
];
