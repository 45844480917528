export const zindex = {
  'z-0': '0',
  'z-10': '10',
  'z-20': '20',
  'z-30': '30',
  'z-40': '40',
  'z-50': '50',
  'z-auto': 'auto',
  'z-priority': 9999,
} as const;
