import { createSelector } from 'reselect';

import { AppStateType } from 'src/store/appstate.type';
import { WITHSUBSCRIPTION } from './constants';

const selectSubscription = (state: AppStateType) => state[WITHSUBSCRIPTION];
export const selectCurrentSubscription = createSelector(selectSubscription, (substate) => {
  if (!substate?.subscription) return null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isMandateSignature, ...otherProperties } = substate.subscription;
  return otherProperties;
});

export const makeSelectSubscription = () => selectCurrentSubscription;

export const makeSelectSubscriptionId = () =>
  createSelector(
    selectSubscription,
    (substate) => substate && substate.subscription && substate.subscription.id,
  );

export const makeSelectIsFraudDoublon = () =>
  createSelector(selectSubscription, (substate) => substate && substate.isFraudOrDoublon);
