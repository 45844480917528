import { ErrorReducerType } from 'src/components/organisms/Error/types';
import { ERROR_ACTION } from './constants';

export const initialState: ErrorReducerType = {
  error: null,
};

function ErrorReducer(state = initialState, action): ErrorReducerType {
  switch (action.type) {
    case ERROR_ACTION:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default ErrorReducer;
