import { IntlMessageType } from 'src/components/utils/LanguageProvider/intl.types';
import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const ContactChannelEnum = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  ALL: 'ALL',
  NONE: 'NONE',
};

type ContactChannelType = ValueOf<typeof ContactChannelEnum>;

export interface WithCustomerServiceType {
  contactChannel: ContactChannelType | null;
  isPhoneEnabled: boolean;
  isEmailEnabled: boolean;
  phoneNumber?: string;
  emailAddress?: string;
  isAllContactChannel: boolean;
  privacyProtectionEmailAddress: string;
  privacyProtectionPostalAddress: string;
}

export interface SetCustomerServicePhoneNumberParams {
  isProfessional: boolean;
  businessPart: string;
  intlMessage: IntlMessageType;
}
