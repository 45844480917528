import ReactDOMClient from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux';
import { getStoredState } from 'redux-persist';
import { ThemeProvider } from '@emotion/react/macro';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistConfig } from 'src/store/reducers';
import configureStore from 'src/store/configureStore';
import history from 'src/utils/browser/history';
import { emptyReduxCache, shouldPurgeCacheHelper } from 'src/store/emptyReduxCache';
import App from 'src/App';
import { setStore } from 'src/store/storeSingleton';
import LanguageProvider from 'src/components/utils/LanguageProvider';
import { theme } from 'src/theme';
import { GlobalStyles } from 'src/theme/global/global.styles';
import Head from 'src/components/utils/Head';
import { HelmetProvider } from 'react-helmet-async';

getStoredState(persistConfig).then((initialState) => {
  const rootElement = document.getElementById('root');
  const shouldPurgeCache = shouldPurgeCacheHelper();
  const storeInitialState = shouldPurgeCache ? {} : initialState;
  const store = configureStore(storeInitialState, history);
  setStore(store);

  const render = () => {
    // @ts-ignore TODO js migration
    emptyReduxCache(store.persistor);

    if (!rootElement) throw new Error('Failed to find the root element');
    const root = ReactDOMClient.createRoot(rootElement);

    root.render(
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <LanguageProvider>
                <PersistGate
                  loading={<div>loading</div>}
                  // @ts-ignore TODO js migration
                  persistor={store.persistor}
                >
                  <Head />
                  <App />
                </PersistGate>
              </LanguageProvider>
            </ConnectedRouter>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>,
    );
  };
  render();
});
