import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import * as t from './types';

export const YOUNGEST_SUBSCRIPTION_AGE = 18;
export const OLDEST_SUBSCRIPTION_AGE = 125;
export const SUBSCRIBER_AVERAGE_AGE = 35;
export const MILLISECONDS_IN_A_DAY = 86400000;

export const REQUEST_SUBSCRIPTION_NEW = 'app/Subscription/REQUEST_SUBSCRIPTION_NEW';
export const REQUEST_SUBSCRIPTION_SUCCESS = 'app/Subscription/REQUEST_SUBSCRIPTION_SUCCESS';
export const REQUEST_SUBSCRIPTION_FAILURE = 'app/Subscription/REQUEST_SUBSCRIPTION_FAILURE';
export const REQUEST_FINALIZE_SUBSCRIPTION = 'app/Subscription/REQUEST_FINALIZE_SUBSCRIPTION';
export const REQUEST_START_PRESUBSCRIPTION = 'app/Subscription/REQUEST_START_PRESUBSCRIPTION';
export const REQUEST_START_PRESUBSCRIPTION_SUCCESS =
  'app/Subscription/REQUEST_START_PRESUBSCRIPTION_SUCCESS';
export const REQUEST_START_PRESUBSCRIPTION_FAILURE =
  'app/Subscription/REQUEST_START_PRESUBSCRIPTION_FAILURE';
export const REQUEST_FINALIZE_SUBSCRIPTION_SUCCESS =
  'app/Subscription/REQUEST_FINALIZE_SUBSCRIPTION_SUCCESS';
export const REQUEST_FINALIZE_SUBSCRIPTION_FAILURE =
  'app/Subscription/REQUEST_FINALIZE_SUBSCRIPTION_FAILURE';
export const SET_FRAUD_DOUBLON = 'app/Subscription/SET_FRAUD_DOUBLON';
export const REQUEST_SAVE_EMAIL = 'app/Subscription/REQUEST_SAVE_EMAIL';
export const REQUEST_SAVE_EMAIL_SUCCESS = 'app/Subscription/REQUEST_SAVE_EMAIL_SUCCESS';
export const REQUEST_SAVE_EMAIL_FAILURE = 'app/Subscription/REQUEST_SAVE_EMAIL_FAILURE';
export const SWITCH_INVOICE_FREQUENCY = 'app/Subscription/SWITCH_INVOICE_FREQUENCY';

const buildSubscriptionFields = fieldsTypeCheckerFactory<t.SubscriptionRequestType>();
export const SUBSCRIPTION_FIELDS = buildSubscriptionFields({
  QUOTATION: 'quotation',
  CIVILITY: 'civility',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  BIRTH_DATE: 'birthdate',
  IS_SECOND_NAME: 'isSecondName',
  CIVILITY2: 'civility2',
  FIRST_NAME2: 'firstName2',
  LAST_NAME2: 'lastName2',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  MOBILE_NUMBER: 'mobileNumber',
  SIRET: 'siret',
  CODE_APE: 'codeApe',
  COMPANY_NAME: 'companyName',
  CONSUMPTION_STREET_NUMBER: 'consumptionStreetNumber',
  CONSUMPTION_STREET_NAME: 'consumptionStreetName',
  CONSUMPTION_ZIP_CODE: 'consumptionZipCode',
  CONSUMPTION_CITY: 'consumptionCity',
  CONSUMPTION_BUILDING: 'consumptionBuilding',
  CONSUMPTION_STAIRCASE: 'consumptionStaircase',
  CONSUMPTION_FLOOR: 'consumptionFloor',
  CONSUMPTION_APARTMENT_NUMBER: 'consumptionApartmentNumber',
  CONSUMPTION_ADDITIONAL_ADDRESS: 'consumptionAdditionalAddress',
  IS_INVOICE_ADDRESS_DIFFERENT: 'isInvoiceAddressDifferent',
  INVOICE_STREET_NUMBER: 'invoiceStreetNumber',
  INVOICE_STREET_NAME: 'invoiceStreetName',
  INVOICE_ZIP_CODE: 'invoiceZipCode',
  INVOICE_CITY: 'invoiceCity',
  INVOICE_BUILDING: 'invoiceBuilding',
  INVOICE_STAIRCASE: 'invoiceStaircase',
  INVOICE_FLOOR: 'invoiceFloor',
  INVOICE_APARTMENT_NUMBER: 'invoiceApartmentNumber',
  INVOICE_ADDITIONAL_ADDRESS: 'invoiceAdditionalAddress',
  CONSUMER_CIVILITY: 'consumerCivility',
  IS_CONSUMER_DIFFERENT: 'isConsumerDifferent',
  CONSUMER_FIRST_NAME: 'consumerFirstName',
  CONSUMER_LAST_NAME: 'consumerLastName',
  CONSUMER_PHONE_NUMBER: 'consumerPhoneNumber',
  CONSUMER_MOBILE_NUMBER: 'consumerMobileNumber',
  CONSUMER_EMAIL: 'consumerEmail',
  IS_SIGNED: 'isSigned',
  IS_BY_PASS_WITHDRAWAL_PERIOD: 'isBypassWithdrawalPeriod',
  PAYMENT_DAY: 'paymentDay',
  INVOICE_FREQUENCY: 'invoiceFrequency',
  INVOICE_SENDING_CHANNEL: 'invoiceSendingChannel',
  INVOICE_PAYMENT_METHOD: 'invoicePaymentMethod',
  ELECTRICITY_RELOCATION_COMMISSIONING_TYPE: 'electricityRelocationCommissioningType',
  GAZ_RELOCATION_COMMISSIONING_TYPE: 'gasRelocationCommissioningType',
  ELECTRICITY_INDEX_BASE: 'electricityIndexBase',
  ELECTRICITY_INDEX_LOW: 'electricityIndexLow',
  GAZ_INDEX: 'gasIndex',
  SAVE_AND_RESUME: 'saveAndResume',
  FRONT_STATUS: 'frontStatus',
  ELECTRICITY_DESIRED_ACTIVATION_DATE: 'electricityDesiredActivationDate',
  GAZ_DESIRED_ACTIVATION_DATE: 'gasDesiredActivationDate',
  HAS_ENERGY_DATA_CONSENT: 'hasEnergyDataConsent',
  CONSENT_COLLECTION: 'consentCollection',
  EXTERNAL_LEAD_ID: 'externalLeadId',
  IS_MANDATE_SIGNATURE: 'isMandateSignature',
});

const buildSubscriptionConsentFields = fieldsTypeCheckerFactory<t.ConsentCollectionType>();
export const SUBSCRIPTION_CONSENT_FIELDS = buildSubscriptionConsentFields({
  HAS_NEWSLETTER_CONSENT: 'consentCollection.newsletterConsentGranted',
  HAS_PARTNER_NEWSLETTER_CONSENT: 'consentCollection.partnerOffersConsentGranted',
});
