import { WithDevis1kWhReducerType } from 'src/hocs/WithDevis1kWh/types';
import { DEVIS_1KWH_SUCCESS_ACTION } from './constants';

export const initialState: WithDevis1kWhReducerType = {
  loading: {
    step: 0,
  },
  devis1kWh: {
    gas: null,
    electricity: null,
  },
};

function WithDevis1kWhReducer(state = initialState, action): WithDevis1kWhReducerType {
  switch (action.type) {
    case DEVIS_1KWH_SUCCESS_ACTION:
      return {
        ...state,
        devis1kWh: action.payload,
      };
    default:
      return state;
  }
}

export default WithDevis1kWhReducer;
