import { theme } from 'src/theme';

export const BreakpointEnum = {
  SM: 414,
  MD: 768,
  LG: 960,
  XL: 1024,
  XXL: 1440,
} as const;

export type FlexCoreAlignmentType = 'flex-start' | 'flex-end' | 'center';

export type SizeType = 's' | 'm' | 'l';

export type ThemeColorVariants = 'primary' | 'secondary' | 'tertiary';

export type ThemeSpacingSizeType = keyof typeof theme.spacing;

export type ImageSizeType = keyof typeof theme.sizes.images;

export type IconSizeType = keyof typeof theme.sizes.icon;

export type StatusColorType = 'info' | 'success' | 'warning' | 'error';

export type GenericColorType = 'disabled' | 'default';

export type VariantType = StatusColorType | GenericColorType | 'primary' | 'secondary';
