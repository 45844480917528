import axios from 'axios';
import AppConfig from 'src/config/config';
import { ContentTypeName, HeadersName } from 'src/utils/api/api.constants';

const { REACT_APP_API_BASE_URL, REACT_APP_TENANT } = AppConfig;

const http = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    [HeadersName.CONTENT_TYPE]: ContentTypeName.APPLICATION_JSON,
    [HeadersName.X_TENANT_ID]: REACT_APP_TENANT,
  },
});

export default http;
