import { object, string } from 'yup';
import { emailRegex } from 'src/utils/validator';
import { SUBSCRIPTION_FIELDS } from 'src/hocs/WithSubscriptionNew/constants';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

export const PauseSubscriptionFormSchema = () => {
  const { intlMessage } = useIntlMessage();

  return object({
    [SUBSCRIPTION_FIELDS.EMAIL]: string()
      .required(intlMessage('shared.i18n.requiredField'))
      .matches(emailRegex, {
        message: intlMessage('shared.i18n.badFormat'),
      }),
  });
};
