import { AgeLimitExceeded } from './ErrorModalContent/AgeLimitExceeded/AgeLimitExcedeed';
import { PauseSubscriptionForm } from './ErrorModalContent/PauseSubscriptionForm/PauseSubscriptionForm';
import { ApiPayTimeoutForm } from './ErrorModalContent/ApiPayTimeoutForm/ApiPayTimeoutForm';
import { NoCompatibleOffer } from './ErrorModalContent/NoCompatibleOffer/NoCompatibleOffer';
import { GenericError } from './ErrorModalContent/GenericError/GenericError';
import { CongratulationError } from './ErrorModalContent/CongratulationError/CongratulationError';
import { DisallowELDDuringWinterBreak } from './ErrorModalContent/DisallowELDInWinterBreak/DisallowELDInWinterBreak';
import { ErrorHtmlKeys } from '../../../utils/errorManager/errors';

export const ErrorComponents = {
  [ErrorHtmlKeys.PAUSE_SUBSCRIPTION_FORM]: PauseSubscriptionForm,
  [ErrorHtmlKeys.API_PAY_TIMEOUT_FORM]: ApiPayTimeoutForm,
  [ErrorHtmlKeys.CONGRATULATION_ERROR]: CongratulationError,
  [ErrorHtmlKeys.GENERIC_ERROR]: GenericError,
  [ErrorHtmlKeys.NO_COMPATIBLE_OFFER]: NoCompatibleOffer,
  [ErrorHtmlKeys.DISALLOW_ELD_DURING_WINTER_BREAK]: DisallowELDDuringWinterBreak,
  [ErrorHtmlKeys.AGE_LIMIT_EXCEEDED]: AgeLimitExceeded,
} as const;
