import type { AppConfig } from './config.types';

const {
  TSC_COMPILE_ON_ERROR,
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL,
  REACT_APP_HOME_URL,
  REACT_APP_TENANT,
  REACT_APP_STATIC_URL,
  REACT_APP_COOKIES_API_KEY,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_GOOGLE_TAG_MANAGER_KEY,
  REACT_APP_GOOGLE_ANALYTICS_ID_KEY,
  REACT_APP_ABTEST_HOMEPAGE_FIRSTNAME_LASTNAME_DISCLAIMER,
  REACT_APP_PAYZEN_KR_CLIENT_DOMAIN,
  REACT_APP_PAYZEN_KR_PUBLIC_KEY,
  REACT_APP_PAYZEN_MERCHANT_KEY,
  REACT_APP_PAYZEN_KR_HIDE_DEBUG_TOOLBAR,
  REACT_APP_CGV_ELEC_DISCO_URL,
  REACT_APP_CGV_ELEC_ESR_URL,
  REACT_APP_CGV_ELEC_GEG_URL,
  REACT_APP_CGV_ELEC_URMMETZ_URL,
  REACT_APP_CGV_GAS_GEG_URL,
  REACT_APP_CGV_GAS_GRDF_URL,
  REACT_APP_CGV_GAS_RGDS_URL,
  REACT_APP_CGV_GAS_RGDS_PRICING_SHIELD_URL,
  REACT_APP_PARTNER_LOGO_URL,
  REACT_APP_CGV_ELEC_VEL_URL,
  REACT_APP_STATIC_BUSINESS_DATA_URL,
  REACT_APP_FAQ_URL,
  REACT_APP_CONTACT_URL,
  REACT_APP_RECRUITMENT_URL,
  REACT_APP_GENERAL_SELLING_CONDITIONS_URL,
  REACT_APP_COOKIES_URL,
  REACT_APP_POLICY_URL,
  REACT_APP_MENTIONS_URL,
  REACT_APP_CREDITS_URL,
  REACT_APP_HIGH_TRAFFIC_URL,
  REACT_APP_ORIGIN_GUARANTEES_URL,
  REACT_APP_BILLS_EXPLANATION_URL,
  REACT_APP_ENEDIS_URL,
} = process.env;

export default {
  TSC_COMPILE_ON_ERROR,
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL,
  REACT_APP_HOME_URL,
  REACT_APP_TENANT,
  REACT_APP_STATIC_URL,
  REACT_APP_COOKIES_API_KEY,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_GOOGLE_TAG_MANAGER_KEY,
  REACT_APP_GOOGLE_ANALYTICS_ID_KEY,
  REACT_APP_ABTEST_HOMEPAGE_FIRSTNAME_LASTNAME_DISCLAIMER,
  REACT_APP_PAYZEN_KR_CLIENT_DOMAIN,
  REACT_APP_PAYZEN_KR_PUBLIC_KEY,
  REACT_APP_PAYZEN_MERCHANT_KEY,
  REACT_APP_PAYZEN_KR_HIDE_DEBUG_TOOLBAR,
  REACT_APP_CGV_ELEC_DISCO_URL,
  REACT_APP_CGV_ELEC_ESR_URL,
  REACT_APP_CGV_ELEC_GEG_URL,
  REACT_APP_CGV_ELEC_URMMETZ_URL,
  REACT_APP_CGV_GAS_GEG_URL,
  REACT_APP_CGV_GAS_GRDF_URL,
  REACT_APP_CGV_GAS_RGDS_URL,
  REACT_APP_CGV_GAS_RGDS_PRICING_SHIELD_URL,
  REACT_APP_PARTNER_LOGO_URL,
  REACT_APP_CGV_ELEC_VEL_URL,
  REACT_APP_STATIC_BUSINESS_DATA_URL,
  REACT_APP_FAQ_URL,
  REACT_APP_CONTACT_URL,
  REACT_APP_RECRUITMENT_URL,
  REACT_APP_GENERAL_SELLING_CONDITIONS_URL,
  REACT_APP_COOKIES_URL,
  REACT_APP_POLICY_URL,
  REACT_APP_MENTIONS_URL,
  REACT_APP_CREDITS_URL,
  REACT_APP_HIGH_TRAFFIC_URL,
  REACT_APP_ORIGIN_GUARANTEES_URL,
  REACT_APP_BILLS_EXPLANATION_URL,
  REACT_APP_ENEDIS_URL,
} as unknown as AppConfig;
