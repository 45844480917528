/* eslint-disable no-cond-assign */
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';

import { Url } from 'src/utils/routes/routes';
import { requestJSON } from 'src/utils/api/request';
import { requestSOWithHeaders } from 'src/utils/api/api.helper';
import { omitNilKeys } from 'src/utils/tools';
import { makeSelectDevisNew } from 'src/hocs/WithDevis/selectors';

import { errorAction } from 'src/components/organisms/Error/actions';
import { ERROR_CODE, FLUX_SERVICE_ERROR } from 'src/components/organisms/Error/constants';
import { setLoading } from 'src/components/organisms/LoadingModal/LoadingModal.slice';
import { selectUuid, selectOffre, makeSelectOldReferences } from 'src/hocs/App/selectors';
import { makeSelectElecInfo, makeSelectGasInfo } from 'src/hocs/WithPODInfo/selectors';
import { ElectricityType, GasType } from 'src/hocs/WithPODInfo/types';
import { PromoCodeEnhancedType } from 'src/hocs/WithPromoCode/types';
import { CurrentAddressType } from 'src/hocs/WithAddress/types';
import { makeSelectWithAddress } from 'src/hocs/WithAddress/selectors';
import { selectCurrentEligibility } from 'src/hocs/WithEligibilityNew/selectors';
import { getCAROrigin, getQuotationEnergyFromPodInfo } from 'src/hocs/WithDevis/business.helper';
import { makeSelectPromoCodeEnhanced } from 'src/hocs/WithPromoCode/selectors';
import { makeSelectEnergyCard } from 'src/components/pages/ChoixEnergieNew/selectors';
import { EnergyEnum } from 'src/hocs/App/types';
import API_ROUTES from 'src/utils/api/api-routes';
import {
  makeSelectCustomerId,
  makeSelectCustomerType,
} from 'src/hocs/WithUserInformations/selectors';
import { getIdsArray, constraintQuotationPayload } from './logic.helper';
import {
  loadingStepAction,
  devisSuccessAction,
  updateQuotationRequestAction,
  updateQuotationSuccessAction,
  updateQuotationFailureAction,
  createQuotationRequestAction,
  createQuotationFailureAction,
  createQuotationSuccessAction,
} from './actions';
import {
  ServiceOptionType,
  UpdateQuotationActionType,
  QuotationResponseType,
  QuotationRequestType,
  ElectricityRateEnum,
} from './types';
import * as C from './constants';

const { quotations = '' } = API_ROUTES;
const updateQuotationUrl = `${quotations}/:id`;

/**
 * Select the currentAddress of the user, then try to fetch the pdl/pce with
 * the current consumption and store the correct quotation based on the information.
 * Redirect on routes.souscrire if none address is selected, redirect to routes.choixenergie
 * if there is an error during any fetch.
 *
 *
 * @param object address
 *  This example query a good quotation
 /* const address = {
 *   streetNumber: '70',
 *   streetName: 'rue John Fitzgerald Kennedy',
 *   zipCode: '59290',
 *   city: 'Wasquehal',
 *   codeInsee: '59646',
 * };
 *
 * this example return multiple pdl
 /*
 * const infoPdl = {
 *   annualConsumptionReferenceBase: '1', // required
 *   annualConsumptionReferenceLow: '1', // required
 *   electricityMeterType: ''
 *   electricityPower: 10 // required
 * };
 *
 * @param object infoPce
 * const infoPce = {
 *   annualConsumptionReference: '1',
 * };
 * @deprecated in favor of requestQuotationNew
 */
export function* requestQuotation(
  currentAddress,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPdl: any = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPce: any = {},
  updateDevis = true,
  promoCode = null,
  sendOldReferences = true,
  page = null,
) {
  const {
    codeInsee,
    supplierInterventionDate = null,
    supplierInterventionDuration = null,
    supplierInterventionType = null,
    streetName,
    streetNumber,
    zipCode,
  } = currentAddress;

  const oldReferences = sendOldReferences ? yield select(makeSelectOldReferences()) : {};
  const eligibility: ReturnType<typeof selectCurrentEligibility> =
    yield select(selectCurrentEligibility);
  yield put(loadingStepAction(2));
  yield delay(300);
  const energy = getQuotationEnergyFromPodInfo(infoPdl, infoPce, eligibility);
  const { codeOffre } = yield select(selectOffre);

  const {
    annualConsumptionReferenceBase = null,
    annualConsumptionReferenceLow = null,
    meterType = null,
    electricityPower = null,
    tariffOption = 'BASE',
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any = infoPdl;

  const {
    annualConsumptionReference = null,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any = infoPce;
  yield put(loadingStepAction(3));
  yield delay(300);
  const electricityCarOrigin = [EnergyEnum.DUAL, EnergyEnum.ELECTRICITY].includes(energy)
    ? getCAROrigin({
        page,
        energyType: EnergyEnum.ELECTRICITY,
      })
    : null;
  const gasCarOrigin = [EnergyEnum.DUAL, EnergyEnum.GAS].includes(energy)
    ? getCAROrigin({
        page,
        energyType: EnergyEnum.GAS,
      })
    : null;
  const uuid = yield select(selectUuid);
  const customerType = yield select(makeSelectCustomerType());
  const customerId = yield select(makeSelectCustomerId()) ?? null;
  const { data }: { data: QuotationResponseType } = yield call(requestJSON, quotations, {
    method: 'POST',
    body: {
      offerCode: codeOffre,
      energy,
      customerType,
      pdl: infoPdl && infoPdl.pdl,
      pce: infoPce && infoPce.pce,
      electricityAnnualConsumptionReferenceBase: annualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow: annualConsumptionReferenceLow,
      electricityMeterType: meterType,
      electricityPower,
      // ensure we call quotation in HP/HC if we pass BASE as argument
      electricityTariffOption:
        parseInt(annualConsumptionReferenceLow, 10) > 0
          ? ElectricityRateEnum.HIGH_LOW
          : tariffOption,
      gasAnnualConsumptionReference: annualConsumptionReference || null,
      zipCode,
      codeInsee,
      sessionId: uuid,
      supplierInterventionType,
      supplierInterventionDuration,
      supplierInterventionDate,
      promoCode,
      streetName,
      streetNumber,
      [C.QUOTATION_FIELDS.EMAIL]: oldReferences[C.QUOTATION_FIELDS.EMAIL],
      [C.QUOTATION_FIELDS.OLD_CONTRACT_ID]: oldReferences[C.QUOTATION_FIELDS.OLD_CONTRACT_ID],
      [C.QUOTATION_FIELDS.OLD_CUSTOMER_ID]: oldReferences[C.QUOTATION_FIELDS.OLD_CUSTOMER_ID],
      originElectricityAnnualConsumptionReference: electricityCarOrigin,
      originGasAnnualConsumptionReference: gasCarOrigin,
      customer: customerId,
    },
  });
  if (updateDevis) {
    yield put(devisSuccessAction(data));
  }
  yield put(loadingStepAction(0));
  return data;
}

/**
 * Todo: this is a temporary solution which objective is to allow non-refactored pages to use the new ErrorCatcher util
 * @deprecated this function should be replaced requestQuotationNew whenever all sagas of all Pages using it are refactored
 */
export function* tempRequestQuotation(
  currentAddress,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPdl: any = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infoPce: any = {},
  updateDevis = true,
  promoCode = null,
  sendOldReferences = true,
  page = null,
) {
  const {
    codeInsee,
    supplierInterventionDate = null,
    supplierInterventionDuration = null,
    supplierInterventionType = null,
    streetName,
    streetNumber,
    zipCode,
  } = currentAddress;

  const oldReferences = sendOldReferences ? yield select(makeSelectOldReferences()) : {};
  const eligibility: ReturnType<typeof selectCurrentEligibility> =
    yield select(selectCurrentEligibility);
  yield put(loadingStepAction(2));
  yield delay(300);
  const energy = getQuotationEnergyFromPodInfo(infoPdl, infoPce, eligibility);
  const { codeOffre } = yield select(selectOffre);
  const electricityCarOrigin = [EnergyEnum.DUAL, EnergyEnum.ELECTRICITY].includes(energy)
    ? getCAROrigin({
        page,
        energyType: EnergyEnum.ELECTRICITY,
      })
    : null;
  const gasCarOrigin = [EnergyEnum.DUAL, EnergyEnum.GAS].includes(energy)
    ? getCAROrigin({
        page,
        energyType: EnergyEnum.GAS,
      })
    : null;
  const {
    annualConsumptionReferenceBase = null,
    annualConsumptionReferenceLow = null,
    meterType = null,
    electricityPower = null,
    tariffOption = 'BASE',
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any = infoPdl;

  const {
    annualConsumptionReference = null,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any = infoPce;
  yield put(loadingStepAction(3));
  yield delay(300);
  const uuid = yield select(selectUuid);
  const customerType = yield select(makeSelectCustomerType());
  try {
    yield put(createQuotationRequestAction());
    const body = {
      offerCode: codeOffre,
      energy,
      customerType,
      pdl: infoPdl && infoPdl.pdl,
      pce: infoPce && infoPce.pce,
      electricityAnnualConsumptionReferenceBase: annualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow: annualConsumptionReferenceLow,
      electricityMeterType: meterType,
      electricityPower,
      // ensure we call quotation in HP/HC if we pass BASE as argument
      electricityTariffOption:
        parseInt(annualConsumptionReferenceLow, 10) > 0 ? 'HIGH_LOW' : tariffOption,
      gasAnnualConsumptionReference: annualConsumptionReference || null,
      zipCode,
      codeInsee,
      sessionId: uuid,
      supplierInterventionType,
      supplierInterventionDuration,
      supplierInterventionDate,
      promoCode,
      streetName,
      streetNumber,
      [C.QUOTATION_FIELDS.EMAIL]: oldReferences[C.QUOTATION_FIELDS.EMAIL],
      [C.QUOTATION_FIELDS.OLD_CONTRACT_ID]: oldReferences[C.QUOTATION_FIELDS.OLD_CONTRACT_ID],
      [C.QUOTATION_FIELDS.OLD_CUSTOMER_ID]: oldReferences[C.QUOTATION_FIELDS.OLD_CUSTOMER_ID],
      originElectricityAnnualConsumptionReference: electricityCarOrigin,
      originGasAnnualConsumptionReference: gasCarOrigin,
    };
    const { data } = yield call(requestSOWithHeaders, 'post', quotations, body);
    if (updateDevis) {
      yield put(createQuotationSuccessAction(data));
    }
    yield put(loadingStepAction(0));
    return data;
  } catch (error) {
    console.error('Something went wrong in tempRequestQuotation() - WithDevis', error);
    const { response }: AxiosError = error;
    yield put(createQuotationFailureAction(response));
    throw response;
  }
}

/**
 * Update a quotation
 * @deprecated in favor of updateQuotationNew
 * @param {object} a full quotation object
 */
export function* updateQuotation(quotation) {
  const PRODUCT_TYPE = {
    false: C.VARIABLE,
    true: C.FIXED,
  };
  try {
    const {
      codeInsee,
      customerType,
      energy,
      electricityAnnualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow,
      electricityMeterType,
      electricityMensuality,
      electricityPower,
      electricityTariffOption,
      electricityProductItemType,
      electricityNextReadingDate,
      gasNextReadingDate,
      gasAnnualConsumptionReference,
      gasMensuality,
      gasProductItemType,
      sessionId,
      options,
      offerCode,
      pdl,
      pce,
      promoCode,
      services,
      supplierInterventionType = C.CHF,
      supplierInterventionDuration,
      supplierInterventionDate,
      originElectricityAnnualConsumptionReference,
      originGasAnnualConsumptionReference,
      hasAcceptedElectricityAnnualReferenceConsumption,
      hasAcceptedGasAnnualReferenceConsumption,
    } = quotation;
    const electricityCarOrigin = getCAROrigin({
      page: null,
      energyType: EnergyEnum.ELECTRICITY,
      originFromQuotation: originElectricityAnnualConsumptionReference,
    });
    const gasCarOrigin = getCAROrigin({
      page: null,
      energyType: EnergyEnum.GAS,
      originFromQuotation: originGasAnnualConsumptionReference,
    });
    const { data } = yield call(requestJSON, Url(updateQuotationUrl, { id: quotation.id }), {
      method: 'PUT',
      body: {
        offerCode,
        energy,
        customerType,
        electricityAnnualConsumptionReferenceBase,
        electricityAnnualConsumptionReferenceLow,
        electricityMeterType,
        electricityPower,
        electricityTariffOption,
        electricityProductItemType: isBoolean(electricityProductItemType)
          ? get(PRODUCT_TYPE, `${electricityProductItemType}`)
          : electricityProductItemType,
        electricityNextReadingDate,
        gasNextReadingDate: gasNextReadingDate || electricityNextReadingDate,
        gasProductItemType: isBoolean(gasProductItemType)
          ? get(PRODUCT_TYPE, `${gasProductItemType}`)
          : gasProductItemType,
        gasAnnualConsumptionReference,
        codeInsee,
        sessionId,
        options:
          options && options.length > 0
            ? options.reduce((acc, c) => acc.concat({ id: c.id }), [])
            : [],
        services:
          services && services.length > 0
            ? services.reduce((acc, a) => acc.concat({ id: a.id }), [])
            : [],
        supplierInterventionType,
        supplierInterventionDuration,
        supplierInterventionDate,
        promoCode,
        pdl,
        pce,
        electricityMensuality,
        gasMensuality,
        originElectricityAnnualConsumptionReference: electricityCarOrigin,
        originGasAnnualConsumptionReference: gasCarOrigin,
        hasAcceptedElectricityAnnualReferenceConsumption,
        hasAcceptedGasAnnualReferenceConsumption,
      },
    });
    yield put(devisSuccessAction(data));
  } catch (error) {
    console.error('Something went wrong in updateQuotation() - WithDevis', error);
    if (error && error.code === 400 && error.message) {
      yield put(errorAction(ERROR_CODE[error.message]));
    } else if (error && error.code === 503 && error.message === FLUX_SERVICE_ERROR) {
      throw Error(FLUX_SERVICE_ERROR);
    } else {
      yield put(errorAction(ERROR_CODE.API_ERROR));
    }
  }
  yield put(loadingStepAction(0));
  yield put(setLoading(false));
}

/**
 * Todo: this is a temporary solution which objective is to allow non-refactored pages to use the new ErrorCatcher util
 * @deprecated this function should be replaced updateQuotationNew whenever all sagas of all Pages using it are refactored
 * @param {object} a full quotation object
 */
export function* tempUpdateQuotation(quotation, page = null) {
  const PRODUCT_TYPE = {
    false: C.VARIABLE,
    true: C.FIXED,
  };
  try {
    const {
      codeInsee,
      customerType,
      energy,
      electricityAnnualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow,
      electricityMeterType,
      electricityMensuality,
      electricityPower,
      electricityTariffOption,
      electricityProductItemType,
      electricityNextReadingDate,
      gasNextReadingDate,
      gasAnnualConsumptionReference,
      gasMensuality,
      gasProductItemType,
      sessionId,
      options,
      offerCode,
      pdl,
      pce,
      promoCode,
      services,
      supplierInterventionType = C.CHF,
      supplierInterventionDuration,
      supplierInterventionDate,
      originElectricityAnnualConsumptionReference,
      originGasAnnualConsumptionReference,
    } = quotation;
    const electricityCarOrigin = getCAROrigin({
      page,
      energyType: EnergyEnum.ELECTRICITY,
      originFromQuotation: originElectricityAnnualConsumptionReference,
    });
    const gasCarOrigin = getCAROrigin({
      page,
      energyType: EnergyEnum.GAS,
      originFromQuotation: originGasAnnualConsumptionReference,
    });
    yield put(updateQuotationRequestAction());

    const body = {
      offerCode,
      energy,
      customerType,
      electricityAnnualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow,
      electricityMeterType,
      electricityPower,
      electricityTariffOption,
      electricityProductItemType: isBoolean(electricityProductItemType)
        ? get(PRODUCT_TYPE, `${electricityProductItemType}`)
        : electricityProductItemType,
      electricityNextReadingDate,
      gasNextReadingDate: gasNextReadingDate || electricityNextReadingDate,
      gasProductItemType: isBoolean(gasProductItemType)
        ? get(PRODUCT_TYPE, `${gasProductItemType}`)
        : gasProductItemType,
      gasAnnualConsumptionReference,
      codeInsee,
      sessionId,
      options:
        options && options.length > 0
          ? options.reduce((acc, c) => acc.concat({ id: c.id }), [])
          : [],
      services:
        services && services.length > 0
          ? services.reduce((acc, a) => acc.concat({ id: a.id }), [])
          : [],
      supplierInterventionType,
      supplierInterventionDuration,
      supplierInterventionDate,
      promoCode,
      pdl,
      pce,
      electricityMensuality,
      gasMensuality,
      originElectricityAnnualConsumptionReference: electricityCarOrigin,
      originGasAnnualConsumptionReference: gasCarOrigin,
    };
    const { data } = yield call(
      requestSOWithHeaders,
      'put',
      Url(updateQuotationUrl, { id: quotation?.id }),
      body,
    );
    yield put(updateQuotationSuccessAction(data));
  } catch (error) {
    console.error('Something went wrong in updateQuotation() - WithDevis', error);
    const { response }: AxiosError = error;
    yield put(createQuotationFailureAction(response));
    throw response;
  } finally {
    yield put(loadingStepAction(0));
    yield put(setLoading(false));
  }
}

export function* updateQuotationNew(newQuotation: QuotationRequestType): SagaIterator {
  try {
    const oldQuotation: QuotationResponseType = yield select(makeSelectDevisNew());
    // options and services arrays should have `id` field only when sent to API
    const {
      services = [],
      options = [],
    }: { services: Array<ServiceOptionType>; options: Array<ServiceOptionType> } = oldQuotation;
    const optionsIds = getIdsArray(options);
    const servicesIds = getIdsArray(services);
    const electricityCardType = yield select(makeSelectEnergyCard(EnergyEnum.ELECTRICITY));
    const gasCardType = yield select(makeSelectEnergyCard(EnergyEnum.GAS));
    const electricityCarOrigin = getCAROrigin({
      page: newQuotation.page,
      energyType: EnergyEnum.ELECTRICITY,
      energyCardValue: electricityCardType,
      originFromQuotation: oldQuotation.originElectricityAnnualConsumptionReference,
    });
    const gasCarOrigin = getCAROrigin({
      page: newQuotation.page,
      energyType: EnergyEnum.GAS,
      energyCardValue: gasCardType,
      originFromQuotation: oldQuotation.originGasAnnualConsumptionReference,
    });
    // create new payload and filter any undesirable fields
    const updatedQuotation = constraintQuotationPayload({
      ...oldQuotation,
      ...newQuotation,
      originElectricityAnnualConsumptionReference: electricityCarOrigin,
      originGasAnnualConsumptionReference: gasCarOrigin,
      services: newQuotation.services ? newQuotation.services : servicesIds,
      options: newQuotation.options ? newQuotation.options : optionsIds,
    });

    // send data to API
    yield put(updateQuotationRequestAction());
    const { data }: { data: QuotationResponseType } = yield call(
      requestSOWithHeaders, // Todo: make Typescript version of requestJSON
      'put',
      Url(updateQuotationUrl, { id: get(oldQuotation, 'id') }), // Todo: make Typescript version
      updatedQuotation,
    );
    yield put(updateQuotationSuccessAction(data));
    return data;
  } catch (error) {
    console.error('Something went wrong in updateQuotationNew() - WithDevis', error);
    const { response }: AxiosError = error;
    yield put(updateQuotationFailureAction(response));
    // Error Redux Actions should be dispatched from Page, for flexibility purposes. So, we will only throw the error.
    // Todo: include this error in the errorManager dictionary
    if (error && error.code === 503 && error.message === FLUX_SERVICE_ERROR) {
      throw Error(FLUX_SERVICE_ERROR);
    } else {
      throw response;
    }
  }
}

export function* requestQuotationNew(
  quotationRequestComplement?: QuotationRequestType,
  sendOldReferences = true,
  page = null,
) {
  try {
    // Do the request
    // Retrieve what cards are used
    const { infoPdl }: ElectricityType = yield select(makeSelectElecInfo());
    const { infoPce }: GasType = yield select(makeSelectGasInfo());
    const eligibility: ReturnType<typeof selectCurrentEligibility> =
      yield select(selectCurrentEligibility);

    // Now retrieve the user data in the store
    // Get the address
    const address: CurrentAddressType = yield select(makeSelectWithAddress());

    // Get things
    const { offerCode } = yield select(selectOffre);
    const { code: promoCode }: PromoCodeEnhancedType = yield select(makeSelectPromoCodeEnhanced());
    const customerType = yield select(makeSelectCustomerType());
    const sessionId = yield select(selectUuid);
    const electricityCardType = yield select(makeSelectEnergyCard(EnergyEnum.ELECTRICITY));
    const gasCardType = yield select(makeSelectEnergyCard(EnergyEnum.GAS));

    // Get energies info
    const energy = getQuotationEnergyFromPodInfo(infoPdl, infoPce, eligibility);
    const elecInfo = {
      pdl: infoPdl?.pdl,
      electricityAnnualConsumptionReferenceBase: infoPdl?.annualConsumptionReferenceBase,
      electricityAnnualConsumptionReferenceLow: infoPdl?.annualConsumptionReferenceLow,
      electricityPower: infoPdl?.electricityPower || infoPdl?.puissanceSouscrite,
      electricityMeterType: infoPdl?.meterType,
      electricityTariffOption: infoPdl?.tariffOption,
    };
    const gasInfo = {
      pce: infoPce?.pce,
      gasAnnualConsumptionReference: infoPce?.annualConsumptionReference || null,
    };

    // get old references info (when user comes from Prefill)
    const references = sendOldReferences ? yield select(makeSelectOldReferences()) : {};
    const oldReferences: Partial<QuotationRequestType> = {
      [C.QUOTATION_FIELDS.EMAIL]: references?.[C.QUOTATION_FIELDS.EMAIL],
      [C.QUOTATION_FIELDS.OLD_CONTRACT_ID]: references?.[C.QUOTATION_FIELDS.OLD_CONTRACT_ID],
      [C.QUOTATION_FIELDS.OLD_CUSTOMER_ID]: references?.[C.QUOTATION_FIELDS.OLD_CUSTOMER_ID],
    };
    const electricityCarOrigin = [EnergyEnum.DUAL, EnergyEnum.ELECTRICITY].includes(energy)
      ? getCAROrigin({
          page: quotationRequestComplement?.page || page,
          energyType: EnergyEnum.ELECTRICITY,
          energyCardValue: electricityCardType,
        })
      : null;
    const gasCarOrigin = [EnergyEnum.DUAL, EnergyEnum.GAS].includes(energy)
      ? getCAROrigin({
          page: quotationRequestComplement?.page || page,
          energyType: EnergyEnum.GAS,
          energyCardValue: gasCardType,
        })
      : null;
    const carOrigins = {
      originElectricityAnnualConsumptionReference: electricityCarOrigin,
      originGasAnnualConsumptionReference: gasCarOrigin,
    };

    // get customerId
    const customerId = yield select(makeSelectCustomerId()) ?? null;

    // Prepare data for requestQuotation
    const body: QuotationRequestType = constraintQuotationPayload({
      customer: customerId,
      offerCode,
      customerType,
      sessionId,
      promoCode,
      energy,
      ...carOrigins,
      ...oldReferences,
      ...address,
      ...elecInfo,
      ...gasInfo,
      ...quotationRequestComplement,
    });

    // do the request
    yield put(createQuotationRequestAction());
    const { data } = yield call(requestSOWithHeaders, 'POST', quotations, omitNilKeys(body));
    yield put(createQuotationSuccessAction(data));
    return data;
  } catch (error) {
    console.error('Something went wrong in requestQuotationNew() - WithDevis', error);
    const { response }: AxiosError = error;
    yield put(createQuotationFailureAction(response));
    throw response;
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  // eslint-disable-next-line no-useless-catch
  try {
    yield takeLatest(C.REQUEST_QUOTATION, requestQuotation);
    yield takeLatest(C.UPDATE_QUOTATION, ({ payload }: UpdateQuotationActionType) =>
      updateQuotation(payload),
    );
  } catch (error) {
    console.error('Something went wrong in defaultSaga() - WithDevis', error);
    throw error;
  }
}
