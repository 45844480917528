import { BUSINESS_PARTS } from 'src/hocs/App/constants';
import { SetCustomerServicePhoneNumberParams } from 'src/hocs/WithCustomerService/types';
import { isSelectraGroupPurchaseBusinessPart } from 'src/hocs/App/business.helper';
import { isGroupPurchase } from 'src/components/pages/GroupPurchase/GroupPurchase.logic.helper';

export const setCustomerServicePhoneNumber = ({
  isProfessional,
  businessPart,
  intlMessage,
}: SetCustomerServicePhoneNumberParams) => {
  const isSelectraGroupPurchase = isSelectraGroupPurchaseBusinessPart(businessPart);

  if (businessPart === BUSINESS_PARTS.PAPERNEST) {
    return intlMessage('app.header.papernestPhoneNumber');
  }
  if (businessPart === BUSINESS_PARTS.BUTAGAZ) {
    return intlMessage('app.header.butagazPhoneNumber');
  }
  if (businessPart === BUSINESS_PARTS.MEILLEUR_TAUX) {
    return intlMessage('app.header.meilleurTauxPhoneNumber');
  }
  if (businessPart === BUSINESS_PARTS.AGACHTGRP24) {
    return intlMessage('app.header.agachtgrp24PhoneNumber');
  }
  if (isGroupPurchase()) {
    return intlMessage('app.groupPurchase.customerServicePhoneNumber');
  }
  if (isSelectraGroupPurchase) {
    return intlMessage('app.header.selectraGroupPurchasePhoneNumber');
  }
  if (isProfessional) {
    intlMessage('shared.i18n.customerServiceProPhoneNumber');
  }
  return intlMessage('shared.i18n.customerServicePhoneNumber');
};
