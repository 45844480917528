import { ConsentReducerType } from './types';
import { GET_CONSENTS_REQUEST, GET_CONSENTS_SUCCESS, GET_CONSENTS_FAILURE } from './constants';

export const initialState: ConsentReducerType = {
  consents: null,
  consentsLoading: false,
  consentsError: null,
};

function WithConsentReducer(state = initialState, action): ConsentReducerType {
  switch (action.type) {
    case GET_CONSENTS_REQUEST:
      return {
        ...state,
        consentsLoading: true,
      };
    case GET_CONSENTS_SUCCESS:
      return {
        ...state,
        consentsLoading: false,
        consents: action?.payload,
      };
    case GET_CONSENTS_FAILURE:
      return {
        ...state,
        consentsLoading: false,
        consentsError: action?.payload,
      };
    default:
      return state;
  }
}

export default WithConsentReducer;
