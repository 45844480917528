export const WITHCARBONIMPACT = 'WithCarbonImpact';

export const GET_CARBON_IMPACT_EMISSIONS_SAVINGS_REQUEST = 'app/CARBON_IMPACT/GET_CO2_EMISSIONS_SAVINGS_REQUEST';
export const GET_CARBON_IMPACT_EMISSIONS_SAVINGS_SUCCESS = 'app/CARBON_IMPACT/GET_CO2_EMISSIONS_SAVINGS_SUCCESS';
export const GET_CARBON_IMPACT_EMISSIONS_SAVINGS_FAILURE = 'app/CARBON_IMPACT/GET_CO2_EMISSIONS_SAVINGS_FAILURE';

// Carbon impact number format options config
export const CARBON_IMPACT_NUMBER_STYLE = 'unit';
export const CARBON_IMPACT_NUMBER_UNIT = 'kilogram';
export const CARBON_IMPACT_NUMBER_UNIT_DISPLAY = 'short';
export const CARBON_IMPACT_NUMBER_MAXIMUM_FRACTION_DIGITS = 0;
