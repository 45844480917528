import { createAsyncThunk } from '@reduxjs/toolkit';
import http from 'src/utils/api/http';
import API_ROUTES from 'src/utils/api/api-routes';
import { addQueryParams } from 'src/utils/api/api.helper';
import { HeadersName } from 'src/utils/api/api.constants';
import type { AppStateType } from 'src/store/appstate.type';
import { selectOffre } from 'src/hocs/App/selectors';
import type { ProductsInformationRequestParams } from './products-information.types';

const { productsInformation } = API_ROUTES;
const { REACT_APP_API_BASE_URL = '' } = process.env;

export const getProductsInformation = createAsyncThunk(
  'products/getInformation',
  async (
    {
      'zip-code': zipCode,
      'geographic-code': geographicCode,
      'customer-type': customerType,
      energy,
    }: ProductsInformationRequestParams,
    { rejectWithValue, getState },
  ) => {
    const params = {
      zipCode,
      geographicCode,
      customerType,
      energy,
    };

    const state = getState() as AppStateType;
    const { codeApporteur: sellerId, codeCanal: sellerChannel } = selectOffre(state);

    const url = addQueryParams(`${REACT_APP_API_BASE_URL}/${productsInformation}`, params);
    try {
      const response = await http.get(url, {
        headers: {
          [HeadersName.X_SELLER_ID]: sellerId,
          [HeadersName.X_SELLER_CHANNEL_ID]: sellerChannel,
        },
      });
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    }
  },
);
