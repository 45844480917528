import * as C from './constants';
import * as T from './types';

export const initialState: T.QuotationReducerType = {
  loading: {
    step: 0,
  },
  // TODO: rename to quotation
  devis: null,
  loadingQuotation: false,
  errorQuotation: null,
  mensualityIndex: 1,
};

function WithDevisReducer(state = initialState, action): T.QuotationReducerType {
  // TODO define all action's types
  switch (action.type) {
    case C.DEVIS_SUCCESS_ACTION: {
      return {
        ...state,
        devis: action.payload,
      };
    }
    case C.LOADING_STEP:
      return {
        ...state,
        loading: {
          step: action.payload,
        },
      };
    case C.CREATE_QUOTATION_REQUEST:
      return {
        ...state,
        loadingQuotation: true,
        errorQuotation: null,
      };
    case C.CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        loadingQuotation: false,
        devis: action.payload,
      };
    case C.CREATE_QUOTATION_FAILURE:
      return {
        ...state,
        loadingQuotation: false,
        errorQuotation: action?.payload,
      };
    case C.UPDATE_QUOTATION_REQUEST:
      return {
        ...state,
        loadingQuotation: true,
        errorQuotation: null,
      };
    case C.UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        loadingQuotation: false,
        devis: action.payload,
      };
    case C.UPDATE_QUOTATION_FAILURE:
      return {
        ...state,
        loadingQuotation: false,
        errorQuotation: action?.payload?.response,
      };
    default:
      return state;
  }
}

export default WithDevisReducer;
