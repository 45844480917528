import * as t from 'src/hocs/App/types';
import {
  SupplierInterventionTypeEnum,
  AnnualConsumptionOriginEnum,
  AnnualConsumptionOriginGasEnum,
  AnnualConsumptionOriginElectricityEnum,
  GetCAROriginTypes,
} from 'src/hocs/WithDevis/types';
import { InfoPceType, InfoPdlType } from 'src/hocs/WithPODInfo/types';
import { EligibilityResponseType } from 'src/hocs/WithEligibilityNew/types';
import { EnergyCardsType } from 'src/components/organisms/EnergyCard/types';
import { OriginsEnum } from 'src/utils/errorManager/types';

export const supplierInterventionIsCHF = (
  supplierIntervention: SupplierInterventionTypeEnum,
): boolean => supplierIntervention === SupplierInterventionTypeEnum.CHF;

export const supplierInterventionIsMES = (
  supplierIntervention: SupplierInterventionTypeEnum,
): boolean => supplierIntervention === SupplierInterventionTypeEnum.MES;

export const getQuotationEnergyFromPodInfo = (
  infoPDL: InfoPdlType,
  infoPCE: InfoPceType,
  { eligibilityElec, eligibilityGaz }: EligibilityResponseType,
): t.EnergyType => {
  // if the address isn't eligible in electricity, we set the CAR to 0
  const electricityCAR = eligibilityElec ? infoPDL?.annualConsumptionReferenceBase : 0;
  // if the address isn't eligible in gas, we set the CAR to 0
  const gasCAR = eligibilityGaz ? infoPCE?.annualConsumptionReference : 0;

  if (eligibilityElec && eligibilityGaz) {
    if (electricityCAR && !gasCAR) return t.EnergyEnum.ELECTRICITY;
    if (gasCAR && !electricityCAR) return t.EnergyEnum.GAS;
    return t.EnergyEnum.DUAL;
  }
  if (eligibilityElec) {
    return t.EnergyEnum.ELECTRICITY;
  }
  if (eligibilityGaz) {
    return t.EnergyEnum.GAS;
  }
  return t.EnergyEnum.GAS;
};

export const getCAROrigin = ({
  page,
  energyType,
  energyCardValue = null,
  originFromQuotation = null,
}: GetCAROriginTypes): string | null => {
  switch (page) {
    case OriginsEnum.HOME_PAGE:
      if (energyType === t.EnergyEnum.ELECTRICITY) {
        return AnnualConsumptionOriginElectricityEnum.SUPPLIER_DEFAULT;
      }
      if (energyType === t.EnergyEnum.GAS) {
        return AnnualConsumptionOriginGasEnum.DSO;
      }
      return null;
    case OriginsEnum.CHOIX_ENERGIE:
      if (energyCardValue === EnergyCardsType.CONSUMPTION_CARD) {
        return AnnualConsumptionOriginEnum.PROSPECT;
      }
      if (energyType === t.EnergyEnum.ELECTRICITY) {
        return AnnualConsumptionOriginElectricityEnum.SUPPLIER_DEFAULT;
      }
      if (energyType === t.EnergyEnum.GAS) {
        return AnnualConsumptionOriginGasEnum.DSO;
      }
      return null;
    case OriginsEnum.ESTIMATION:
      return AnnualConsumptionOriginEnum.SUPPLIER_ESTIMATOR;
    default:
      return originFromQuotation;
  }
};
