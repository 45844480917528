/* eslint-disable @typescript-eslint/ban-types */
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { EnergyEnum, EnergyType } from 'src/hocs/App/types';

const { ELECTRICITY, GAS, DUAL } = EnergyEnum;

// omit all key which values are null or undefined
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omitNilKeys: any = (obj: object) => omitBy(obj, isNil);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasSome = (obj: Record<string, any> | undefined, propsArray: string[]) =>
  propsArray.some((key) => !isNil(obj?.[key]));

// detects if an object has properties with Nil (null or undefined) values
export const hasNilValues = (obj: object): boolean => {
  const hasNull = Object.values(obj).includes(null);
  const hasUndefined = Object.values(obj).includes(undefined);
  return hasNull || hasUndefined;
};

export const getMonthlyPriceRounded = ({ value }: { value: number }) => Math.round(value / 12);

export const setHasElectricity = (energyType: EnergyType) =>
  [ELECTRICITY, DUAL].includes(energyType);
export const setHasGas = (energyType: EnergyType) => [GAS, DUAL].includes(energyType);
