/**
 *
 * Token.react.js
 *
 * This compoment simply manage token stored in localstorage.
 */

const TOKEN_KEY = 'ekw/token';

// TODO implements CookieStorage for old browser
const CookieStorage = {};

/*
 * Abstract Storage for localStorage
 */
const Localstorage = {
  token: null,
  set: (key, token, ttl) => {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        token,
        ttl,
      }),
    );
  },
  get: (key) => {
    const result = {
      token: null,
      user: null,
    };

    const data = JSON.parse(window.localStorage.getItem(key));

    if (!data) {
      return result;
    }
    const { token, ttl } = data;

    if (token && ttl - Date.now() > 0) {
      return token;
    }
    window.localStorage.removeItem(TOKEN_KEY);
    return result;
  },
  remove: () => {
    window.localStorage.removeItem(TOKEN_KEY);
  },
};

/*
 * Abstract Storage
 */
const Store = {
  get: () => {
    // @ts-ignore TODO js migration
    if (window.localStorage !== 'undefined') {
      return Localstorage;
    }
    return CookieStorage;
  },
};

const Token = {
  store: null,
  getStore: () => {
    if (Token.store) {
      return Token.store;
    }
    Token.store = Store.get();
    return Token.store;
  },
  // default ttl is 24h
  set: (token, ttl = 1000 * 24 * 60 * 60) => {
    Token.getStore().set(TOKEN_KEY, token, (token.token.ttl || ttl) + Date.now());
  },
  get: () => Token.getStore().get(TOKEN_KEY),
  remove: () => Token.getStore().remove(TOKEN_KEY),
};

export default Token;
