import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import { EnergyEnum } from 'src/hocs/App/types';
import * as t from './types';

export const WITH_DEVIS = 'WithDevis';
export const LOADING_STEP = 'app/Devis/LOADING_STEP';
export const DEVIS_SUCCESS_ACTION = 'app/Devis/DEVIS_SUCCESS_ACTION';
export const REQUEST_QUOTATION = 'app/Devis/REQUEST_QUOTATION';
export const UPDATE_QUOTATION = 'app/Devis/UPDATE_QUOTATION';

/**
 * NEW CONSTANTS
 */
export const CREATE_QUOTATION_REQUEST = 'app/Devis/CREATE_QUOTATION_REQUEST';
export const CREATE_QUOTATION_SUCCESS = 'app/Devis/CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_FAILURE = 'app/Devis/CREATE_QUOTATION_FAILURE';
export const UPDATE_QUOTATION_REQUEST = 'app/Devis/UPDATE_QUOTATION_REQUEST';
export const UPDATE_QUOTATION_SUCCESS = 'app/Devis/UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION_FAILURE = 'app/Devis/UPDATE_QUOTATION_FAILURE';

export const { DUAL } = EnergyEnum;
export const { ELECTRICITY } = EnergyEnum;
export const GAZ = EnergyEnum.GAS; // TODO: change wording rename to GAS instead
export const { CHF } = t.SupplierInterventionTypeEnum;
export const { FIXED } = t.ProductItemTypeEnum;
export const { VARIABLE } = t.ProductItemTypeEnum;

// List all fields names expected by the API.
// API calls and Form Input Names should make use of this object

const buildQuotationFields = fieldsTypeCheckerFactory<t.QuotationRequestType>();
export const QUOTATION_FIELDS = buildQuotationFields({
  STREET_NAME: 'streetName',
  STREET_NUMBER: 'streetNumber',
  ENERGY: 'energy',
  CUSTOMER_TYPE: 'customerType',
  ZIP_CODE: 'zipCode',
  CODE_INSEE: 'codeInsee',
  SESSION_ID: 'sessionId',
  PDL: 'pdl',
  PCE: 'pce',
  OFFER_CODE: 'offerCode',
  PROMO_CODE: 'promoCode',
  ELECTRICITY_ANNUAL_CONSUMPTION_REFERENCE_BASE: 'electricityAnnualConsumptionReferenceBase',
  ELECTRICITY_ANNUAL_CONSUMPTION_REFERENCE_LOW: 'electricityAnnualConsumptionReferenceLow',
  ELEC_METER: 'electricityMeterType',
  ELECTRICITY_POWER: 'electricityPower',
  ELEC_RATE: 'electricityTariffOption',
  ELEC_PRODUCT_TYPE: 'electricityProductItemType',
  ELEC_MONTHLY_FEE: 'electricityMensuality',
  ELEC_READING_DATE: 'electricityNextReadingDate',
  // Todo: check with back team if the field is still used
  // electricityDgo,
  GAS_CONSUMPTION: 'gasAnnualConsumptionReference',
  GAZ_PRODUCT: 'gasProductItemType',
  GAZ_MONTHLY_FEE: 'gasMensuality',
  GAZ_READING_DATE: 'gasNextReadingDate',
  // Todo: check with back team if the field is still used
  // gasDgo,
  OPTIONS: 'options',
  SERVICES: 'services',
  SUPPLIER_INTERVENTION: 'supplierInterventionType',
  SUPPLIER_DURATION: 'supplierInterventionDuration',
  SUPPLIER_DATE: 'supplierInterventionDate',
  OLD_CUSTOMER_ID: 'oldCustomerId',
  OLD_CONTRACT_ID: 'oldContractId',
  EMAIL: 'email',
  ELECTRICITY_ANNUAL_CONSUMPTION_REFERENCE_ORIGIN: 'originElectricityAnnualConsumptionReference',
  GAS_ANNUAL_CONSUMPTION_REFERENCE_ORIGIN: 'originGasAnnualConsumptionReference',
  COMPANY_NAME: 'companyName',
  CUSTOMER_ID: 'customer',
});
