import { createSelector } from 'reselect';

import { EnergyEnum } from 'src/hocs/App/types';
import { AppStateType } from 'src/store/appstate.type';
import { name } from './slice';
import * as t from './types';

export const selectChoixEnergie = (state: AppStateType): t.EnergyChoiceReducerType => state[name];

type SelectorEnergyType = typeof EnergyEnum.ELECTRICITY | typeof EnergyEnum.GAS;

export const makeSelectEnergyCard = (energyType: SelectorEnergyType) =>
  createSelector(selectChoixEnergie, (state) => {
    const isElectricity = energyType === EnergyEnum.ELECTRICITY;
    return isElectricity ? state?.electricityCard : state?.gasCard;
  });
