import React from 'react';
import { IntlProvider } from 'react-intl';

import strings from 'src/translations/strings.json';
import { LanguageProviderProps, LANGUAGES } from './intl.types';

const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }: LanguageProviderProps) => (
  <IntlProvider
    locale={LANGUAGES.FR}
    key={LANGUAGES.FR}
    messages={strings}
    onError={(err) => {
      if (err.code === 'MISSING_TRANSLATION') {
        return;
      }
      console.error(err);
    }}
  >
    {children}
  </IntlProvider>
);

export default LanguageProvider;
