import { MessageFormatElement } from 'react-intl';
import {
  CustomMessageDescriptor,
  TranslationKeys,
} from 'src/components/utils/LanguageProvider/intl.types';
import { ValueOf } from 'src/utils/typescript/typescript.helper';
import { ErrorHtmlKeysType } from './errors';

export enum ApiErrorMessagesEnum {
  UNKNOWN = 'UNKNOWN',
  ZERO_RESULTS = 'ZERO_RESULTS',
  EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR',
  FLUX_SERVICE_ERROR = 'FLUX_SERVICE_ERROR',
  SERVICE_PDL_UNAVAILABLE = 'SERVICE_PDL_UNAVAILABLE',
  SERVICE_PCE_UNAVAILABLE = 'SERVICE_PCE_UNAVAILABLE',
  SERVICE_PROMOCODE_UNAVAILABLE = 'SERVICE_PROMOCODE_UNAVAILABLE',
  NO_COMPATIBLE_ACTIVE_OFFERS = 'NO_COMPATIBLE_ACTIVE_OFFERS',
  NO_OFFERS_FOR_GIVEN_ENERGY = 'NO_OFFERS_FOR_GIVEN_ENERGY',
  VALIDATION_FAILED = 'Validation Failed',
  INTERNAL_SERVICE_ERROR = 'INTERNAL_SERVICE_ERROR',
  INVALID_OPTION = 'INVALID_OPTION',
  NO_POD_INFO_AVAILABLE = 'NO_POD_INFO_AVAILABLE',
  INVALID_PDL = 'INVALID_PDL',
  INVALID_PCE = 'INVALID_PCE',
  INSEE_DO_NOT_MATCH_PDL = 'INSEE_DO_NOT_MATCH_PDL',
  INVALID_IBAN = 'INVALID_IBAN',
  CUSTOMER_SERVICE_ACTION_REQUIRED = 'CUSTOMER_SERVICE_ACTION_REQUIRED',
  CUSTOMER_ALREADY_SUBSCRIBED = 'CUSTOMER_ALREADY_SUBSCRIBED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  INSUFFICIENT_DATA_FOR_ELECTRICITY_QUOTATION = 'INSUFFICIENT_DATA_FOR_ELECTRICITY_QUOTATION',
  INSUFFICIENT_DATA_FOR_GAS_QUOTATION = 'INSUFFICIENT_DATA_FOR_GAS_QUOTATION',
  MUST_GIVE_CAR_ELECTRICITY = 'MUST_GIVE_CAR_ELECTRICITY',
  MUST_GIVE_CAR_GAS = 'MUST_GIVE_CAR_GAS',
  SUBSCRIPTION_LOCKED = 'SUBSCRIPTION_LOCKED',
  SUBSCRIPTION_CANNOT_BE_FINALIZED = 'SUBSCRIPTION_CANNOT_BE_FINALIZED',
  NO_INSEE_DATA = 'NO_INSEE_DATA',
  // this error will only occur when API-SO app/db are out of service
  NETWORK_ERROR = 'Network error communicating with endpoint',
  CITY_NOT_FOUND = 'CITY_NOT_FOUND',
  API_PAY_TIMEOUT = 'API_PAY_TIMEOUT',
  POSSIBLE_SPAM_ATTEMPT = 'POSSIBLE_SPAM_ATTEMPT',
  DISALLOW_ELD_DURING_WINTER_BREAK = 'DISALLOW_ELD_DURING_WINTER_BREAK',
  NO_COMPATIBLE_SERVICES = 'NO_COMPATIBLE_SERVICES',
  AGE_LIMIT_EXCEEDED = 'AGE_LIMIT_EXCEEDED',
}

export enum OriginsEnum {
  UNKNOWN = 'UNKNOWN',
  HOME_PAGE = 'HOME_PAGE',
  CHOIX_ENERGIE = 'CHOIX_ENERGIE',
  ESTIMATION = 'ESTIMATION',
  CONTRAT = 'CONTRAT',
  QUOTATION = 'QUOTATION',
  SERVICES_OPTIONS = 'SERVICES_OPTIONS',
  MY_DELIVERY = 'MY_DELIVERY',
  LIVRAISON_POD = 'LIVRAISON_POD',
  MODE_FACTURATION = 'MODE_FACTURATION',
  SUMMARY = 'SUMMARY',
  PAIEMENT_SUCCESS = 'PAIEMENT_SUCCESS',
  PAYMENT = 'PAYMENT',
  NON_ELIGIBLE = 'NON_ELIGIBLE',
  MANDATE_SIGNATURE = 'MANDATE_SIGNATURE',
}

export enum DestinationsEnum {
  POP_UP = 'POP_UP',
  INPUT_ERROR = 'INPUT_ERROR',
  REDIRECTION = 'REDIRECTION',
  NONE = 'NONE',
}

export interface ErrorDefinitionType {
  id?: TranslationKeys;
  defaultMessage?: string | MessageFormatElement[];
  errorMessage?: string;
  destination?: DestinationsEnum;
  redirectionPath?: string;
  isClosable?: boolean;
  headlineMessage?: CustomMessageDescriptor;
  html?: ErrorHtmlKeysType;
  originUrl?: string;
  customErrorImage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  htmlProps?: any;
  inputFields?: string | Array<string>;
}

export type ErrorDictionaryType = {
  [key in OriginsEnum]?: {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    [key in ApiErrorMessagesEnum]?: ErrorDefinitionType;
  };
};

export const HttpServerErrorCode = {
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
} as const;
export type HttpServerErrorCodeType = ValueOf<typeof HttpServerErrorCode>;
