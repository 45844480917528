import * as T from './types';
import messages from './messages';

const getErrorMessageToDisplay = ({ error }: T.ErrorMessageMethodsParam): T.ErrorMessageMethodsType['errorMessageToDisplay']=> {
  if (error.message && messages[error.message]) {
    return messages[error.message];
  }
  if (messages[error.code]) {
    return messages[error.code];
  }
  return messages.errorHappened;
};

const getHeadingTextFromErrorCode = ({ error }: T.ErrorMessageMethodsParam): T.ErrorMessageMethodsType['headingText'] => {
  if (error.code && error.headline && messages[`${error.code}_HEAD`]) {
    return messages[`${error.code}_HEAD`];
  }
  return '';
};

export const getErrorMessage = ({ error }: T.ErrorMessageMethodsParam): T.ErrorMessageMethodsType => {
  const closable = !error.message;
  const errorMessageToDisplay = getErrorMessageToDisplay({ error });
  const headingText = getHeadingTextFromErrorCode({ error });

  return {
    headingText,
    closable,
    errorMessageToDisplay,
  };
};
