import { useLocation } from 'react-router-dom';

import routes from 'src/utils/routes/routes';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

export const useLocationTitle = () => {
  const { pathname } = useLocation();
  const { intlMessage } = useIntlMessage();

  const tenantName = intlMessage('shared.i18n.tenantName');

  if (pathname === routes.choixEnergie) {
    return intlMessage('app.ChoixEnergie.title');
  }

  if (pathname === routes.estimation) {
    return intlMessage('app.Estimation.stepHeading');
  }

  if (pathname.includes(routes.mandateSignature)) {
    return intlMessage('app.mandateSignature.title');
  }

  if (pathname === routes.mandateSignatureFinalized) {
    return intlMessage('app.mandateSignatureFinalized.title', { tenantName });
  }

  if (pathname === routes.nonEligible) {
    return intlMessage('app.nonEligible.title', { tenantName });
  }

  if (pathname === routes.devis) {
    return intlMessage('app.quotation.heading');
  }

  if (pathname === routes.serviceOption) {
    return intlMessage('app.servicesOption.title', { tenantName });
  }

  if (pathname === routes.souscrire) {
    return intlMessage('app.souscrire.title');
  }

  return null
}