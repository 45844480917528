import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import * as t from './types';

export const initialState: t.EligibilityReducerType = {
  nonEligibility: {
    error: null,
    data: null,
    loading: false,
  },
  eligibility: {
    codePostal: null,
    commune: null,
    codeInsee: null,
    eligibilityDual: null,
    eligibilityElec: null,
    eligibilityGaz: null,
  },
  loading: false,
  error: null,
  PODType: null,
  loadingPod: false,
  errorPod: null,
  inseeList: null,
};

const slice = createSlice({
  name: 'WithEligibility',
  initialState,
  reducers: {
    requestEligibilityActionNew: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestEligibilitySuccessAction: (state, action: PayloadAction<t.EligibilityResponseType>) => {
      state.eligibility = action.payload;
      state.loading = false;
    },
    requestEligibilityFailureAction: (state, action: PayloadAction<AxiosResponse<unknown>>) => {
      state.error = action.payload;
      state.loading = false;
    },
    requestNonEligibilityAction: (state) => {
      state.nonEligibility.error = null;
      state.nonEligibility.loading = true;
    },
    requestNonEligibilitySuccessAction: (
      state,
      action: PayloadAction<t.NonEligibilityResponseType>,
    ) => {
      state.nonEligibility.data = action.payload;
      state.nonEligibility.loading = false;
    },
    requestNonEligibilityFailureAction: (state, action: PayloadAction<AxiosResponse<unknown>>) => {
      state.nonEligibility.error = action.payload;
      state.nonEligibility.loading = false;
    },
    requestPodRegexAction: (state) => {
      state.loadingPod = true;
      state.errorPod = null;
    },
    requestPodRegexSuccessAction: (state, action: PayloadAction<t.PodRegexResponseType>) => {
      state.PODType = action.payload;
      state.loadingPod = false;
    },
    requestPodRegexFailureAction: (state, action: PayloadAction<AxiosResponse<unknown>>) => {
      state.errorPod = action.payload;
      state.loadingPod = false;
    },
    requestInseeRequestAction: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestInseeSuccessAction: (state, action: PayloadAction<t.InseeListResponseType>) => {
      state.loading = false;
      state.inseeList = action.payload;
    },
    requestInseeErrorAction: (state, action: PayloadAction<AxiosResponse<unknown>>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  requestEligibilityActionNew,
  requestEligibilitySuccessAction,
  requestEligibilityFailureAction,
  requestNonEligibilityAction,
  requestNonEligibilitySuccessAction,
  requestNonEligibilityFailureAction,
  requestPodRegexAction,
  requestPodRegexSuccessAction,
  requestPodRegexFailureAction,
  requestInseeRequestAction,
  requestInseeSuccessAction,
  requestInseeErrorAction,
} = slice.actions;
