import { takeEvery, call, put, select } from 'redux-saga/effects';
import { setLoading } from 'src/components/organisms/LoadingModal/LoadingModal.slice';
import { errorAction } from 'src/components/organisms/Error/actions';
import { ERROR_CODE } from 'src/components/organisms/Error/constants';
import { push } from 'connected-react-router';
import routes, { Url } from 'src/utils/routes/routes';
import { requestJSON } from 'src/utils/api/request';
import { index } from 'src/utils/date';
import { selectExternalLeadId } from 'src/hocs/App/selectors';
import API_ROUTES from 'src/utils/api/api-routes';
import { UPDATE_SUBSCRIPTION, REQUEST_SUBSCRIPTION, USER_STATUS } from './constants';

import { requestSubscriptionSuccessAction, setFraudDoublonAction } from './actions';
import { makeSelectSubscriptionId } from './selectors';

const { subscriptions = '' } = API_ROUTES;
const subscriptionUrl = `${subscriptions}/:id`;

/**
 * Fetch the current subscription
 * @deprecated in favor of WithSubscriptionNew Saga Generator requestSubscription
 */
export function* requestSubscription(subscriptionId) {
  try {
    const id = yield select(makeSelectSubscriptionId());
    const { data } = yield call(requestJSON, Url(subscriptionUrl, { id: subscriptionId || id }), {
      method: 'GET',
    });
    yield put(requestSubscriptionSuccessAction(data));
    return data;
  } catch (error) {
    console.error('Something went wrong in requestSubscription() - WithSubscription', error);
    yield put(errorAction(ERROR_CODE.API_ERROR));
  }
  return null;
}

/**
 * Update a subscription
 * @param {object} subscription a full subscription object
 * @deprecated in favor of WithSubscriptionNew Saga Generator updateSubscription
 */
// eslint-disable-next-line consistent-return
export function* updateSubscription(subscription) {
  try {
    const {
      quotation,
      civility,
      firstName,
      lastName,
      mobileNumber,
      phoneNumber,
      email,
      birthdate,
      civility2,
      firstName2,
      lastName2,
      consumerCivility,
      consumerFirstName,
      consumerLastName,
      consumerPhoneNumber,
      consumerMobileNumber,
      consumerEmail,
      isConsumerDifferent,
      consumptionStreetNumber,
      consumptionStreetName,
      consumptionZipCode,
      consumptionCity,
      isInvoiceAddressDifferent,
      consumptionBuilding,
      consumptionStaircase,
      consumptionFloor,
      consumptionApartmentNumber,
      consumptionAdditionalAddress,
      invoiceStreetNumber,
      invoiceStreetName,
      invoiceZipCode,
      invoiceCity,
      invoiceFrequency,
      invoiceSendingChannel,
      invoicePaymentMethod,
      isSigned,
      electricityRelocationCommissioningType,
      gasRelocationCommissioningType,
      paymentDay,
      electricityIndexBase,
      electricityIndexLow,
      gasIndex,
      frontStatus,
      hasEnergyDataConsent,
      saveAndResume,
      invoiceBuilding,
      invoiceStaircase,
      invoiceFloor,
      invoiceApartmentNumber,
      invoiceAdditionalAddress,
      electricityDesiredActivationDate,
      hasNewsletterConsent,
      siret,
      companyName,
      hasPartnerNewsletterConsent,
      consentCollection,
      isBypassWithdrawalPeriod,
    } = subscription;
    const externalLeadId = yield select(selectExternalLeadId);
    const id = yield select(makeSelectSubscriptionId());
    const url = id ? Url(subscriptionUrl, { id }) : subscriptions;
    const method = id ? 'PUT' : 'POST';
    if (!quotation.id) throw new Error('Quotation id is needed for subscription');
    const DesiredActivationDate = index(electricityDesiredActivationDate);
    const { data } = yield requestJSON(url, {
      method,
      body: {
        ...(!externalLeadId ? {} : { externalLeadId }),
        quotation: quotation.id,
        civility,
        firstName,
        lastName,
        mobileNumber,
        phoneNumber,
        email,
        birthdate,
        civility2,
        firstName2,
        lastName2,
        consumerCivility,
        consumerFirstName,
        consumerLastName,
        consumerPhoneNumber,
        consumerMobileNumber,
        consumerEmail,
        isConsumerDifferent,
        consumptionStreetNumber,
        consumptionStreetName,
        consumptionZipCode,
        consumptionCity,
        isInvoiceAddressDifferent,
        consumptionBuilding,
        consumptionStaircase,
        consumptionFloor,
        consumptionApartmentNumber,
        consumptionAdditionalAddress,
        invoiceStreetNumber,
        invoiceStreetName,
        invoiceZipCode,
        invoiceCity,
        invoiceFrequency,
        invoiceSendingChannel,
        invoicePaymentMethod,
        isSigned,
        electricityRelocationCommissioningType,
        gasRelocationCommissioningType,
        paymentDay,
        electricityIndexBase,
        electricityIndexLow,
        gasIndex,
        frontStatus,
        hasEnergyDataConsent,
        saveAndResume,
        invoiceBuilding,
        invoiceStaircase,
        invoiceFloor,
        invoiceApartmentNumber,
        invoiceAdditionalAddress,
        electricityDesiredActivationDate: DesiredActivationDate,
        gasDesiredActivationDate: DesiredActivationDate,
        hasNewsletterConsent,
        siret,
        companyName,
        hasPartnerNewsletterConsent,
        consentCollection,
        isBypassWithdrawalPeriod,
      },
    });
    if (data) {
      yield put(requestSubscriptionSuccessAction(data));
      return data;
    }
  } catch (error) {
    console.error('Something went wrong in updateSubscription() - WithSubscription', error);
    yield put(setLoading(false));
    if (error.code === 409) {
      yield put(setFraudDoublonAction(USER_STATUS.ALREADY_CLIENT));
      return yield put(push(routes.alreadyClient));
    }
    if (error.code === 418) {
      yield put(setFraudDoublonAction(USER_STATUS.IS_493));
      return yield put(push(routes.unPaid));
    }
    yield put(errorAction(error));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  try {
    yield takeEvery(REQUEST_SUBSCRIPTION, requestSubscription);
    // @ts-ignore TODO js migration
    yield takeEvery(UPDATE_SUBSCRIPTION, ({ payload }) => updateSubscription(payload));
  } catch (error) {
    console.error('Something went wrong in defaultSaga() - WithSubscription', error);
    yield put(errorAction(ERROR_CODE.API_ERROR));
  }
}
