import { AxiosResponse } from 'axios';
import { ElectricityDistributorsType } from 'src/hocs/App/types';
import { ElectricityRateEnum, SupplierInterventionTypeEnum } from 'src/hocs/WithDevis/types';
import { ElectricityRelocationCommissioningTypeEnum } from 'src/hocs/WithSubscriptionNew/types';
import { ApiErrorMessagesEnum } from 'src/utils/errorManager/types';
import { CustomerTypeType } from 'src/hocs/WithUserInformations/types';

/*
 * Enums
 *
 */

export enum SubscriptionTypeKeysEnum {
  MES = 'activation',
  CHF = 'supplierChange',
}

/*
 * Base Types
 *
 */

export interface PodAddressType {
  codeInsee: string;
  codePostal: string;
  ville: string;
  numeroVoie: string;
  voie: string;
}

export interface InfoPdlType {
  pdl: string;
  adresse: PodAddressType;
  electricityPower?: number;
  puissanceSouscrite?: number;
  dateTheoriqueReleve: string;
  siContractuel: ElectricityDistributorsType;
  communicant: boolean;
  annualConsumptionReferenceBase?: number;
  annualConsumptionReferenceLow?: number;
  tariffOption: ElectricityRateEnum;
  isLinky: boolean;
  isHighLow: boolean;
  meterType: string;
  dialsNumber: number;
}

export interface InfoPceType {
  pce: string;
  adresse: PodAddressType;
  dateTheoriqueReleve: string;
  annualConsumptionReference: number;
}

export interface ActivationType {
  code: string;
  intervention: string;
  indexReelNecessaire: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  index: Array<any>;
  price:
    | {
        [key in ElectricityRelocationCommissioningTypeEnum]?: string;
      }
    | string;
}

export interface SupplierChangeType {
  code: string;
  intervention: string;
  indexReelNecessaire: boolean;
}

interface SubscriptionTypeType {
  [SubscriptionTypeKeysEnum.MES]: ActivationType;
  [SubscriptionTypeKeysEnum.CHF]: SupplierChangeType;
}

/*
 *    Reducer Types
 */

export type ErrorType =
  | ApiErrorMessagesEnum.NO_POD_INFO_AVAILABLE
  | ApiErrorMessagesEnum.SERVICE_PDL_UNAVAILABLE
  | ApiErrorMessagesEnum.SERVICE_PCE_UNAVAILABLE;

export interface ElectricityType {
  infoPdl?: InfoPdlType;
  subscriptionType?: SubscriptionTypeType;
  error?: ErrorType;
}

export interface GasType {
  infoPce?: InfoPceType;
  subscriptionType?: SubscriptionTypeType;
  error?: ErrorType;
}

export interface WithPodInfoReducerType {
  elecInfo: ElectricityType;
  gasInfo: GasType;
  loading: boolean;
  error?: AxiosResponse;
}

/*
 *    Redux Action Types
 */

export interface BaseActionType {
  type: string;
}

export interface CreatePodInfoSuccessActionType extends BaseActionType {
  payload?: PodInfoResponseType;
}

export interface CreatePodInfoFailureActionType extends BaseActionType {
  payload?: AxiosResponse;
}

export interface CreateSubscriptionTypePodSuccessActionType extends BaseActionType {
  payload: SubscriptionTypeResponseType;
}

export interface CreateSubscriptionTypePodFailureActionType extends BaseActionType {
  payload?: AxiosResponse;
}

export interface CreateSubscriptionTypeAddressSuccessActionType extends BaseActionType {
  payload?: SubscriptionTypeResponseType;
}

export interface CreateSubscriptionTypeAddressFailureActionType extends BaseActionType {
  payload?: AxiosResponse;
}

/*
 * API Call Types
 *
 */

export interface PodInfoByPodRequestType {
  pdl?: string;
  pce?: string;
  zipCode: string;
  codeInsee: string;
  customerId?: string;
  customerType: CustomerTypeType;
  supplierInterventionType: SupplierInterventionTypeEnum;
  firstName: string;
  lastName: string;
  companyName?: string;
}

export interface PodInfoByAddressRequestType {
  streetNumber: string;
  streetName: string;
  zipCode: string;
  codeInsee: string;
  city: string;
  additionalAddress?: string;
  electricityMeterNumber?: string;
  gasMeterNumber?: string;
  previousConsumerName: string;
  customerType: CustomerTypeType;
  firstName: string;
  lastName: string;
  companyName?: string;
  mobilePhone?: string;
}

export interface PodInfoResponseType {
  infoPdl?: InfoPdlType;
  infoPce?: InfoPceType;
}

export type SubscriptionTypeByAddressRequestType = PodInfoByAddressRequestType;

export type SubscriptionTypeByPodRequestType = PodInfoByPodRequestType;

export interface SubscriptionTypeResponseType {
  electricity?: ElectricityType;
  gas?: GasType;
  customerId?: string;
}
