export const SET_FORM = 'app/WithForm/SET_FORM';
export const AUTO_FILL_FORMS = 'app/WithForm/AUTO_FILL_FORMS';
export const SET_FORM_FIELD = 'app/WithForm/AUTO_FORM_FIELD';
export const WITH_FORM_REDUCER = 'WithForm';

export const FORM_NAMES = {
  home: 'HOME_FORM',
  contrat: 'CONTRACT_FORM',
  billing: 'BILLING_FORM',
  energyChoice: 'ENERGY_CHOICE',
  pod: 'POD_FORM',
  consumptionPDL: 'CONSUMPTION_FORM_PDL',
  consumptionPCE: 'CONSUMPTION_FORM_PCE',
  consumptionByName: 'CONSUMPTION_BY_NAME_FORM',
  estimation: 'ESTIMATION',
  quotation: 'QUOTATION',
  electricMobilityConsumptionForm: 'ELECTRIC_MOBILITY_CONSUMPTION_FORM',
} as const;
