import {
  HousingEstimatorEventType,
  ContractPageEventType,
  EnergyChoiceQuotationEventType,
  HomeQuotationEventType,
  OccupantNameConsumption,
  UsageEstimatorEventType,
  MyDeliveryPodEventType,
  PaymentEventType,
  PaymentModeEventType,
  QuotationHelpClick,
  QuotationKnowConsumption,
  QuotationPageEventType,
  ResumeProgressionEventType,
  SaveProgressionEventType,
  ServicesOptionPageEventType,
  SubmitQuotationPOD,
  SubmitSubscriptionPOD,
  SummaryModeEventType,
  MeterEstimatorEventType,
} from 'src/utils/gtm/gtm.types';
import C, { GTMEventTypes } from './constants';

const pageViewFormatter = (pathName, customerType, businessPart) => ({
  event: GTMEventTypes.PAGE_VIEW,
  environment: C.ENVIRONMENT_NAME,
  page: pathName,
  customerType,
  businessPart,
});

const submitQuotationEventFormatter = (
  event,
  quotation,
  customerType,
  businessPart,
  userEmail,
  userPhone,
):
  | QuotationPageEventType
  | ServicesOptionPageEventType
  | ContractPageEventType
  | MyDeliveryPodEventType
  | PaymentModeEventType
  | SummaryModeEventType
  | PaymentEventType => {
  const hasContactInfo = userEmail || userPhone;

  return {
    event,
    environment: C.ENVIRONMENT_NAME,
    customerType,
    businessPart,
    ...(hasContactInfo && {
      userData: {
        ...(userEmail && { userEmail }),
        ...(userPhone && { userPhone }),
      },
    }),
    ...quotation,
  };
};

const resumeProgressionEventFormatter = ({
  subscriptionId,
  quoteId,
  funnelInformations,
  customerType,
  businessPart,
}): ResumeProgressionEventType => ({
  event: GTMEventTypes.RESUME_PROGRESSION,
  environment: C.ENVIRONMENT_NAME,
  customerType,
  subscriptionId,
  quoteId,
  funnelInformations,
  businessPart,
});

const saveProgressionEventFormatter = (
  userEmail,
  customerType,
  businessPart,
): SaveProgressionEventType => ({
  event: GTMEventTypes.SAVE_PROGRESSION,
  environment: C.ENVIRONMENT_NAME,
  customerType,
  businessPart,
  userData: {
    userEmail,
  },
});

const subscriptionStepFormatter = (funnelType, stepNumber, customerType, businessPart) => ({
  event: GTMEventTypes.SUBSCRIPTION_STEP,
  environment: C.ENVIRONMENT_NAME,
  businessPart,
  customerType,
  page: {
    funnelType,
    stepNumber,
    error: false, // Todo: check with data team the use of that variable
  },
});

const submitHomepageEventFormatter = ({
  estimatedProjectDate,
  subscriptionReason,
  advantagesCode,
  customerType,
  businessPart,
}): HomeQuotationEventType => ({
  event: GTMEventTypes.QUOTATION_HOME,
  environment: C.ENVIRONMENT_NAME,
  estimatedProjectDate,
  subscriptionReason,
  customerType,
  businessPart,
  ecommerce: {
    items: [
      {
        advantagesCode,
      },
    ],
  },
});

const submitEnergyChoicePageEventFormatter = ({
  subscriptionReason,
  advantagesCode,
  itemName,
  customerType,
  businessPart,
}): EnergyChoiceQuotationEventType => ({
  event: GTMEventTypes.QUOTATION_ENERGY_CHOICE,
  environment: C.ENVIRONMENT_NAME,
  subscriptionReason,
  customerType,
  businessPart,
  ecommerce: {
    items: [
      {
        advantagesCode,
        itemName,
      },
    ],
  },
});

const housingEstimatorEventFormatter = ({
  housingSeniority,
  insulationYear,
  hasInsulationWork,
  peopleCount,
  surface,
  housingType,
  customerType,
  businessPart,
}): HousingEstimatorEventType => ({
  event: GTMEventTypes.ESTIMATOR_HOUSING,
  environment: C.ENVIRONMENT_NAME,
  housingSeniority,
  insulationYear,
  hasInsulationWork,
  peopleCount,
  surface,
  housingType,
  customerType,
  businessPart,
});

const usageEstimatorEventFormatter = ({
  alternativeHeatingType,
  electricHeatingType,
  houseHeatingType,
  housingCooking,
  heatingTemperature,
  waterHeatingType,
  customerType,
  businessPart,
}): UsageEstimatorEventType => ({
  event: GTMEventTypes.ESTIMATOR_USAGE,
  environment: C.ENVIRONMENT_NAME,
  alternativeHeatingType,
  electricHeatingType,
  houseHeatingType,
  housingCooking,
  heatingTemperature,
  waterHeatingType,
  customerType,
  businessPart,
});

const meterEstimatorEventFormatter = ({
  customerType,
  businessPart,
  subscribedPower,
  tariffOption,
  airConditioningType,
  hasElectricVehicle,
  electricVehicleCharging,
  hasPools,
  poolHeatingType,
}): MeterEstimatorEventType => ({
  event: GTMEventTypes.ESTIMATOR_METER,
  environment: C.ENVIRONMENT_NAME,
  subscribedPower,
  tariffOption,
  airConditioningType,
  hasElectricVehicle,
  electricVehicleCharging,
  hasPools,
  poolHeatingType,
  customerType,
  businessPart,
});

const quotationHelpClickFormatter = (
  textValue,
  customerType,
  businessPart,
): QuotationHelpClick => ({
  event: GTMEventTypes.QUOTATION_CLICK_HELP,
  environment: C.ENVIRONMENT_NAME,
  textValue,
  customerType,
  businessPart,
});

const submitSubscriptionPODFormatter = (
  subscriptionId,
  customerType,
  businessPart,
): SubmitSubscriptionPOD => ({
  event: GTMEventTypes.SUBSCRIPTION_POD,
  environment: C.ENVIRONMENT_NAME,
  subscriptionId,
  customerType,
  businessPart,
});

const submitQuotationPODFormatter = (
  quotation,
  customerType,
  businessPart,
): SubmitQuotationPOD => ({
  event: GTMEventTypes.QUOTATION_INFORMATION,
  environment: C.ENVIRONMENT_NAME,
  customerType,
  businessPart,
  ...quotation,
});

const quotationKnowConsumptionFormatter = (
  customerType,
  businessPart,
): QuotationKnowConsumption => ({
  event: GTMEventTypes.QUOTATION_KNOWN_CONSO,
  environment: C.ENVIRONMENT_NAME,
  customerType,
  businessPart,
});

const occupantNameConsumptionFormatter = (
  subscriptionId,
  customerType,
  businessPart,
): OccupantNameConsumption => ({
  event: !subscriptionId
    ? GTMEventTypes.QUOTATION_REGISTRATION_NUMBER_OCCUPANT_NAME
    : GTMEventTypes.SUBSCRIPTION_REGISTRATION_NUMBER_OCCUPANT_NAME,
  environment: C.ENVIRONMENT_NAME,
  customerType,
  businessPart,
});

export {
  pageViewFormatter,
  subscriptionStepFormatter,
  saveProgressionEventFormatter,
  resumeProgressionEventFormatter,
  submitHomepageEventFormatter,
  submitEnergyChoicePageEventFormatter,
  housingEstimatorEventFormatter,
  usageEstimatorEventFormatter,
  submitQuotationEventFormatter,
  quotationHelpClickFormatter,
  submitQuotationPODFormatter,
  submitSubscriptionPODFormatter,
  quotationKnowConsumptionFormatter,
  occupantNameConsumptionFormatter,
  meterEstimatorEventFormatter,
};
