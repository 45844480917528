import { AppReducerType } from 'src/hocs/App/types';

/**
 * Retrieve key's corresponding object from local storage
 * @param {String} key - any existing key representing object
 * @returns {Object}
 */
const retrieveItemFromLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.error(`Unable to retrieve key : ${key} from localstorage`, error);
    return null;
  }
};
/**
 * Retrieve app store from local storage
 * @param {String} key - app key 'persist:EKW'
 * @returns {Object | null} AppReducerType
 */
const retrieveAppStoreFromLocalStorage = (key: string): AppReducerType => {
  try {
    const appStoredInLocalStorage = retrieveItemFromLocalStorage(key);
    return JSON.parse(appStoredInLocalStorage?.App);
  } catch (error) {
    console.error(`Unable to retrieve key : ${key} from localstorage`, error);
    return null;
  }
};

export { retrieveAppStoreFromLocalStorage };
