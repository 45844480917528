import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EnergyCardsType,
  FormCardsType,
} from 'src/components/organisms/EnergyCard/types';

import * as t from './types';

export const initialState: t.EnergyChoiceReducerType = {
  electricityCard: EnergyCardsType.POD_FORM,
  gasCard: EnergyCardsType.POD_FORM,
};

export const ENERGY_CHOICE_REDUCER = 'ChoixEnergie';

// this variable map all the SUCCESS CARDS & FORM CARDS
const cardsCouple = {
  [EnergyCardsType.CONSUMPTION_CARD_POD]: EnergyCardsType.POD_FORM,
  [EnergyCardsType.CONSUMPTION_CARD]: EnergyCardsType.CONSUMPTION_FORM,
};

const slice = createSlice({
  name: ENERGY_CHOICE_REDUCER,
  initialState,
  reducers: {
    // set energy cards
    setElectricityCardAction: (
      state,
      action: PayloadAction<EnergyCardsType>,
    ): t.EnergyChoiceReducerType => ({
      ...state,
      electricityCard: action.payload,
    }),
    setGasCardAction: (
      state,
      action: PayloadAction<EnergyCardsType>,
    ): t.EnergyChoiceReducerType => ({
      ...state,
      gasCard: action.payload,
    }),
    // edit cards
    editElectricityCardAction: (state): t.EnergyChoiceReducerType => ({
      ...state,
      electricityCard: cardsCouple[state.electricityCard],
    }),
    editGasCardAction: (state): t.EnergyChoiceReducerType => ({
      ...state,
      gasCard: cardsCouple[state.gasCard],
    }),
    // set all cards
    setAllCardsAction: (
      state,
      action: PayloadAction<EnergyCardsType>,
    ): t.EnergyChoiceReducerType => ({
      ...state,
      electricityCard: action.payload,
      gasCard: action.payload,
    }),
    // set form cards
    setElectricityFormCardAction: (
      state,
      action: PayloadAction<FormCardsType>,
    ): t.EnergyChoiceReducerType => ({
      ...state,
      electricityCard: action.payload,
      // for handle an very edge case, see https://joul.myjetbrains.com/youtrack/issue/PSFO-1716
      gasCard: state.electricityCard === EnergyCardsType.POD_FORM ? action.payload : state.gasCard,
    }),
    setGasFormCardAction: (
      state,
      action: PayloadAction<FormCardsType>,
    ): t.EnergyChoiceReducerType => ({
      ...state,
      gasCard: action.payload,
      // for handle an very edge case, see https://joul.myjetbrains.com/youtrack/issue/PSFO-1716
      electricityCard: state.gasCard === EnergyCardsType.POD_FORM ? action.payload : state.gasCard,
    }),
  },
});

export default slice.reducer;

// Our action creators are typed out of the box 🎉 !
export const {
  setElectricityCardAction,
  setGasCardAction,
  setAllCardsAction,
  editElectricityCardAction,
  editGasCardAction,
  setElectricityFormCardAction,
  setGasFormCardAction,
} = slice.actions;

export const { name } = slice;
