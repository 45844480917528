import { AxiosResponse } from 'axios';
import * as T from './types';
import * as C from './constants';

// TODO: dispatch on WithSubscriptionNew reducer

export function requestSubscriptionAction(): T.RequestSubscriptionActionType {
  return {
    type: C.REQUEST_SUBSCRIPTION_NEW,
  };
}

export function requestSubscriptionSuccessAction(
  subscription: T.SubscriptionResponseType,
): T.RequestSubscriptionSuccessActionType {
  return {
    type: C.REQUEST_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
}

export function requestSubscriptionFailureAction(
  error: AxiosResponse,
): T.RequestSubscriptionFailureActionType {
  return {
    type: C.REQUEST_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export function requestFinalizeSubscriptionAction(): T.RequestSubscriptionActionType {
  return {
    type: C.REQUEST_FINALIZE_SUBSCRIPTION,
  };
}

export function requestFinalizeSubscriptionSuccessAction(
  subscription: T.FinalizeResponseType,
): T.RequestFinalizeSubscriptionSuccessActionType {
  return {
    type: C.REQUEST_FINALIZE_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
}

export function requestFinalizeSubscriptionFailureAction(
  error: AxiosResponse,
): T.RequestSubscriptionFailureActionType {
  return {
    type: C.REQUEST_FINALIZE_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export function requestSaveEmailAction(): T.RequestSaveEmailActionType {
  return {
    type: C.REQUEST_SAVE_EMAIL,
  };
}

export function requestSaveEmailSuccessAction(
  subscription: T.SaveEmailResponseType,
): T.RequestSaveEmailSuccessActionType {
  return {
    type: C.REQUEST_SAVE_EMAIL_SUCCESS,
    payload: subscription,
  };
}

export function requestSaveEmailFailureAction(
  error: AxiosResponse,
): T.RequestSaveEmailFailureActionType {
  return {
    type: C.REQUEST_SAVE_EMAIL_FAILURE,
    payload: error,
  };
}

export function requestStartPresubscriptionAction() {
  return {
    type: C.REQUEST_START_PRESUBSCRIPTION,
  };
}

export function requestStartPresubscriptionSuccessAction() {
  return {
    type: C.REQUEST_START_PRESUBSCRIPTION_SUCCESS,
    payload: {},
  };
}

export function requestStartPresubscriptionFailureAction(
  error: AxiosResponse,
): T.RequestSubscriptionFailureActionType {
  return {
    type: C.REQUEST_START_PRESUBSCRIPTION_FAILURE,
    payload: error,
  };
}