import { useState } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { requestSoNew } from 'src/utils/api/api.helper';
import { useRequestHeaders } from 'src/hooks/useRequestHeaders';
import { useIsComponentMounted } from 'src/hooks/useIsComponentMounted';
import { checkServerErrorType } from 'src/utils/errorManager/logic.helper';

interface UseRequestType<ResponseType, PayloadType> {
  data: ResponseType | null;
  loading: boolean;
  error: AxiosResponse;
  status: number;
  fetchUrl: (payload?: PayloadType) => Promise<void>;
}

/**
 * Hook that builds tools that allow you to do API requests
 *
 * @param url
 * @param method
 * @param getBody
 * @param onRequest
 * @param onSuccess
 * @param onError
 * @param responseMiddleware optional function that would reformat the data before setting it, also allowing to throw
 * an error in a custom way. If you do so, then you need to throw an error using `buildAxiosError` function
 * ```
 * const middleware = (data) => {
 *   throw buildAxiosError(data, 'ERROR');
 * }
 * ```
 */
export function useRequest<ResponseType, PayloadType>(
  url: string,
  method: string,
  getBody: (payload?: PayloadType) => PayloadType,
  onRequest: () => void,
  onSuccess: (data: ResponseType) => void,
  onError: (err) => void,
  responseMiddleware: (x: AxiosResponse<ResponseType>) => AxiosResponse<ResponseType> = (
    identity,
  ) => identity,
): UseRequestType<ResponseType, PayloadType> {
  const [data, setData] = useState<ResponseType | null>(null);
  const [error, setError] = useState<AxiosResponse | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean | null>(false);
  const headers = useRequestHeaders();
  const isComponentMounted = useIsComponentMounted();

  async function fetchUrl(payload = null) {
    setLoading(true);
    onRequest();
    try {
      setError(null);
      const body = getBody ? getBody(payload) : payload;
      const result = await requestSoNew(method, url, body, headers);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { data, status } = responseMiddleware(result);
      setData(data);
      setStatus(status);
      onSuccess(data);
    } catch (err) {
      console.error('Something went wrong in fetchUrl() - useRequest', err);
      const { response }: AxiosError = err;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { status }: AxiosResponse = response;
      checkServerErrorType(status);
      setError(response);
      setStatus(status);
      onError(response);
      throw response;
    } finally {
      if (isComponentMounted()) setLoading(false);
    }
  }

  return {
    data,
    loading,
    error,
    status,
    fetchUrl,
  };
}

export const buildAxiosError = <T,>(
  response: AxiosResponse<T>,
  errorMessage: string,
): Partial<AxiosError> => ({
  response: {
    ...response,
    data: {
      ...response?.data,
      message: errorMessage,
    },
  },
});
