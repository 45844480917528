import { AxiosResponse } from 'axios';
import { ValueOf } from 'src/utils/typescript/typescript.helper';
import { CustomerTypeType } from 'src/hocs/WithUserInformations/types';
import { EnergyType } from 'src/hocs/App/types';

import * as c from './constants';

/**
 * REDUX
 */

export interface CreatePromoCodeRequestActionType {
  type: typeof c.CREATE_PROMO_CODE_REQUEST;
}

export interface CreatePromoCodeSuccessActionType {
  type: typeof c.CREATE_PROMO_CODE_SUCCESS;
  payload: PromoCodeResponseType;
}

export interface CreatePromoCodeFailureActionType {
  type: typeof c.CREATE_PROMO_CODE_FAILURE;
  payload: AxiosResponse;
}

export interface SetCodeActionType {
  type: typeof c.SET_CODE;
  payload: string;
}

export interface TriggerPromoCodeRequestActionType {
  type: typeof c.TRIGGER_PROMO_CODE_REQUEST;
  payload: string;
}

export type PromoCodeActionsType =
  | CreatePromoCodeRequestActionType
  | CreatePromoCodeSuccessActionType
  | CreatePromoCodeFailureActionType
  | SetCodeActionType;

export interface WithPromoCodeReducerType {
  promoCode: PromoCodeResponseType;
  loading: boolean;
  error: AxiosResponse;
  code: string;
}

export interface PromoCodeEnhancedType extends PromoCodeResponseType {
  code: string;
}

/**
 * API CALLS
 */

export interface PromoCodeRequestType {
  code: string;
  customerType: CustomerTypeType;
}

export const PromoCodeTypeOfPromoEnum = {
  KWH: 'KWH',
  EURO: 'EURO',
  MEMBERSHIP: 'ABONNEMENT',
} as const;
export type PromoCodeTypeOfPromoType = ValueOf<typeof PromoCodeTypeOfPromoEnum>;

export const PromoCodeTypeEnum = {
  PREPAYMENT: 'PREPAYMENT',
  PROMOTION: 'PROMOTION',
  SPONSORSHIP: 'SPONSRSHIP', // TODO: check if possible to change that enum typo problem naming
} as const;
export type PromoCodeTypeType = ValueOf<typeof PromoCodeTypeEnum>;

export interface PromoCodeResponseType {
  isValid: boolean;
  type: PromoCodeTypeType;
  typeOfPromo: PromoCodeTypeOfPromoType;
  valueOfPromo: number;
  applicableOn: {
    [key in EnergyType]?: EnergyType;
  };
  errorCode: unknown | null;
  godfatherNumClient?: string | null;
}
