import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

export const useNavigateTo = () => {
  const dispatch = useDispatch();
  return {
    navigateTo: (
      route: string,
      urlParams: Record<string, unknown> = null, // concatanated to the url
      state: Record<string, unknown> = null, // pass to the components props
    ) => dispatch(push(route.concat('?').concat(queryString.stringify(urlParams)), state)),
  };
};
