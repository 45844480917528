/* eslint-disable @typescript-eslint/no-explicit-any -- too complex to solve these any */
import { Store } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import invariant from 'invariant';
import conformsTo from 'lodash/conformsTo';

import AppConfig from 'src/config/config';
import checkStore from './checkStore';
import { DAEMON, ONCE_TILL_UNMOUNT, RESTART_ON_REMOUNT } from './constants';

export type CustomStore = Store<any, any> & { injectedSagas: any; runSaga: any };
type DescriptorType = {
  mode?: string;
  saga?: any;
};

const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];

const checkKey = (key: string) =>
  invariant(
    isString(key) && !isEmpty(key),
    '(app/utils...) injectSaga: Expected `key` to be a non empty string',
  );

const checkDescriptor = (descriptor: DescriptorType) => {
  const shape = {
    saga: isFunction,
    mode: (mode: string) => isString(mode) && allowedModes.includes(mode),
  };
  invariant(
    conformsTo(descriptor, shape),
    '(app/utils...) injectSaga: Expected a valid saga descriptor',
  );
};

export function injectSagaFactory(store: CustomStore, isValid: boolean) {
  return function injectSaga(key: string, descriptor: DescriptorType = {}, args?: any) {
    if (!isValid) checkStore(store);

    const newDescriptor = { ...descriptor, mode: descriptor.mode || RESTART_ON_REMOUNT };
    const { saga, mode } = newDescriptor;

    checkKey(key);
    checkDescriptor(newDescriptor);

    let hasSaga = Reflect.has(store.injectedSagas, key);

    if (AppConfig.NODE_ENV !== 'production') {
      const oldDescriptor = store.injectedSagas[key];
      // enable hot reloading of daemon and once-till-unmount sagas
      if (hasSaga && oldDescriptor.saga !== saga && oldDescriptor.task) {
        oldDescriptor.task.cancel();
        hasSaga = false;
      }
    }
    try {
      if (!hasSaga || (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT)) {
        store.injectedSagas[key] = { ...newDescriptor, task: store.runSaga(saga, args) }; // eslint-disable-line no-param-reassign
      }
    } catch (error) {
      console.error('Something went wrong in injectSaga()', { error });
    }
  };
}

export function ejectSagaFactory(store: CustomStore, isValid: boolean) {
  return function ejectSaga(key: string) {
    if (!isValid) checkStore(store);

    checkKey(key);

    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];
      if (descriptor.mode !== DAEMON) {
        try {
          if (descriptor.task) descriptor.task.cancel();
          // Clean up in production; in development we need `descriptor.saga` for hot reloading
          if (AppConfig.NODE_ENV === 'production') {
            // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
            store.injectedSagas[key] = 'done'; // eslint-disable-line no-param-reassign
          }
        } catch (error) {
          console.error('Something went wrong in ejectSagaFactory() - sagasInjectors', error);
        }
      }
    }
  };
}

export default function getInjectors(store: CustomStore) {
  checkStore(store);

  return {
    injectSaga: injectSagaFactory(store, true),
    ejectSaga: ejectSagaFactory(store, true),
  };
}
