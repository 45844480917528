const routes = {
  souscrire: '/',
  devis: '/devis',
  choixEnergie: '/choix-energie',
  nonEligible: '/non-eligible',
  contrat: '/contrat',
  serviceOption: '/service-option',
  modeFacturation: '/mode-facturation',
  summary: '/recapitulatif',
  estimation: '/estimation',
  myDelivery: '/ma-livraison',
  livraisonPod: '/ma-livraison/pod',
  paiement: '/paiement',
  paiementSuccess: '/paiement-success',
  paiementFailed: '/paiement-failed',
  unPaid: '/a-bientot',
  alreadyClient: '/doublon',
  reprise: '/content-de-vous-revoir/:id',
  repriseNoId: '/content-de-vous-revoir',
  resumeWithoutPOD: '/au-revoir',
  maintenance: '/maintenance',
  prefill: '/personnalisation',
  mandateSignature: '/signature-mandat',
  mandateSignatureFinalized: '/bienvenue-chez-nous',
  groupPurchaseSummary: '/signature-emce24',
  groupPurchaseLanding: '/campagne-emce24',
} as const;

export const noStickyBannerRoutes = [
  routes.unPaid,
  routes.maintenance,
  routes.resumeWithoutPOD,
  routes.mandateSignatureFinalized,
  routes.alreadyClient,
  routes.paiementSuccess,
  routes.paiementSuccess,
  routes.alreadyClient,
  routes.nonEligible,
  routes.estimation,
] as string[];

export const checkIfRoutesHasStickyBanner = (pathName: string) =>
  !noStickyBannerRoutes.includes(pathName);

export const Url = (routeKey: string, params: Record<string, unknown>) => {
  let url = routes[routeKey] || routeKey;
  try {
    Object.keys(params).forEach((key) => {
      url = url.replace(`:${key}`, params[key]);
    });
  } catch (error) {
    console.error('Something went wrong in routes.js#Url(), routeKey is undefined', error);
  }
  return url;
};

export default routes;
