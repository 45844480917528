export const WITH_ELECTRIC_MOBILITY = 'WithElectricMobility';
export const SET_HAS_ELECTRIC_MOBILITY_OFFER_MODAL =
  'app/WithElectricMobility/SET_HAS_ELECTRIC_MOBILITY_OFFER_MODAL';
export const SET_HAS_ALREADY_SEEN_ELECTRIC_MOBILITY_OFFER_MODAL =
  'app/WithElectricMobility/SET_HAS_ALREADY_SEEN_ELECTRIC_MOBILITY_OFFER_MODAL';
export const SET_HAS_ELECTRIC_MOBILITY = 'app/WithElectricMobility/SET_HAS_ELECTRIC_MOBILITY';

export const ELECTRIC_MOBILITY_ANNUAL_CONSUMPTION_REFERENCE = {
  BASE: {
    MINIMUM: 500,
    THRESHOLD: 3000,
  },
  LOW: {
    DEFAULT: 2500,
    MINIMUM: 1000,
  },
  MINIMUM_DIALS_NUMBER: 1,
};

export const BUSINESS_PART_ELECTRIC_MOBILITY_REGEX = /_VEL$/;
