const ENVIRONMENT_NAME = 'Souscription';

const STEPS = {
  DEFAULT: 1,
  DEVIS_HOME: 1,
  DEVIS_CHOIX_ENERGIE: 2,
  DEVIS_DEVIS: 3,
  SOUSCRIRE_SERVICE_OPTION: 4,
  SOUSCRIRE_CONTRAT: 5,
  SOUSCRIRE_MY_DELIVERY: 6,
  SOUSCRIRE_LIVRAISON_POD: 6,
  SOUSCRIRE_MODE_FACTURATION: 7,
  SOUSCRIRE_SUMMARY: 8,
  SOUSCRIRE_PAIEMENT: 9,
  SOUSCRIRE_PAIEMENT_SUCCESS: 10,
};

const BUSINESS = {
  CATEGORY_ENERGIES: 'Energies',
  CATEGORY_SERVICE: 'Service',
  CATEGORY_OPTION: 'Option',
  NAME_ELECTRICITY: 'Electricité',
  NAME_GAZ: 'Gaz',
  NAME_GAZ_BIO: 'Gaz 100% biomethane',
  NAME_SMALL_PRODUCERS: 'Option Petits Producteurs',
  NAME_HEATER_REPAIR: 'Entretien Chaudière',
  NAME_SUPPORT_REPAIR: 'Assistance dépannage',
  NAME_DIFFUSE_FADING: 'Thermostat Narco',
  CODE_GAZ_BIO: 'GGO',
  CODE_SMALL_PRODUCERS: 'AGO',
  CODE_HEATER_REPAIR: 'DepChau',
  CODE_SUPPORT_REPAIR: 'DepElec',
  CODE_DIFFUSE_FADING: 'VOLTALIS0',
};

export default {
  STEPS,
  BUSINESS,
  ENVIRONMENT_NAME,
};

export enum GTMEventTypes {
  USER = 'user',
  PAGE_VIEW = 'pageview',
  ESTIMATOR_HOUSING = 'estimateur_logement',
  ESTIMATOR_USAGE = 'estimateur_usage',
  ESTIMATOR_METER = 'estimateur_compteurs',
  RESUME_PROGRESSION = 'reprendre_progression',
  SAVE_PROGRESSION = 'sauvegarder_progression',
  QUOTATION_PRO = 'devis_entreprise',
  QUOTATION_CLICK_HELP = 'devis_click_aide',
  QUOTATION_HOME = 'devis_home',
  QUOTATION_ENERGY_CHOICE = 'devis_choix_energie',
  QUOTATION_SUMMARY = 'devis_recapitulatif',
  QUOTATION_INFORMATION = 'devis_renseignement_pod',
  QUOTATION_KNOWN_CONSO = 'devis_je_connais_ma_conso',
  QUOTATION_REGISTRATION_NUMBER_OCCUPANT_NAME = 'devis_matricule_nom_occupant',
  SUBSCRIPTION_STEP = 'virtual_page',
  SUBSCRIPTION_REGISTRATION_NUMBER_OCCUPANT_NAME = 'souscrire_matricule_nom_occupant',
  SUBSCRIPTION_POD = 'souscrire_pod',
  SUBSCRIPTION_CONTACT_PHONE = 'souscrire_contact_phone',
  SUBSCRIPTION_CONTACT_MAIL = 'souscrire_contact_mail',
  SUBSCRIPTION_OPTION_CHOICE = 'souscrire_choix_option',
  SUBSCRIPTION_ADD_CONTACT_DETAILS = 'souscrire_ajout_coordonnees',
  SUBSCRIPTION_SERVICE_CHARGE = 'souscrire_frais_service',
  SUBSCRIPTION_PAYMENT_MODE = 'souscrire_mode_paiement',
  SUBSCRIPTION_SUMMARY = 'souscrire_recapitulatif',
  SUBSCRIPTION_ADD_PAYMENT_INFO = 'souscrire_ajout_info_paiement',
  SUBSCRIPTION_PAYMENT_SUCCESS = 'souscrire_paiement_success',
}
