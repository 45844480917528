import { BreakpointEnum } from 'src/theme/theme.types';

export const breakpoints = {
  upMedium: `@media (min-width: ${BreakpointEnum.XL}px)`,
  upTablet: `@media (min-width: ${BreakpointEnum.MD}px)`,
  upSmall: `@media (min-width: ${BreakpointEnum.SM}px)`,
  downMedium: `@media (max-width: ${BreakpointEnum.XL}px)`,
  downTablet: `@media (max-width: ${BreakpointEnum.MD}px)`,
  small: `@media (max-width: ${BreakpointEnum.SM}px)`,
  tablet: `@media (min-width: ${BreakpointEnum.SM + 1}px) and (max-width: ${BreakpointEnum.XL}px)`,
  medium: `@media (min-width: ${BreakpointEnum.XL + 1}px) and (max-width: 1280px)`,
  large: '@media (min-width: 1280px)',
} as const;
