import * as C from './constants';
import { WithElectricMobilityType } from './types';

export const initialState: WithElectricMobilityType = {
  hasElectricMobilityOfferModal: false,
  hasAlreadySeenElectricMobilityOfferModal: false,
  hasElectricMobility: false,
};

function WithElectricMobilityReducer(state = initialState, action): WithElectricMobilityType {
  switch (action.type) {
    case C.SET_HAS_ELECTRIC_MOBILITY_OFFER_MODAL: {
      return {
        ...state,
        hasElectricMobilityOfferModal: action.payload,
      };
    }
    case C.SET_HAS_ALREADY_SEEN_ELECTRIC_MOBILITY_OFFER_MODAL: {
      return {
        ...state,
        hasAlreadySeenElectricMobilityOfferModal: action.payload,
      };
    }
    case C.SET_HAS_ELECTRIC_MOBILITY: {
      return {
        ...state,
        hasElectricMobility: action.payload,
      };
    }
    default:
      return state;
  }
}

export default WithElectricMobilityReducer;
