export enum HeadersName {
  X_SELLER_ID = 'X-Seller-Id',
  X_TENANT_ID = 'X-Tenant-Id',
  X_SELLER_CHANNEL_ID = 'X-Seller-Channel-Id',
  CONTENT_TYPE = 'Content-type',
}

export enum ContentTypeName {
  APPLICATION_JSON = 'application/json',
}
