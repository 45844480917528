import React from 'react';

import { isFeatureDisabled } from 'src/utils/featureFlag';
import * as t from './types';

export const FeatureFlagComponent: React.FC<t.FeatureFlagComponentProps> = ({
  featureName,
  children,
  alternativeFeature = null,
}: t.FeatureFlagComponentProps) => {
  if (isFeatureDisabled(featureName)) return alternativeFeature;
  return children;
};
