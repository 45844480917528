import { AxiosResponse } from 'axios';

import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const SendingModeEnum = {
  PAPER: 'Papier',
  MAIL: 'Mail',
} as const;

type SendingModeEnumType = ValueOf<typeof SendingModeEnum>;

export const BillingRythmEnum = {
  FLAT: 'LISSÉE',
  SEASONALIZED: 'SAISONALISÉE',
} as const;

type BillingRythmEnumType = ValueOf<typeof BillingRythmEnum>;

export const PaymentModeEnum = {
  AUTOMATIC: 'Prélèvement automatique',
  CREDIT_CARD: 'Carte bancaire',
} as const;

type PaymentModeEnumType = ValueOf<typeof PaymentModeEnum>;

/*
    Redux Types
 */

export interface BillingReducerType {
  billingMethodsLoading: boolean;
  billingMethodsError: AxiosResponse;
  billingMethods: BillingMethodsResponseType;
  monthlyBillLoading: boolean;
  monthlyBillError: AxiosResponse;
  monthlyBill: MonthlyBillResponseType;
  mandateLoading: boolean;
  mandateError: AxiosResponse;
  mandate: MandateResponseType;
}

export interface BaseActionType {
  type: string;
}

export interface FailureActionType extends BaseActionType {
  payload?: AxiosResponse;
}

export interface GetBillingMethodsSuccessActionType extends BaseActionType {
  payload: BillingMethodsResponseType;
}

export interface GetMonthlyBillingSuccessActionType extends BaseActionType {
  payload: MonthlyBillResponseType;
}

export interface GetMandateSuccessActionType extends BaseActionType {
  payload: MandateResponseType;
}

/*
    API Types
 */

interface BillingType {
  sendingMode: SendingModeEnumType;
  labelBillingRythme: BillingRythmEnumType;
  labelPaymentMode: PaymentModeEnumType;
  labelSendingMode: SendingModeEnumType;
}

export interface BillingMethodsResponseType {
  ANN: {
    DOM: Array<BillingType>;
  };
  MENS: {
    DOM: Array<BillingType>;
    CB: Array<BillingType>;
  };
}

type MonthlyBillPricesType = [number, number, number];

export interface TemplateEnergyPartsType {
    annualMonthlyFee: {
      ttc: {
        ELECTRICITY: number;
        GAS: number;
      };
      ht: {
        ELECTRICITY: number;
        GAS: number;
      };
    };
    ttc: {
      GAS?: MonthlyBillPricesType;
      ELECTRICITY?: MonthlyBillPricesType;
    };
    ht: {
      GAS?: MonthlyBillPricesType;
      ELECTRICITY?: MonthlyBillPricesType;
    };
  };

export interface MonthlyBillResponseType {
  divAmount: number;
  nextReadingDate: string; // formatted as YYYY-MM-DD
  shortTemplate: boolean;
  displayShortTemplate: boolean;
  totalAmountIncVAT: number;
  totalTemplateAnnualFee: number;
  totalTemplateAnnualFeeMin: number;
  totalTemplateAnnualFeeMax: number;
  totalTemplateMonthlyFee: number;
  totalMonthlyFeeRecommended: number;
  totalMonthlyFeeRecommendedMin: number;
  totalMonthlyFeeRecommendedMax: number;
  nextInvoiceDate: string; // formatted as YYYY-MM-DD
  templateEnergyParts: TemplateEnergyPartsType;
}

export interface MandateResponseType {
  id: string;
  iban: string;
  bic: string;
  mandateId: string;
  levyType: string;
  extId: string;
  bankName: string;
  shopId?: string;
}

export interface MandateMinimalType {
  iban: MandateResponseType['iban'];
  bic: MandateResponseType['bic'];
  mandateId: MandateResponseType['mandateId'];
  levyType: MandateResponseType['levyType'];
  shopId?: MandateResponseType['shopId'];
}

export interface GetMandateRequestType {
  iban: string;
  subscriptionId: string;
}

export const CurrencyEnum = {
  EUR: 'EUR',
} as const;

export type CurrencyEnumType = ValueOf<typeof CurrencyEnum>;
