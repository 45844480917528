import pick from 'lodash/pick';

export const constrainRequestPayload = <T>(payload: T, fields: Record<string, string>) =>
  pick<T>(payload, Object.values(fields)); // TODO Improve the return type

// We need to add a `readonly any[]` else the helper doesn't detect arrays as const
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValueOf<T> = T extends readonly any[] | any[] ? T[number] : T[keyof T];

/**
 * Builds a function that allows to build another function. This new function can be used to check
 * a dictionary's values are among the keys of another object, **while keeping the literal typing of the dictionary**.
 *
 * Example:
 * ```
 * const buildPodInfoFields = fieldsTypeCheckerFactory<PodInfoType>();
 * export const POD_INFO_FIELDS = buildPodInfoFields({
 *   FIELD1: 'fieldKey1',
 *   FIELD2: 'fieldKey2',
 * })
 * ```
 *
 * The utility checks that 'fieldKey1' and 'fieldKey2' are indeed in the keys of PodInfoType,
 * but it also keeps the information that POD_INFO_FIELDS.FIELD1 is exactly 'fieldKey1'.
 * (which is essential to properly type our forms)
 *
 * This solution comes from a question I had to ask on StackOverflow.
 * https://stackoverflow.com/questions/63362083/type-checking-the-keys-of-a-const-literal-without-losing-the-type
 */
export const fieldsTypeCheckerFactory =
  <ReferenceObject>() =>
  <DictionaryToCheck extends Record<string, keyof ReferenceObject>>(
    o: DictionaryToCheck,
  ): DictionaryToCheck =>
    o;
