import { createSelector } from 'reselect';

import { AppStateType } from 'src/store/appstate.type';
import * as c from 'src/hocs/WithPayments/constants';

export const selectPayments = (state: AppStateType) => state?.[c.WITH_PAYMENTS];

export const selectPaymentsList = createSelector(selectPayments, (substate) => substate?.payments);

export const makeSelectPaymentsList = () => selectPaymentsList;
