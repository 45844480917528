import 'isomorphic-fetch';
import { ContentTypeName, HeadersName } from 'src/utils/api/api.constants';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return response.json().then((body) => {
    // We keep this code if we ever need to activate this feature again
    // if (shouldRedirectUser(body)) {
    //   return;
    // }
    throw body;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, opt) {
  const options = {
    ...opt,
    // credentials: 'include',
  };

  const safeurl = url;

  return fetch(safeurl, options)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => ({ data }));
}

/**
 * @deprecated use requestSOWithHeaders instead
 * Requests a URL, returning a promise, set Content-type to json by default
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */
export function requestJSON(url, opts) {
  const options = {
    ...opts,
  };
  if (typeof options.body === 'object') {
    options.body = JSON.stringify(options.body);
  }
  options.headers = {
    ...options.headers,
    [HeadersName.CONTENT_TYPE]: ContentTypeName.APPLICATION_JSON,
    Accept: ContentTypeName.APPLICATION_JSON,
  };
  return request(url, options);
}
