import { BUSINESS_PARTS } from 'src/hocs/App/constants';

export const BUSINESS_PARTNERS_LIST = {
  SELECTRABIOGAZ: BUSINESS_PARTS.SELECTRABIOGAZ,
  SELECTRA: BUSINESS_PARTS.SELECTRA,
  SELECTRAPRINTEMPS2022: BUSINESS_PARTS.SELECTRAPRINTEMPS2022,
  MTAUX: BUSINESS_PARTS.MTAUX,
  BUTAGAZ: BUSINESS_PARTS.BUTAGAZ,
  HELLOWT: BUSINESS_PARTS.HELLOWT,
};
