import { FieldError, RegisterOptions, Control } from 'react-hook-form';

import { FormDataType } from 'src/utils/form/form.helper.types';

interface InputPropsResult<
  FieldName extends keyof FormShape,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormShape extends Record<string, any>,
> {
  name: FieldName;
  id: string;
  formRef: (ref: HTMLInputElement) => void;
  error: FieldError;
  defaultValue: FormShape[FieldName];
  control: Control;
}

export const getInputPropsFromName = <
  FieldName extends keyof FormShape,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormShape extends Record<string, any>,
>(
  name: FieldName,
  formData: FormDataType<FormShape>,
  validationRules: RegisterOptions = null,
): InputPropsResult<FieldName, FormShape> => {
  const { register, errors, control, defaultValues } = formData;
  return {
    name,
    id: name as string,
    formRef: (ref: HTMLInputElement) => {
      register(ref, validationRules);
    },
    // @ts-ignore TODO something does not make sense here
    error: errors?.[name],
    defaultValue: defaultValues?.[name],
    control,
  };
};

export const handleDropDownChange = (e, field, formData) => {
  formData.register(field);
  formData.setValue(field, e?.value);
  formData.clearErrors(field);
};
