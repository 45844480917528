import { ERROR_ACTION } from './constants';

export function errorAction(error) {
  return {
    type: ERROR_ACTION,
    payload: error,
  };
}

export function resetErrorAction() {
  return {
    type: ERROR_ACTION,
    payload: null,
  };
}
