import { createSelector } from 'reselect';
import keys from 'lodash/keys';
import { AxiosResponse } from 'axios';

import { AppStateType } from 'src/store/appstate.type';
import { makeSelectDevis } from 'src/hocs/WithDevis/selectors';
import { ELECTRICITY, GAZ, DUAL } from 'src/hocs/WithDevis/constants';

import { ENEDIS, GRDF, WITHELIGIBILITY } from './constants';
import * as t from './types';

const selectEligibility = (state: AppStateType) => state?.[WITHELIGIBILITY];

export const selectCurrentEligibility = createSelector(
  selectEligibility,
  (substate): t.EligibilityResponseType => (substate ? substate.eligibility : null),
);

export const selectCurrentPodRegex = createSelector(
  selectEligibility,
  (substate): t.PodRegexResponseType => (substate ? substate.PODType : null),
);

export const makeSelectEligibility = () => selectCurrentEligibility;

export const makeSelectPODType = () => selectCurrentPodRegex;

/**
 * Returns whether the address is ELD or not for a DUAL order
 */
const isELDForDual = (eligibility: t.EligibilityReducerType): boolean =>
  ![GRDF].includes(eligibility?.PODType?.[GAZ]?.grdCode) ||
  ![ENEDIS].includes(eligibility?.PODType?.[ELECTRICITY]?.grdCode);

/**
 * Returns whether the address is an ELD or not, based on whether the user
 * ordered elec, gas, or dual.
 */
export const makeIsELD = () =>
  createSelector([selectEligibility, makeSelectDevis()], (eligibility, devis): boolean => {
    if (
      !(
        eligibility &&
        eligibility.PODType &&
        keys(eligibility.PODType).length > 0 &&
        devis &&
        devis.energy
      )
    )
      return null;
    if (devis.energy === ELECTRICITY)
      return ![ENEDIS].includes(eligibility.PODType[ELECTRICITY].grdCode);
    if (devis.energy === GAZ) return ![GRDF].includes(eligibility.PODType[GAZ].grdCode);
    if (devis.energy === DUAL) return isELDForDual(eligibility);
    return false;
  });

export const selectNonEligibility = createSelector(
  selectEligibility,
  (subState): t.NonEligibilitySubReducerType => (subState ? subState.nonEligibility : null),
);

export const selectNonEligibilityData = createSelector(
  selectNonEligibility,
  (subState): t.NonEligibilityResponseType => (subState ? subState.data : null),
);

export const selectNonEligibilityError = createSelector(
  selectNonEligibility,
  (subState): AxiosResponse<unknown> => (subState ? subState.error : null),
);
