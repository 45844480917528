import routes from 'src/utils/routes/routes';
import { getStore } from 'src/store/storeSingleton';
import { PURGE_REDUX } from 'src/store/reducers';
import { Persistor } from 'redux-persist/es/types';

export const purgeReduxAndPersistor = async (): Promise<void> => {
  const store = getStore();
  await store?.persistor?.purge();
  store.dispatch({ type: PURGE_REDUX });
  await store?.persistor?.flush();
};

export const emptyReduxCache = (persistor: Persistor): void => {
  const shouldPurge = shouldPurgeCacheHelper();
  if (shouldPurge) persistor.purge();
};

export const isHomePage = (): boolean => window.location.pathname === routes.souscrire;

export const shouldPurgeCacheHelper = (): boolean => {
  const regexPerso = /personnalisation(\/)*.*/;
  const isPersonnalisation = regexPerso.test(window.location.pathname);
  // eslint-disable-next-line no-useless-escape
  const regexReprise = new RegExp(`${routes.repriseNoId}\/([a-z0-9\-]+)`);
  const isReprisePage = regexReprise.test(window.location.pathname);
  // eslint-disable-next-line no-useless-escape
  const regexMandateSignature = new RegExp(`${routes.mandateSignature}\/`);
  const isMandateSignaturePage = regexMandateSignature.test(window.location.pathname);

  return isHomePage() || isReprisePage || isPersonnalisation || isMandateSignaturePage;
};
