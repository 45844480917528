import { REQUEST_SUBSCRIPTION_SUCCESS, SET_FRAUD_DOUBLON } from './constants';

export function requestSubscriptionSuccessAction(subscription) {
  return {
    type: REQUEST_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
}

export function setFraudDoublonAction(status) {
  return {
    type: SET_FRAUD_DOUBLON,
    payload: status,
  };
}
