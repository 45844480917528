import routes from 'src/utils/routes/routes';
import { RouteValues } from 'src/utils/routes/types';

export const isProgressionSavable = () => {
  const progressionRoutesAvailable: Partial<RouteValues>[] = [
    routes.devis,
    routes.paiement,
    routes.contrat,
    routes.serviceOption,
    routes.modeFacturation,
    routes.paiementSuccess,
    routes.paiementFailed,
    routes.summary,
  ];

  // TODO use react router methods
  const path = window.location.pathname;

  return progressionRoutesAvailable.includes(path as RouteValues);
};
