import { FC } from 'react';
import { createPortal } from 'react-dom';
import Column from 'src/components/ui/layouts/column/Column';
import CloseButton from 'src/components/molecules/CloseButton';
import Heading from 'src/components/ui/typography/heading/Heading';
import { FormattedMessage } from 'react-intl';
import messages from 'src/hocs/App/messages';
import Button from 'src/components/ui/button/Button';
import Text from 'src/components/ui/typography/text/Text';
import * as S from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal: FC<ModalProps> = ({
  children,
  id,
  isClosable,
  alignContent = 'left',
  hasTopCloseButton = false,
  isLoaderModal,
  headingText,
  hasBackground = true,
  onClose,
  isOpened,
  illustration,
  closeButtonLabel,
  footerButtons,
  description,
  'data-testid': dataTestId,
}) => {
  const handleCloseModal = () => {
    if (onClose) onClose();
  };

  return createPortal(
    <S.ModalContainer role="dialog" isOpened={isOpened}>
      <S.Modal
        id={id}
        isOpened={isOpened}
        data-testid={dataTestId}
        isLoaderModal={isLoaderModal}
        hasBackground={hasBackground}
      >
        {hasTopCloseButton && (
          <Column align="right" bottom={0}>
            <CloseButton
              data-testid={`${id}-CloseButtonTestId`}
              id={`${id}CloseButton`}
              onClick={handleCloseModal}
            />
          </Column>
        )}
        <Column align={alignContent} bottom={0}>
          {illustration && <S.ModalIllustration src={illustration} alt="" />}
          {headingText && (
            <Heading align={alignContent} type="h4">
              {headingText}
            </Heading>
          )}
          {description && (
            <Text type="m" color="attenuated" margin="0 0 10px 0">
              {description}
            </Text>
          )}
        </Column>
        <S.ModalContent align={alignContent}>{children}</S.ModalContent>
        {(isClosable || footerButtons) && (
          <S.ModalFooter align={alignContent}>
            {isClosable && (
              <Button
                id={`${id}ModalCloseButton`}
                onClick={handleCloseModal}
                // @ts-ignore FormattedMessage is poorly typed, it will surely be fixed when upgrading react-intl
                label={closeButtonLabel || <FormattedMessage {...messages.ok} />}
              />
            )}
            {footerButtons && (
              <S.ModalFooterButtons align="around" bottom={0}>
                {footerButtons}
              </S.ModalFooterButtons>
            )}
          </S.ModalFooter>
        )}
      </S.Modal>
    </S.ModalContainer>,
    document.body,
  );
};
