import { semantical } from 'src/theme/tokens/semantical';
import { customComponents } from 'src/theme/tokens/components/custom';

const { radius } = customComponents;

export const modalTheme = {
  backdrop: {
    bluriness: semantical.colors.background.special.blur,
  },
  modal: {
    backgroundColor: semantical.colors.background.neutral.strongest,
    radius: semantical.radius[radius.modal],
    desktop: {
      padding: semantical.spacing['2xl'],
    },
    mobile: {
      padding: semantical.spacing.l,
    },
  },
} as const;
