import { Helmet } from 'react-helmet-async';
import { isFeatureEnabled } from 'src/utils/featureFlag';
import { initiateGoogleMaps } from 'src/components/utils/Head/initiateGoogleMaps';
import { tenantScripts } from 'src/components/utils/Head/scripts';
import AppConfig from 'src/config/config';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

const { REACT_APP_TENANT = '', REACT_APP_STATIC_URL = '' } = AppConfig;

initiateGoogleMaps();

const Head = (): JSX.Element => {
  const { intlMessage } = useIntlMessage();
  const tenantName = intlMessage('shared.i18n.tenantName');
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta
        content={intlMessage('app.metaDescription', {
          tenantName,
        })}
        name="description"
      />
      <title>{intlMessage('app.title', { tenantName })}</title>
      <link
        href={`${REACT_APP_STATIC_URL}${REACT_APP_TENANT}/souscription/favicon/favicon.ico`}
        rel="icon"
        sizes="any"
      />
      <link
        href={`${REACT_APP_STATIC_URL}${REACT_APP_TENANT}/souscription/favicon/favicon.svg`}
        rel="icon"
        type="image/svg+xml"
      />
      <link
        href={`${REACT_APP_STATIC_URL}${REACT_APP_TENANT}/souscription/favicon/apple-touch-icon.png`}
        rel="apple-touch-icon"
      />
      {tenantScripts.map((script, index) => {
        const { isFeatureFlagEnabled, ...otherProps } = script;
        return (
          isFeatureEnabled(isFeatureFlagEnabled) && (
            <script key={index} {...otherProps}>
              {script.innerHTML && script.innerHTML}
            </script>
          )
        );
      })}
    </Helmet>
  );
};

export default Head;
