import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectBusinessPart, selectCurrentRoute } from 'src/hocs/App/selectors';
import { selectCustomerType, selectIsProfessional } from 'src/hocs/WithUserInformations/selectors';
import { selectCurrentDevis } from 'src/hocs/WithDevis/selectors';
import { selectCurrentSubscription } from 'src/hocs/WithSubscription/selectors';
import { createCommonDataLayerFields, getFunnelInformations } from 'src/utils/gtm/logic.helper';
import { selectPaymentsList } from 'src/hocs/WithPayments/selectors';
import { GTMEventTypes } from 'src/utils/gtm/constants';

export const selectCustomerId = createSelector(
  selectCurrentDevis,
  selectCurrentSubscription,
  (quotation, subscription) =>
    get(quotation, 'customerId') || get(subscription, 'quotation.customerId') || 'DVS000000001',
);

export const selectGTMQuotation = createSelector(
  selectCurrentDevis,
  selectCurrentSubscription,
  selectBusinessPart,
  selectPaymentsList,
  selectCustomerType,
  selectCustomerId,
  selectIsProfessional,
  selectCurrentRoute,
  (
    quotation,
    subscription,
    businessPart,
    payments,
    customerType,
    userId,
    isProfessional,
    route,
  ) => {
    const { eventName } = getFunnelInformations(route.pathname);
    let dataLayerFields;
    const subscriptionId = subscription?.id ?? '';
    const invoicePaymentMethod = subscription?.invoicePaymentMethod ?? '';
    const totalAnnualAmount = quotation?.totalAnnualAmount ?? 0;
    const totalAnnualAmountExclVAT = quotation?.totalAnnualAmountExclVAT ?? 0;
    const totalAnnualAmountSavedInclVAT = quotation?.totalAnnualAmountSavedInclVAT ?? 0;

    const { immediateAmountToPay } = payments;
    switch (eventName) {
      case GTMEventTypes.SUBSCRIPTION_PAYMENT_SUCCESS:
        dataLayerFields = {
          ...createCommonDataLayerFields({
            quotation,
            subscription,
            isProfessional,
          }),
          userId,
          businessPart,
          customerType,
          subscriptionId,
          immediateAmountToPay,
          invoicePaymentMethod,
          totalAnnualAmount,
          totalAnnualAmountExclVAT,
          totalAnnualAmountSavedInclVAT,
        };
        break;
      default:
        dataLayerFields = {
          ...createCommonDataLayerFields({
            quotation,
            subscription,
            isProfessional,
          }),
        };
        break;
    }
    return dataLayerFields;
  },
);

export const makeSelectGTMQuotation = () => selectGTMQuotation;

export const makeSelectCustomerId = () => selectCustomerId;
