import { semantical } from 'src/theme/tokens/semantical';

const { fonts } = semantical;

export const headingTheme = {
  desktop: {
    h1: {
      fontSize: fonts.sizes['4xl'],
      lineHeight: fonts.lineHeights['4xl'],
    },
    h2: {
      fontSize: fonts.sizes['3xl'],
      lineHeight: fonts.lineHeights['3xl'],
    },
    h3: {
      fontSize: fonts.sizes['2xl'],
      lineHeight: fonts.lineHeights.xl,
    },
    h4: {
      fontSize: fonts.sizes.xl,
      lineHeight: fonts.lineHeights.l,
    },
    h5: {
      fontSize: fonts.sizes.l,
      lineHeight: fonts.lineHeights.s,
    },
    h6: {
      fontSize: fonts.sizes.s,
      lineHeight: fonts.lineHeights['2xs'],
    },
  },
  mobile: {
    h1: {
      fontSize: fonts.sizes['2xl'],
      lineHeight: fonts.lineHeights['2xl'],
    },
    h2: {
      fontSize: fonts.sizes.xl,
      lineHeight: fonts.lineHeights.m,
    },
    h3: {
      fontSize: fonts.sizes.l,
      lineHeight: fonts.lineHeights.xs,
    },
    h4: {
      fontSize: fonts.sizes.m,
      lineHeight: fonts.lineHeights['2xs'],
    },
    h5: {
      fontSize: fonts.sizes.s,
      lineHeight: fonts.lineHeights['3xs'],
    },
    h6: {
      fontSize: fonts.sizes['2xs'],
      lineHeight: fonts.lineHeights['4xs'],
    },
  },
};
