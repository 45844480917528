/* eslint-disable @typescript-eslint/no-explicit-any -- too complex to solve these any */
import { Store } from '@reduxjs/toolkit';
import conformsTo from 'lodash/conformsTo';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import invariant from 'invariant';

/**
 * Validate the shape of redux store
 * @deprecated we don't really need this anymore with typescript...
 */
export default function checkStore(store: Store<any, any>) {
  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    injectedReducers: isObject,
    injectedSagas: isObject,
  };
  invariant(
    // @ts-ignore something wrong with the types but this whole function should not be used anyway
    conformsTo(store, shape),
    '(app/utils...) injectors: Expected a valid redux store',
  );
}
