import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import * as t from './types';

export const WITH_POD_INFO = 'WithPODInfo';
export const RESET_SUBSCRIPTION = 'app/WithPODInfo/RESET_SUBSCRIPTION';

export const TRIGGER_SUBSCRIPTION_TYPE_BY_POD = 'app/WithPODInfo/TRIGGER_SUBSCRIPTION_TYPE_BY_POD';
export const TRIGGER_SUBSCRIPTION_TYPE_BY_ADDRESS =
  'app/WithPODInfo/TRIGGER_SUBSCRIPTION_TYPE_BY_ADDRESS';
// subscription_type/pod constants
export const CREATE_SUBSCRIPTION_TYPE_POD_REQUEST =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_POD_REQUEST';
export const CREATE_SUBSCRIPTION_TYPE_POD_SUCCESS =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_POD_SUCCESS';
export const CREATE_SUBSCRIPTION_TYPE_POD_FAILURE =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_POD_FAILURE';
// subscription_type/address constants
export const CREATE_SUBSCRIPTION_TYPE_ADDRESS_REQUEST =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_ADDRESS_REQUEST';
export const CREATE_SUBSCRIPTION_TYPE_ADDRESS_SUCCESS =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_ADDRESS_SUCCESS';
export const CREATE_SUBSCRIPTION_TYPE_ADDRESS_FAILURE =
  'app/WithPODInfo/CREATE_SUBSCRIPTION_TYPE_ADDRESS_FAILURE';

export const TRIGGER_POD_INFO_BY_POD = 'app/WithPODInfo/TRIGGER_POD_INFO_BY_POD';
export const TRIGGER_POD_INFO_BY_ADDRESS = 'app/WithPODInfo/TRIGGER_POD_INFO_BY_ADDRESS';
export const CREATE_POD_INFO_REQUEST = 'app/WithPODInfo/CREATE_POD_INFO_REQUEST';
export const CREATE_POD_INFO_SUCCESS = 'app/WithPODInfo/CREATE_POD_INFO_SUCCESS';
export const CREATE_POD_INFO_FAILURE = 'app/WithPODInfo/CREATE_POD_INFO_FAILURE';

const buildPodByPodFields = fieldsTypeCheckerFactory<t.PodInfoByPodRequestType>();
export const POD_BY_POD_FIELDS = buildPodByPodFields({
  PDL: 'pdl',
  PCE: 'pce',
  ZIP: 'zipCode',
  CODE_INSEE: 'codeInsee',
  CUSTOMER_ID: 'customerId',
  CUSTOMER_TYPE: 'customerType',
  SUPPLIER_INTERVENTION_TYPE: 'supplierInterventionType',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
});

const buildPodByAddressFields = fieldsTypeCheckerFactory<t.PodInfoByAddressRequestType>();
export const POD_BY_ADDRESS_FIELDS = buildPodByAddressFields({
  STREET_NUMBER: 'streetNumber',
  STREET_NAME: 'streetName',
  ZIP: 'zipCode',
  CODE_INSEE: 'codeInsee',
  CITY: 'city',
  CONSUMER_LAST_NAME: 'previousConsumerName',
  ADDITIONAL_ADDRESS: 'additionalAddress',
  CUSTOMER_TYPE: 'customerType',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  USER_MOBILE_PHONE_NUMBER: 'mobilePhone',
});
