import { typedDefineMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

export default typedDefineMessage({
  tenantName: {
    id: 'shared.i18n.tenantName',
    defaultMessage: 'Ekwateur',
  },
  closeButtonAriaLabel: {
    id: 'shared.i18n.closeButtonAriaLabel',
    defaultMessage: 'Fermer',
  },
  loadingButtonLabel: {
    id: 'shared.i18n.loadingButtonLabel',
    defaultMessage: 'Chargement...',
  },
  required: {
    id: 'shared.i18n.requiredField',
    defaultMessage: 'Requis',
  },
  atLeastOneRequired: {
    id: 'shared.i18n.atLeastOneRequired',
    defaultMessage: 'Au moins un téléphone requis',
  },
  maxLength: {
    id: 'shared.i18n.maxLength',
    defaultMessage: 'Max {max} caractères',
  },
  minLength: {
    id: 'shared.i18n.minLength',
    defaultMessage: 'Minimum {min} caractères',
  },
  badFormat: {
    id: 'shared.i18n.badFormat',
    defaultMessage: 'Format invalide',
  },
  notMatch: {
    id: 'shared.i18n.notMatch',
    defaultMessage: 'Email non identique',
  },
  notValid: {
    id: 'shared.i18n.notValid',
    defaultMessage: 'Non Valide',
  },
  ok: {
    id: 'shared.i18n.ok',
    defaultMessage: 'OK',
  },
  loading: {
    id: 'app.loading',
    defaultMessage: 'Chargement en cours...',
  },
  errorHappened: {
    id: 'app.errorHappened',
    defaultMessage: 'app.errorHappened',
  },
  'No data found for this address': {
    id: 'app.nodataadresse',
    defaultMessage: 'Impossible de retrouver les informations pour cette adresse.',
  },
  customerServiceEmailContact: {
    id: 'shared.i18n.customerServiceEmailContact',
    defaultMessage: 'shared.i18n.customerServiceEmailContact',
  },
  privacyProtectionEmailAddress: {
    id: 'shared.i18n.privacyProtectionEmailAddress',
    defaultMessage: 'shared.i18n.privacyProtectionEmailAddress',
  },
  privacyProtectionPostalAddress: {
    id: 'shared.i18n.privacyProtectionPostalAddress',
    defaultMessage: 'shared.i18n.privacyProtectionPostalAddress',
  },
  resumeSubscriptionButtonLabel: {
    id: 'shared.i18n.resumeSubscriptionButtonLabel',
    defaultMessage: 'Continuer',
  },
  firstNameInputLabel: {
    id: 'shared.i18n.firstNameInputLabel',
    defaultMessage: 'Prénom',
  },
  firstNameInputPlaceholder: {
    id: 'shared.i18n.firstNameInputPlaceholder',
    defaultMessage: 'Luke',
  },
  lastNameInputLabel: {
    id: 'shared.i18n.lastNameInputLabel',
    defaultMessage: 'Nom',
  },
  lastNameInputPlaceholder: {
    id: 'shared.i18n.lastNameInputPlaceholder',
    defaultMessage: 'Skywalker',
  },
  included: {
    id: 'shared.i18n.included',
    defaultMessage: 'Inclus',
  },
  total: {
    id: 'shared.i18n.total',
    defaultMessage: 'Total',
  },
  FIRST_MONTHLY_PAYMENT: {
    id: 'shared.i18n.paymentDetails.firstMonthlyPayment',
    defaultMessage: '1ère mensualité',
  },
  SUBSCRIPTION: {
    id: 'shared.i18n.paymentDetails.subscription',
    defaultMessage: 'Acompte à la souscription',
  },
});
