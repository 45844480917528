import React from 'react';
import useIsDesktop from 'src/hooks/useIsDesktop';
import * as S from './styles';
import * as T from './types';

export const InputWithButton = ({ children, ...rest }: T.InputWithButtonTypes) => {
  const inputProps = children.find((element) => element.props.type !== 'button')?.props;
  const isDesktop = useIsDesktop();

  const displayErrorDeprecatedInput = !!(
    (inputProps.meta?.touched && inputProps.errorMessage) ||
    (inputProps.meta?.touched && inputProps.meta?.error)
  );

  const deprecatedInputLength =
    displayErrorDeprecatedInput &&
    (inputProps.meta?.error?.props?.defaultMessage?.length ||
      inputProps.errorMessage?.props?.defaultMessage?.length);

  // This function calculates the spacing to have depending on error/caption text length. So if
  // the text is long, as it is in absolute position, it will not pass over other elements
  const getSpacing = () => {
    const baseSpacing = isDesktop ? 18 : 30;
    const numberOfErrorLines =
      Math.ceil((inputProps.error?.message?.length || deprecatedInputLength || 0) / 50) || 0;
    const numberOfCaptionLines = Math.ceil((inputProps.caption?.length || 0) / 50) || 0;
    const spacingToReturn = baseSpacing * (numberOfErrorLines || numberOfCaptionLines);
    return spacingToReturn;
  };

  return (
    <S.InputWithButtonContainer spacing={getSpacing()} {...rest}>
      {children}
    </S.InputWithButtonContainer>
  );
};
