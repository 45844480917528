import { ElectricityType, GasType } from 'src/hocs/WithPODInfo/types';
import encryptCharsWithStars from 'src/utils/strings/encryptCharsWithStars';
import removeFirstNChars from 'src/utils/strings/removeFirstNChars';
import removeLastNChars from 'src/utils/strings/removeLastNChars';

/**
 * when the server response is an Array or undefined, we must not save it to the store
 * remove this function when https://joul.myjetbrains.com/youtrack/issue/PSFO-1770 is done
 */
export function isAValidPodInfoResponse(
  serverResponse?: ElectricityType | GasType | Array<void>,
): boolean {
  // if the server response is undefined or an array return false
  if (!serverResponse || Array.isArray(serverResponse)) {
    return false;
  }
  return true;
}

/**
 * Encrypts parts of PDL or PCE
 * @param {string} value PDL or PCE to hide
 * @returns string ex: 11111111111512 -> ***********512
 */
export const encryptPODNumber = (value: string) => {
  const charsToEncrypt = removeLastNChars(value, 3);
  const displayedChars = removeFirstNChars(value, 3);
  const encryptedChars = encryptCharsWithStars(charsToEncrypt);
  return `${encryptedChars}${displayedChars}`;
};