import { SellingConditionsReducerType } from 'src/components/pages/Summary/Form/SellingConditions/types';
import { GET_SELLING_CONDITIONS_SUCCESS } from './constants';

export const initialState: SellingConditionsReducerType = {
  conditions: [],
};

// Todo: refactoring - replace local redux state by useState (or move to App reducer if not possible)
function sellingConditionsReducer(state = initialState, action): SellingConditionsReducerType {
  switch (action.type) {
    case GET_SELLING_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: action.payload,
      };
    default:
      return state;
  }
}

export default sellingConditionsReducer;
