import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'src/utils/saga/injectSaga';
import { DAEMON } from 'src/utils/saga/constants';
import { makeSelectSubscription } from './selectors';
import saga from './saga';
import { WITHSUBSCRIPTION } from './constants';
import { WithSubscriptionProps } from './types';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/* @deprecated in favor of WithSubscriptionNew */
const WithSubscription = (WrappedComponent) => {
  class HOC extends React.PureComponent<WithSubscriptionProps> {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  // @ts-ignore TODO js migration
  HOC.displayName = `WithSubscription(${getDisplayName(WrappedComponent)})`;
  return compose(withSaga, withConnect)(HOC);
};

const mapStateToProps = () =>
  createStructuredSelector({
    subscription: makeSelectSubscription(),
  });

const withConnect = connect(mapStateToProps);
const withSaga = injectSaga({ key: WITHSUBSCRIPTION, saga, mode: DAEMON });

export default WithSubscription;
