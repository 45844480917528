/* eslint-disable no-cond-assign */
import { takeEvery, put } from 'redux-saga/effects';
import { errorAction } from 'src/components/organisms/Error/actions';
import { GET_PCE_FAILED, GET_PDL_FAILED } from './constants';

/**
 * Select the currentAddress of the user, then try to fetch the pdl/pce with
 * the current consumption and store the correct quotation based on the information.
 * Redirect on routes.souscrire if none address is selected, redirect to routes.choixenergie
 * if there is an error during any fetch.
 *
 *
 */
export function* redirectEstimation() {
  // return yield put(push(routes.estimation));
}

// Individual exports for testing
export default function* defaultSaga() {
  try {
    yield takeEvery(GET_PCE_FAILED, redirectEstimation);
    yield takeEvery(GET_PDL_FAILED, redirectEstimation);
  } catch (error) {
    console.error('Something went wrong in defaultSaga() - Error', error);
    yield put(errorAction(error));
  }
}
