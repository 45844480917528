import { ValueOf } from 'src/utils/typescript/typescript.helper';

export const CustomerTypeEnum = {
  PROFESSIONAL: 'PROFESSIONAL',
  PRIVATE: 'PRIVATE',
} as const;

export type CustomerTypeType = ValueOf<typeof CustomerTypeEnum>;

export interface UserInformations {
  isProfessional?: boolean;
  companyName?: string;
  customerType?: CustomerTypeType;
  firstName: string | null;
  lastName: string | null;
  customerId?: string | null;
}

export interface WithUserInformationsType extends UserInformations {
  isUserAnonymous: boolean;
}
