import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { UseFormMethods } from 'react-hook-form';

import { setErrorNewAction } from 'src/hocs/App/actions';
import { matchErrorByOrigin } from 'src/utils/errorManager/logic.helper';
import { useNavigateTo } from 'src/hooks/useNavigateTo';
import { DestinationsEnum, ErrorDefinitionType, OriginsEnum } from 'src/utils/errorManager/types';

export const useErrorCatcher = (
  error: AxiosResponse,
  origin?: OriginsEnum,
  // eslint-disable-next-line @typescript-eslint/ban-types
  setError?: UseFormMethods['setError'],
) => {
  // Define hooks to be used
  const { formatMessage } = useIntl();
  const { navigateTo } = useNavigateTo();
  const dispatch = useDispatch();
  // const originalMessage = get(error, 'data.message');
  const originalMessage = error?.data?.message;
  return useEffect(() => {
    if (originalMessage) {
      const {
        id,
        defaultMessage,
        destination,
        redirectionPath,
        isClosable,
        headlineMessage,
        html,
        htmlProps,
        inputFields,
        customErrorImage,
      }: ErrorDefinitionType = matchErrorByOrigin(originalMessage, origin);

      const shouldIgnore = destination === DestinationsEnum.NONE;

      const errorMessage = id && defaultMessage ? formatMessage({ id, defaultMessage }) : '';

      // if error should be ignored stop here
      if (shouldIgnore) return;
      if (destination === DestinationsEnum.REDIRECTION) {
        if (redirectionPath) navigateTo(redirectionPath);
        else console.warn('redirectionPath missing, please, edit the dictionary');
      } else if (destination === DestinationsEnum.POP_UP) {
        // Todo: create new Error component accepting isClosable, headlineMessage and html  as params
        // this error actions was put in here as an example only, till the ErrorNew components is created
        dispatch(
          setErrorNewAction({
            errorMessage,
            isClosable,
            headlineMessage,
            html,
            customErrorImage,
            htmlProps,
          }),
        );
      } else if (destination === DestinationsEnum.INPUT_ERROR) {
        if (setError) {
          if (typeof inputFields === 'string') setError(inputFields, { message: errorMessage });
          else if (typeof inputFields === 'object')
            inputFields.forEach((field) => setError(field, { message: errorMessage }));
        } else console.warn('setError missing, please, pass it as a parameter');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};
