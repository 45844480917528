import { WINTER_BREAK_ENDING_DATE } from 'src/utils/constants';
import queryString from 'query-string';
import { CustomerTypeEnum, CustomerTypeType } from 'src/hocs/WithUserInformations/types';
import {
  BUSINESS_PARTS,
  PRIVATE_CUSTOMER_TAX_KEY,
  PRO_CUSTOMER_TAX_KEY,
} from 'src/hocs/App/constants';
import messages from 'src/components/organisms/Footer/messages';
import AppConfig from 'src/config/config';
import * as t from './types';

const {
  REACT_APP_FAQ_URL,
  REACT_APP_CONTACT_URL,
  REACT_APP_RECRUITMENT_URL,
  REACT_APP_GENERAL_SELLING_CONDITIONS_URL,
  REACT_APP_COOKIES_URL,
  REACT_APP_POLICY_URL,
  REACT_APP_MENTIONS_URL,
  REACT_APP_CREDITS_URL,
} = AppConfig;

// an ELD is an distributor of energy where value is ENEDIS or DISCO or GINKO or GRDF
export const isElectricityELD = (distributor: t.ElectricityDistributorsType): boolean => {
  if (!distributor) {
    // if there is not distributor
    return false; // by default return false
  }

  // here a list of all not ELD distributors
  const distributorsNotELD: Array<t.ElectricityDistributorsType> = [
    t.ElectricityDistributorsEnum.ENEDIS,
    t.ElectricityDistributorsEnum.DISCO,
    t.ElectricityDistributorsEnum.GINKO,
  ];

  const isNotELD = distributorsNotELD.includes(distributor); // check if distributor equal one of distributorsNotELD
  return !isNotELD;
};

export const isGasELD = (distributor: t.GasDistributorsType): boolean => {
  if (!distributor) return false;
  return distributor !== t.GasDistributorsEnum.GRDF;
};

// Considering user choice (ELEC / DUAL / GAZ ) we find which PODS are required
function getPodsRequiredFromEnergy(energy: t.EnergyType): Array<t.PodTypesType> {
  switch (energy) {
    case t.EnergyEnum.DUAL: {
      return [t.PodTypes.PDL, t.PodTypes.PCE];
    }
    case t.EnergyEnum.ELECTRICITY: {
      return [t.PodTypes.PDL];
    }
    case t.EnergyEnum.GAS: {
      return [t.PodTypes.PCE];
    }
    default: {
      return [];
    }
  }
}

export function isPDLWanted(energy: t.EnergyType): boolean {
  return getPodsRequiredFromEnergy(energy).includes(t.PodTypes.PDL);
}

export function isPCEWanted(energy: t.EnergyType): boolean {
  return getPodsRequiredFromEnergy(energy).includes(t.PodTypes.PCE);
}

export const isProfessional = (customerType: CustomerTypeType): boolean =>
  customerType === CustomerTypeEnum.PROFESSIONAL;

export const hasWinterBreakEnded = () => Date.now() > new Date(WINTER_BREAK_ENDING_DATE).getTime();

export const getUrlParams = () => queryString.parse(window.location.search);

export const getTaxMode = (customerType: CustomerTypeType): string =>
  isProfessional(customerType) ? PRO_CUSTOMER_TAX_KEY : PRIVATE_CUSTOMER_TAX_KEY;

export const footerLinkList = [
  {
    key: 'FAQ',
    message: messages.faq,
    href: REACT_APP_FAQ_URL,
  },
  {
    key: 'Contact',
    message: messages.contact,
    href: REACT_APP_CONTACT_URL,
  },
  {
    key: 'Recruitment',
    message: messages.recruitment,
    href: REACT_APP_RECRUITMENT_URL,
  },
  {
    key: 'CGV',
    message: messages.generalSellingConditions,
    href: REACT_APP_GENERAL_SELLING_CONDITIONS_URL,
  },
  {
    key: 'Cookies',
    message: messages.cookies,
    href: REACT_APP_COOKIES_URL,
  },
  {
    key: 'Policy',
    message: messages.politic,
    href: REACT_APP_POLICY_URL,
  },
  {
    key: 'Mentions',
    message: messages.mentions,
    href: REACT_APP_MENTIONS_URL,
  },
  {
    key: 'Credits',
    message: messages.credits,
    href: REACT_APP_CREDITS_URL,
  },
];

export const isSelectraGroupPurchaseBusinessPart = (businessPart: string): boolean =>
  Object.values(BUSINESS_PARTS.SELECTRA_GROUP_PURCHASE).some(
    (selectraBusinessPart) => businessPart === selectraBusinessPart,
  );
