import { semantical } from 'src/theme/tokens/semantical';

export const scrollBarTheme = {
  width: '4px',
  height: '4px',
  borderRadius: semantical.radius.weaker,
  colors: {
    track: semantical.colors.background.special.transparent,
    thumb: semantical.colors.background.neutral.strong,
  },
};
