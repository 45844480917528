import { semantical } from 'src/theme/tokens/semantical';
import { inputTheme } from 'src/theme/tokens/components/form/input';
import { customComponents } from 'src/theme/tokens/components/custom';

const { inputSpacing } = customComponents;

export const inputTextTheme = {
  ...inputTheme,
  variants: {
    desktop: {
      textFontSize: semantical.fonts.sizes.s,
      labelFontSize: semantical.fonts.sizes.xs,
      captionFontSize: semantical.fonts.sizes['2xs'],
      lineHeight: semantical.fonts.lineHeights.xs,
    },
    mobile: {
      textFontSize: semantical.fonts.sizes.s,
      labelFontSize: semantical.fonts.sizes['2xs'],
      captionFontSize: semantical.fonts.sizes['3xs'],
      lineHeight: semantical.fonts.lineHeights['2xs'],
    },
  },
  padding: {
    noIcon: `${semantical.spacing['2xs']} ${semantical.spacing[inputSpacing]}`,
    rightIcon: `${semantical.spacing['2xs']} ${semantical.spacing['3xl']} ${semantical.spacing['2xs']} ${semantical.spacing[inputSpacing]}`,
    leftIcon: `${semantical.spacing['2xs']} ${semantical.spacing[inputSpacing]} ${semantical.spacing['2xs']} ${semantical.spacing['3xl']}`,
    bothIcon: `${semantical.spacing['2xs']} ${semantical.spacing['3xl']} ${semantical.spacing['2xs']} ${semantical.spacing['3xl']}`,
  },
  labelMargin: `${semantical.spacing.none} ${semantical.spacing.none} ${semantical.spacing.xs} ${semantical.spacing[inputSpacing]}`,
  errorMessageMargin: `${semantical.spacing.xs} ${semantical.spacing.none} ${semantical.spacing.none} ${semantical.spacing.l}`,
  height: `${semantical.sizes['5xl']}px`,
  heightWithButton: `${semantical.sizes['4xl']}px`,
  minWidth: '320px',
  iconSize: `${semantical.sizes.icon.m}px`,
  iconPosition: semantical.spacing.l,
} as const;
