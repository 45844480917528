import axios, { AxiosResponse } from 'axios';
import { retrieveAppStoreFromLocalStorage } from 'src/utils/browser/localStorage.helper';
import { ContentTypeName, HeadersName } from 'src/utils/api/api.constants';
import { selectOffre } from 'src/hocs/App/selectors';
import { select } from 'redux-saga/effects';

const { REACT_APP_API_BASE_URL = '', REACT_APP_TENANT = '' } = process.env;

const baseHeaders = {
  [HeadersName.CONTENT_TYPE]: ContentTypeName.APPLICATION_JSON,
  [HeadersName.X_SELLER_CHANNEL_ID]: 'PSFO',
};

export interface GetHeadersFromLocalStorageReturn {
  [HeadersName.X_SELLER_ID]: string;
  [HeadersName.X_SELLER_CHANNEL_ID]: string;
  [HeadersName.X_TENANT_ID]: string;
}

/**
 * Get extra headers values from localStorage to build headers for API Request
 * @returns {Object} - { 'X-Seller-Id': codeApporteur, 'X-Seller-Channel-Id': codeCanal, 'X-Tenant-Id': tenant.name }
 */
export const getHeadersFromLocalStorage = (
  xSellerId?: string,
): GetHeadersFromLocalStorageReturn => {
  const appStoreFromLocalStorage = retrieveAppStoreFromLocalStorage('persist:EKW');
  const {
    offre: { codeCanal = '', codeApporteur = '' },
  } = appStoreFromLocalStorage;
  return {
    [HeadersName.X_SELLER_ID]: xSellerId || codeApporteur,
    [HeadersName.X_SELLER_CHANNEL_ID]: codeCanal,
    [HeadersName.X_TENANT_ID]: REACT_APP_TENANT,
  };
};

export function* requestSOWithHeaders(method, ep, data = null, extraHeaders = null) {
  const { codeApporteur, codeCanal } = yield select(selectOffre);

  const newHeaders = {
    ...extraHeaders,
    [HeadersName.X_SELLER_ID]: codeApporteur,
    [HeadersName.X_SELLER_CHANNEL_ID]: codeCanal,
    [HeadersName.X_TENANT_ID]: REACT_APP_TENANT,
  };
  return yield requestSoNew(method, ep, data, newHeaders);
}

export async function requestSoNew(method, ep, data, extraHeaders = null): Promise<AxiosResponse> {
  const headers = { ...baseHeaders, ...extraHeaders };
  try {
    const rawData = await (method.includes('get')
      ? axios({
          method,
          url: `${REACT_APP_API_BASE_URL}/${ep}`,
          headers,
          params: data,
        })
      : axios({
          method,
          url: `${REACT_APP_API_BASE_URL}/${ep}`,
          headers,
          data: data ?? {},
        }));
    return rawData;
  } catch (error) {
    console.error('Something went wrong in requestSoNew() - api.helper', error);
    throw error;
  }
}

export const addQueryParams = (
  baseUrl: string,
  params: Record<string, string | number | undefined>,
): string => {
  const urlObject = new URL(baseUrl);
  Object.entries(params).forEach(([paramName, value]) => {
    if (value) {
      urlObject.searchParams.append(paramName, String(value));
    }
  });
  return urlObject.toString();
};
