import { isAValidPodInfoResponse } from './logic.helper';
import * as t from './types';
import * as c from './constants';

export const initialState: t.WithPodInfoReducerType = {
  elecInfo: {
    infoPdl: null,
    subscriptionType: null,
  },
  gasInfo: {
    infoPce: null,
    subscriptionType: null,
  },
  loading: false,
  error: null,
};

function PODInfoReducer(state = initialState, { type, payload }): t.WithPodInfoReducerType {
  switch (type) {
    case c.RESET_SUBSCRIPTION: {
      return initialState;
    }
    case c.CREATE_SUBSCRIPTION_TYPE_POD_REQUEST:
    case c.CREATE_SUBSCRIPTION_TYPE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case c.CREATE_SUBSCRIPTION_TYPE_POD_SUCCESS:
    case c.CREATE_SUBSCRIPTION_TYPE_ADDRESS_SUCCESS:
      return {
        ...state,
        elecInfo: isAValidPodInfoResponse(payload?.electricity)
          ? payload?.electricity
          : state?.elecInfo,
        gasInfo: isAValidPodInfoResponse(payload?.gas) ? payload?.gas : state?.gasInfo,
        loading: false,
      };
    case c.CREATE_SUBSCRIPTION_TYPE_POD_FAILURE:
    case c.CREATE_SUBSCRIPTION_TYPE_ADDRESS_FAILURE: {
      const response = payload?.data;
      const electricityCAR = response?.electricity?.infoPdl?.annualConsumptionReferenceBase;
      const gasCAR = response?.gas?.infoPce?.annualConsumptionReference;

      return {
        ...state,
        loading: false,
        elecInfo: electricityCAR ? response?.electricity : state?.elecInfo,
        gasInfo: gasCAR ? response?.gas : state?.gasInfo,
        error: payload,
      };
    }
    case c.CREATE_POD_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case c.CREATE_POD_INFO_SUCCESS:
      return {
        ...state,
        elecInfo: {
          ...state.elecInfo,
          infoPdl:  payload.infoPdl ? payload.infoPdl : state.elecInfo.infoPdl
        },
        gasInfo: {
          ...state.elecInfo,
          infoPce:  payload.infoPce ? payload.infoPce : state.gasInfo.infoPce
        },
        loading: false,
      }
    case c.CREATE_POD_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default PODInfoReducer;
