export const ERROR_ACTION = 'app/App/ERROR';
export const ERROR_REDUCER = 'ErrorReducer';
export const ERROR_SAGA = 'ErrorSaga';

export const GET_PCE_FAILED = 'GET_PCE_FAILED';
export const GET_PDL_FAILED = 'GET_PDL_FAILED';
export const FLUX_SERVICE_ERROR = 'FLUX_SERVICE_ERROR';
export const NO_COMPATIBLE_ACTIVE_OFFERS = 'NO_COMPATIBLE_ACTIVE_OFFERS';

export const ERROR_CODE = {
  API_ERROR: { code: 'errorHappened' },
  NO_POSTALCODE: { code: 'noPostalCode', headline: false },
  INVALID_OPTION: { code: 'INVALID_OPTION', headline: true, closable: true },
  NO_COMPATIBLE_ACTIVE_OFFERS: {
    code: NO_COMPATIBLE_ACTIVE_OFFERS,
    headline: false,
    closable: true,
  },
};
