import { createSelector } from 'reselect';
import { AppStateType } from 'src/store/appstate.type';
import { selectDevisNew } from 'src/hocs/WithDevis/selectors';
import { supplierInterventionIsCHF } from 'src/hocs/WithDevis/business.helper';
import { WITH_POD_INFO } from './constants';
import * as t from './types';

export const selectPODInfo = (state: AppStateType) => state?.[WITH_POD_INFO];

const selectPodInfoPath = createSelector(selectDevisNew, (devis) =>
  supplierInterventionIsCHF(devis?.supplierInterventionType)
    ? t.SubscriptionTypeKeysEnum.CHF
    : t.SubscriptionTypeKeysEnum.MES,
);

const selectIsElectricityIndexNeeded = createSelector(
  selectPODInfo,
  selectPodInfoPath,
  (state, path): boolean => state?.elecInfo?.subscriptionType?.[path]?.indexReelNecessaire,
);

const selectIsGasIndexNeeded = createSelector(
  selectPODInfo,
  selectPodInfoPath,
  (state, path): boolean => state?.gasInfo?.subscriptionType?.[path]?.indexReelNecessaire,
);

export const selectElecInfo = createSelector(
  selectPODInfo,
  (substate): t.ElectricityType => substate?.elecInfo,
);

export const selectGasInfo = createSelector(
  selectPODInfo,
  (substate): t.GasType => substate?.gasInfo,
);

export const makeSelectElecInfo = () => selectElecInfo;

export const makeSelectGasInfo = () => selectGasInfo;

export const makeSelectIsElectricityIndexNeeded = () => selectIsElectricityIndexNeeded;

export const makeSelectIsGasIndexNeeded = () => selectIsGasIndexNeeded;

export const makeSelectPODInfo = () => selectPODInfo;

const selectAddressFromPod = createSelector(
  selectPODInfo,
  (state): t.PodAddressType =>
    state?.elecInfo?.infoPdl?.adresse || state?.gasInfo?.infoPce?.adresse,
);

export const makeSelectAddressFromPod = () => selectAddressFromPod;
