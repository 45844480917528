import routes from 'src/utils/routes/routes';
import { STATUS } from 'src/hocs/WithSubscription/constants';
import { CurrencyEnum } from 'src/hocs/WithBilling/types';
import { EnergyEnum } from 'src/hocs/App/types';
import get from 'lodash/get';
import { getTaxMode } from 'src/hocs/App/business.helper';
import { EcommerceItemsType } from 'src/utils/gtm/gtm.types';
import { GAS_OPTIONS } from 'src/components/pages/ServicesOptions/constants';
import C, { GTMEventTypes } from './constants';

export const getFunnelInformations = (pathName: string) => {
  let funnelType = STATUS.IS_QUOTATION;
  let stepNumber = C.STEPS.DEFAULT;
  let eventName = '';
  // eslint-disable-next-line default-case
  switch (pathName) {
    case routes.souscrire:
      stepNumber = C.STEPS.DEVIS_HOME;
      funnelType = STATUS.IS_QUOTATION;
      eventName = GTMEventTypes.QUOTATION_HOME;
      break;
    case routes.choixEnergie:
      stepNumber = C.STEPS.DEVIS_CHOIX_ENERGIE;
      funnelType = STATUS.IS_QUOTATION;
      eventName = GTMEventTypes.QUOTATION_ENERGY_CHOICE;
      break;
    case routes.devis:
      stepNumber = C.STEPS.DEVIS_DEVIS;
      funnelType = STATUS.IS_QUOTATION;
      eventName = GTMEventTypes.QUOTATION_SUMMARY;
      break;
    case routes.serviceOption:
      funnelType = STATUS.IS_SERVICES_OPTIONS;
      stepNumber = C.STEPS.SOUSCRIRE_SERVICE_OPTION;
      eventName = GTMEventTypes.SUBSCRIPTION_OPTION_CHOICE;
      break;
    case routes.contrat:
      funnelType = STATUS.IS_PROFILE;
      stepNumber = C.STEPS.SOUSCRIRE_CONTRAT;
      eventName = GTMEventTypes.SUBSCRIPTION_ADD_CONTACT_DETAILS;
      break;
    case routes.myDelivery:
      funnelType = STATUS.IS_DELIVERY;
      stepNumber = C.STEPS.SOUSCRIRE_MY_DELIVERY;
      eventName = GTMEventTypes.SUBSCRIPTION_SERVICE_CHARGE;
      break;
    case routes.livraisonPod:
      funnelType = STATUS.IS_WAITING_FOR_POD;
      stepNumber = C.STEPS.SOUSCRIRE_LIVRAISON_POD;
      eventName = GTMEventTypes.SUBSCRIPTION_SERVICE_CHARGE;
      break;
    case routes.modeFacturation:
      funnelType = STATUS.IS_BILLING_METHOD;
      stepNumber = C.STEPS.SOUSCRIRE_MODE_FACTURATION;
      eventName = GTMEventTypes.SUBSCRIPTION_PAYMENT_MODE;
      break;
    case routes.summary:
      funnelType = STATUS.IS_SIGNED;
      stepNumber = C.STEPS.SOUSCRIRE_SUMMARY;
      eventName = GTMEventTypes.SUBSCRIPTION_SUMMARY;
      break;
    case routes.paiement:
      funnelType = STATUS.IS_PAYMENT;
      stepNumber = C.STEPS.SOUSCRIRE_PAIEMENT;
      eventName = GTMEventTypes.SUBSCRIPTION_ADD_PAYMENT_INFO;
      break;
    case routes.paiementSuccess:
      funnelType = STATUS.IS_PAYMENT;
      stepNumber = C.STEPS.SOUSCRIRE_PAIEMENT_SUCCESS;
      eventName = GTMEventTypes.SUBSCRIPTION_PAYMENT_SUCCESS;
      break;
  }
  return {
    funnelType,
    stepNumber,
    eventName,
  };
};

export const getEcommerceEnergyItems = ({ isProfessional, quotation }) => {
  const promoCode = quotation?.promoCode ?? '';
  const electricityAnnualAmountInclVAT = quotation?.electricityAnnualAmountInclVAT ?? 0;
  const gasAnnualAmount = quotation?.gasAnnualAmount ?? 0;
  const electricityMensuality = quotation?.electricityMensuality ?? 0;
  const gasMensuality = quotation?.gasMensuality ?? 0;
  const energy = quotation?.energy ?? '';

  const taxMode = getTaxMode(isProfessional);
  const createEcommerceItem = (itemName, unitPrice): EcommerceItemsType => {
    const loweredItemName = itemName.toLowerCase();
    return {
      advantagesCode: promoCode || '',
      currency: CurrencyEnum.EUR,
      itemName,
      itemCategory: C.BUSINESS.CATEGORY_ENERGIES,
      itemQuantity: 1,
      itemId: get(quotation, `${loweredItemName}ProductName`),
      itemConsumptionBasePrice: get(
        quotation,
        `${loweredItemName}ConsumptionBasePrice${taxMode}Taxes`,
      ),
      itemSubscriptionPrice: get(quotation, `${loweredItemName}SubscriptionPrice${taxMode}Taxes`),
      unitPrice,
      price: get(quotation, `${loweredItemName}AnnualAmount${taxMode}VAT`),
      priceType: get(quotation, `${loweredItemName}ProductItemType`),
    };
  };

  const electricityItem = createEcommerceItem(
    EnergyEnum.ELECTRICITY,
    electricityAnnualAmountInclVAT,
  );
  const gasItem = createEcommerceItem(EnergyEnum.GAS, gasAnnualAmount);

  switch (energy) {
    case EnergyEnum.DUAL:
      return {
        subscriptionMonthlyPayment: electricityMensuality + gasMensuality,
        items: [electricityItem, gasItem],
      };
    case EnergyEnum.GAS:
      return {
        subscriptionMonthlyPayment: gasMensuality,
        items: [gasItem],
      };
    case EnergyEnum.ELECTRICITY:
      return {
        subscriptionMonthlyPayment: electricityMensuality,
        items: [electricityItem],
      };
    default:
      return {
        subscriptionMonthlyPayment: 0,
        items: [],
      };
  }
};

export const getEcommerceOptionsAndServicesItems = (options = [], services = [], isProfessional) =>
  [...options, ...services].map((option) => transformOptionsAndServices(option, isProfessional));

export const transformOptionsAndServices = (option, isProfessional): EcommerceItemsType => {
  const {
    id,
    title,
    annualAmountInclVAT,
    annualAmountExclVAT,
    unitPriceInclVAT,
    unitPriceExclVAT,
  } = option;
  const price = isProfessional ? annualAmountExclVAT : annualAmountInclVAT;
  const itemConsumptionBasePrice = isProfessional ? unitPriceExclVAT : unitPriceInclVAT;
  const optionMap = {
    [C.BUSINESS.CODE_GAZ_BIO]: {
      itemName: C.BUSINESS.NAME_GAZ_BIO,
      itemCategory: C.BUSINESS.CATEGORY_OPTION,
      itemConsumptionBasePrice,
    },
    [C.BUSINESS.CODE_SMALL_PRODUCERS]: {
      itemName: C.BUSINESS.NAME_SMALL_PRODUCERS,
      itemCategory: C.BUSINESS.CATEGORY_OPTION,
      itemConsumptionBasePrice,
    },
    [C.BUSINESS.CODE_HEATER_REPAIR]: {
      itemName: C.BUSINESS.NAME_HEATER_REPAIR,
      itemCategory: C.BUSINESS.CATEGORY_SERVICE,
    },
    [C.BUSINESS.CODE_SUPPORT_REPAIR]: {
      itemName: C.BUSINESS.NAME_SUPPORT_REPAIR,
      itemCategory: C.BUSINESS.CATEGORY_SERVICE,
    },
    [C.BUSINESS.CODE_DIFFUSE_FADING]: {
      itemName: C.BUSINESS.NAME_DIFFUSE_FADING,
      itemCategory: C.BUSINESS.CATEGORY_SERVICE,
    },
  };

  const foundKey = Object.keys(optionMap).find((key) => id.includes(key));
  if (foundKey) {
    return {
      advantagesCode: '',
      currency: CurrencyEnum.EUR,
      ...optionMap[foundKey],
      itemId: id,
      itemQuantity: 1,
      ...(id !== GAS_OPTIONS.FULLY_RENEWABLE_OPTION ? { variant: title } : {}),
      price,
    };
  }
  return null;
};

const createEcommerceField = (quotation, isProfessional) => {
  const ecommerceEnergyItems = getEcommerceEnergyItems({
    quotation,
    isProfessional,
  });

  const ecommerceOptionsAndServicesItems = getEcommerceOptionsAndServicesItems(
    quotation?.options,
    quotation?.services,
    isProfessional,
  );

  return {
    ecommerce: {
      billAmountIncludingTaxes: quotation?.totalAnnualAmountInclVAT,
      items: [...ecommerceEnergyItems.items, ...ecommerceOptionsAndServicesItems],
    },
    subscriptionMonthlyPayment: ecommerceEnergyItems?.subscriptionMonthlyPayment,
  };
};

export const createCommonDataLayerFields = ({ quotation, subscription, isProfessional }) => {
  const ecommerceField = createEcommerceField(quotation, isProfessional);
  const billingConfiguration = get(subscription, 'invoiceFrequency');
  const supplierInterventionType = quotation?.supplierInterventionType ?? '';
  const quoteId = quotation?.id ?? '';
  const { ecommerce, subscriptionMonthlyPayment } = ecommerceField;
  return {
    billingConfiguration,
    subscriptionReason: supplierInterventionType,
    quoteId,
    ecommerce,
    subscriptionMonthlyPayment,
  };
};
