import { CartActionType, CartReducerType } from 'src/components/organisms/Cart/types';
import { SET_CART_LOADING } from './constants';

export const initialState: CartReducerType = {
  isLoading: false,
};

function CartReducer(
  state: CartReducerType = initialState,
  action: CartActionType,
) {
  switch (action.type) {
    case SET_CART_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export default CartReducer;
