import { typedDefineMessage } from 'src/components/utils/LanguageProvider/intl.helpers';


export default typedDefineMessage({
  catchPhrase: {
    id: 'app.footer.catchPhrase',
    defaultMessage: 'L’énergie est notre avenir, économisons-la !',
  },
  copyright: {
    id: 'app.footer.copyright',
    defaultMessage: '©{currentYear} - {tenantName}',
  },
  faq: {
    id: 'app.footer.faq',
    defaultMessage: 'FAQ',
  },
  contact: {
    id: 'app.footer.contact',
    defaultMessage: 'Contact',
  },
  recruitment: {
    id: 'app.footer.recruitment',
    defaultMessage: 'Recrutement',
  },
  generalSellingConditions: {
    id: 'app.footer.generalSellingConditions',
    defaultMessage: 'CGV',
  },
  cookies: {
    id: 'app.footer.cookies',
    defaultMessage: 'Cookies',
  },
  politic: {
    id: 'app.footer.politic',
    defaultMessage: 'Politique de confidentialité',
  },
  mentions: {
    id: 'app.footer.mentions',
    defaultMessage: 'Mentions légales',
  },
  credits: {
    id: 'app.footer.credits',
    defaultMessage: 'Crédits',
  },
  medias: {
    id: 'app.footer.medias',
    defaultMessage: 'Presse',
  },
});
