import { makeSelectLocation } from 'src/hocs/App/selectors';
import {
  HousingEstimatorEvent,
  UsageEstimatorEvent,
  EmailClick,
  EnergyChoiceFormParams,
  OccupantNameConsumption,
  PageChange,
  PageView,
  PhoneClick,
  QuotationHelpClick,
  QuotationKnowConsumption,
  ResumeSubscriptionEvent,
  SaveProgressionEvent,
  SubmitHomepageFormParams,
  SubmitQuotationPOD,
  SubmitSubscriptionPOD,
  SubmitQuotationSummary,
  SubscriptionStep,
  MeterEstimatorEvent,
} from 'src/utils/gtm/events.types';
import C, { GTMEventTypes } from 'src/utils/gtm/constants';
import { makeSelectGTMQuotation } from './selectors';
import { addEvent } from './gtm';
import {
  pageViewFormatter,
  subscriptionStepFormatter,
  saveProgressionEventFormatter,
  resumeProgressionEventFormatter,
  submitEnergyChoicePageEventFormatter,
  submitHomepageEventFormatter,
  housingEstimatorEventFormatter,
  usageEstimatorEventFormatter,
  submitQuotationEventFormatter,
  quotationHelpClickFormatter,
  submitQuotationPODFormatter,
  quotationKnowConsumptionFormatter,
  occupantNameConsumptionFormatter,
  submitSubscriptionPODFormatter,
  meterEstimatorEventFormatter,
} from './eventFormatter';
import { getFunnelInformations } from './logic.helper';

export const housingEstimatorEvent = ({
  housingSeniority,
  insulationYear,
  hasInsulationWork,
  peopleCount,
  surface,
  housingType,
  customerType,
  businessPart,
}: HousingEstimatorEvent) => {
  addEvent(
    housingEstimatorEventFormatter({
      housingSeniority,
      insulationYear,
      hasInsulationWork,
      peopleCount,
      surface,
      housingType,
      customerType,
      businessPart,
    }),
  );
};

export const usageEstimatorEvent = ({
  alternativeHeatingType,
  electricHeatingType,
  houseHeatingType,
  housingCooking,
  heatingTemperature,
  waterHeatingType,
  customerType,
  businessPart,
}: UsageEstimatorEvent) => {
  addEvent(
    usageEstimatorEventFormatter({
      alternativeHeatingType,
      electricHeatingType,
      houseHeatingType,
      housingCooking,
      heatingTemperature,
      waterHeatingType,
      customerType,
      businessPart,
    }),
  );
};

export const meterEstimatorEvent = ({
  customerType,
  businessPart,
  subscribedPower,
  tariffOption,
  airConditioningType,
  hasElectricVehicle,
  electricVehicleCharging,
  hasPools,
  poolHeatingType,
}: MeterEstimatorEvent) => {
  addEvent(
    meterEstimatorEventFormatter({
      customerType,
      businessPart,
      subscribedPower,
      tariffOption,
      airConditioningType,
      hasElectricVehicle,
      electricVehicleCharging,
      hasPools,
      poolHeatingType,
    }),
  );
};

export const submitHomepageForm = ({
  estimatedProjectDate,
  subscriptionReason,
  advantagesCode,
  isProfessional,
  customerType,
  businessPart,
}: SubmitHomepageFormParams) => {
  if (!isProfessional) {
    addEvent(
      submitHomepageEventFormatter({
        estimatedProjectDate,
        subscriptionReason,
        advantagesCode,
        customerType,
        businessPart,
      }),
    );
  }
};

export const submitEnergyChoiceForm = ({
  subscriptionReason,
  advantagesCode,
  itemName,
  isProfessional,
  customerType,
  businessPart,
}: EnergyChoiceFormParams) => {
  if (!isProfessional) {
    addEvent(
      submitEnergyChoicePageEventFormatter({
        subscriptionReason,
        advantagesCode,
        itemName,
        customerType,
        businessPart,
      }),
    );
  }
};

export const saveProgression = ({
  userEmail,
  isProfessional,
  customerType,
  businessPart,
}: SaveProgressionEvent) => {
  if (!isProfessional) {
    addEvent(saveProgressionEventFormatter(userEmail, customerType, businessPart));
  }
};

export const resumeSubscription = ({
  subscriptionId,
  quoteId,
  isProfessional,
  funnelInformations,
  customerType,
  businessPart,
}: ResumeSubscriptionEvent) => {
  if (!isProfessional) {
    addEvent(
      resumeProgressionEventFormatter({
        subscriptionId,
        quoteId,
        funnelInformations,
        customerType,
        businessPart,
      }),
    );
  }
};

export const submitQuotationSummary = ({
  state,
  isProfessional,
  customerType,
  businessPart,
  userEmail,
  userPhone,
}: SubmitQuotationSummary) => {
  if (!isProfessional) {
    const { pathname } = makeSelectLocation()(state);
    const { eventName } = getFunnelInformations(pathname);
    const selectedQuotation = makeSelectGTMQuotation()(state);

    addEvent(
      submitQuotationEventFormatter(
        eventName,
        selectedQuotation,
        customerType,
        businessPart,
        userEmail,
        userPhone,
      ),
    );
  }
};

export const quotationHelpClick = ({
  textValue,
  isProfessional,
  customerType,
  businessPart,
}: QuotationHelpClick) => {
  if (!isProfessional) {
    addEvent(quotationHelpClickFormatter(textValue, customerType, businessPart));
  }
};

export const submitSubscriptionPOD = ({
  subscriptionId,
  isProfessional,
  customerType,
  businessPart,
}: SubmitSubscriptionPOD) => {
  if (!isProfessional) {
    addEvent(submitSubscriptionPODFormatter(subscriptionId, customerType, businessPart));
  }
};

export const submitQuotationPOD = ({
  state,
  isProfessional,
  customerType,
  businessPart,
}: SubmitQuotationPOD) => {
  if (!isProfessional) {
    const selectedQuotation = makeSelectGTMQuotation()(state);

    addEvent(submitQuotationPODFormatter(selectedQuotation, customerType, businessPart));
  }
};

export const quotationKnowConsumption = ({
  isProfessional,
  customerType,
  businessPart,
}: QuotationKnowConsumption) => {
  if (!isProfessional) {
    addEvent(quotationKnowConsumptionFormatter(customerType, businessPart));
  }
};

export const occupantNameConsumption = ({
  subscriptionId,
  isProfessional,
  customerType,
  businessPart,
}: OccupantNameConsumption) => {
  if (!isProfessional) {
    addEvent(occupantNameConsumptionFormatter(subscriptionId, customerType, businessPart));
  }
};

export const phoneClick = ({ isProfessional, customerType, businessPart }: PhoneClick) => {
  if (!isProfessional) {
    addEvent({
      event: GTMEventTypes.SUBSCRIPTION_CONTACT_PHONE,
      environment: C.ENVIRONMENT_NAME,
      customerType,
      businessPart,
    });
  }
};

export const emailClick = ({ isProfessional, customerType, businessPart }: EmailClick) => {
  if (!isProfessional) {
    addEvent({
      event: GTMEventTypes.SUBSCRIPTION_CONTACT_MAIL,
      environment: C.ENVIRONMENT_NAME,
      customerType,
      businessPart,
    });
  }
};

// Todo: check with data team if pageview and page are not redundant
export const pageView = ({ pathName, customerType, businessPart }: PageView) => {
  const pageViewEvent = pageViewFormatter(pathName, customerType, businessPart);
  addEvent(pageViewEvent);
};

export const subscriptionStep = ({ pathName, customerType, businessPart }: SubscriptionStep) => {
  const { funnelType, stepNumber } = getFunnelInformations(pathName);
  const subscriptionStepEvent = subscriptionStepFormatter(
    funnelType,
    stepNumber,
    customerType,
    businessPart,
  );
  addEvent(subscriptionStepEvent);
};

export const pageChange = ({ state, customerType, businessPart }: PageChange) => {
  const { pathname: pathName } = makeSelectLocation()(state);
  pageView({
    pathName,
    customerType,
    businessPart,
  });
  subscriptionStep({
    pathName,
    customerType,
    businessPart,
  });
};
