export const spacing = {
  none: '0',
  '3xs': '2px',
  '2xs': '4px',
  xs: '8px',
  s: '12px',
  m: '16px',
  l: '24px',
  xl: '32px',
  '2xl': '40px',
  '3xl': '56px',
  '4xl': '64px',
} as const;
