import { useStore } from 'react-redux';
import { selectOffre } from 'src/hocs/App/selectors';
import AppConfig from 'src/config/config';
import { HeadersName } from 'src/utils/api/api.constants';

const { REACT_APP_TENANT } = AppConfig;

// eslint-disable-next-line @typescript-eslint/ban-types
export const useRequestHeaders: () => object = () => {
  const store = useStore();
  const state = store.getState();
  const { codeApporteur, codeCanal } = selectOffre(state);

  return {
    [HeadersName.X_SELLER_ID]: codeApporteur,
    [HeadersName.X_SELLER_CHANNEL_ID]: codeCanal,
    [HeadersName.X_TENANT_ID]: REACT_APP_TENANT,
  };
};
