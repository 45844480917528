import styled from '@emotion/styled/macro';

const SharedGrid = styled.div<{ xs?: number; tp?: number; md?: number; lg?: number }>`
  ${({ theme }) => theme.breakpoints.small} {
    max-width: calc(((${(props) => props.xs}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    max-width: calc(((${(props) => props.tp}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
  }
  ${({ theme }) => theme.breakpoints.medium} {
    max-width: calc(((${(props) => props.md}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
  }
  ${({ theme }) => theme.breakpoints.large} {
    max-width: calc(((${(props) => props.lg}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
  }
`;

export default SharedGrid;
