import { useIntl, defineMessages } from 'react-intl';
import {
  MessagesType,
  TranslationKeys,
  CustomMessageDescriptor,
  MessageOptions,
  MessageOptionsElement,
} from 'src/components/utils/LanguageProvider/intl.types';

export const typedDefineMessage = (messages: MessagesType) => defineMessages(messages);

export const getTranslation = (key: TranslationKeys): CustomMessageDescriptor => {
  const translation = { id: key };
  return translation;
};

interface UseIntlMessage<TBase = unknown> {
  intlMessage(key: TranslationKeys, values?: MessageOptions): string;
  intlMessage<T extends TBase>(
    key: TranslationKeys,
    values?: MessageOptionsElement<T>,
  ): string | T | (T | string)[];
}
export const useIntlMessage = (): UseIntlMessage => {
  const { formatMessage } = useIntl();

  const intlMessage = (key, values) => formatMessage(getTranslation(key), values);
  return { intlMessage };
};
