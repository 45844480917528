import carneroRegular from '../../assets/fonts/Carnero/Carnero-Regular.woff2';
import carneroBold from '../../assets/fonts/Carnero/Carnero-Bold.woff2';
import gothicBold from '../../assets/fonts/GothicA1/GothicA1-Bold.woff2';
import gothicSemibold from '../../assets/fonts/GothicA1/GothicA1-SemiBold.woff2';
import gothicRegular from '../../assets/fonts/GothicA1/GothicA1-Regular.woff2';

export const fontFace = {
  format: 'woff2',
  family: {
    heading: 'Carnero',
    body: 'Gothic',
    cta: 'Carnero',
  },
  urls: {
    headingRegular: carneroRegular,
    headingBold: carneroBold,
    bodyBold: gothicBold,
    bodySemibold: gothicSemibold,
    bodyRegular: gothicRegular,
  },
};
