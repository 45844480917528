import { AxiosResponse } from 'axios';
import routes from 'src/utils/routes/routes';
import { dictionary } from 'src/utils/errorManager/dictionary';
import { ErrorHtmlKeys, ErrorHtmlKeysType } from 'src/utils/errorManager/errors';
import {
  ErrorDefinitionType,
  ApiErrorMessagesEnum,
  OriginsEnum,
  HttpServerErrorCode,
  HttpServerErrorCodeType,
} from './types';

const { REACT_APP_HIGH_TRAFFIC_URL = '', REACT_APP_HOME_URL = '' } = process.env;

export const findOriginByUrl = (): OriginsEnum => {
  const path: string = window.location.pathname;
  if (RegExp(`${routes.souscrire}$`).test(path)) {
    return OriginsEnum.HOME_PAGE;
  }
  if (path.match(`${routes.livraisonPod}`)) {
    return OriginsEnum.LIVRAISON_POD;
  }
  if (path.match(`${routes.myDelivery}`)) {
    return OriginsEnum.MY_DELIVERY;
  }
  if (path.match(`${routes.modeFacturation}`)) {
    return OriginsEnum.MODE_FACTURATION;
  }
  if (path.match(`${routes.choixEnergie}`)) {
    return OriginsEnum.CHOIX_ENERGIE;
  }
  if (path.match(`${routes.devis}`)) {
    return OriginsEnum.QUOTATION;
  }
  if (path.match(`${routes.serviceOption}`)) {
    return OriginsEnum.SERVICES_OPTIONS;
  }
  if (path.match(`${routes.paiementSuccess}`)) {
    return OriginsEnum.PAIEMENT_SUCCESS;
  }
  if (path.match(`${routes.nonEligible}`)) {
    return OriginsEnum.NON_ELIGIBLE;
  }
  return OriginsEnum.UNKNOWN;
};

// Todo: create a function to detect origin automatically
export const matchErrorByOrigin = (
  errorMsg: ApiErrorMessagesEnum,
  fixOrigin?: OriginsEnum,
): ErrorDefinitionType => {
  const origin = fixOrigin || findOriginByUrl();
  const originDictionary = dictionary?.[origin] || dictionary?.[OriginsEnum.UNKNOWN];
  return (
    originDictionary?.[errorMsg] ||
    originDictionary?.[ApiErrorMessagesEnum.UNKNOWN] ||
    dictionary?.[OriginsEnum.UNKNOWN]?.[errorMsg] ||
    dictionary?.[OriginsEnum.UNKNOWN]?.[ApiErrorMessagesEnum.UNKNOWN]
  );
};

export function isFluxServiceError(errorMessage: ApiErrorMessagesEnum): boolean {
  return [
    ApiErrorMessagesEnum.FLUX_SERVICE_ERROR,
    ApiErrorMessagesEnum.SERVICE_PDL_UNAVAILABLE,
    ApiErrorMessagesEnum.SERVICE_PCE_UNAVAILABLE,
  ].includes(errorMessage);
}

// Function that catch server error type and do something with it
export const checkServerErrorType = (errorStatus: number): string | null => {
  const highTrafficUrl = `${REACT_APP_HOME_URL}${REACT_APP_HIGH_TRAFFIC_URL}`;
  switch (errorStatus) {
    // 504: if we got high traffic after an event (tv show, ad campaign, etc.), if API-SO is down we redirect to ewkateur.fr website
    case HttpServerErrorCode.GATEWAY_TIMEOUT: {
      window.location.href = highTrafficUrl;
      break;
    }
    default:
      return null;
  }
  return null;
};

interface FakedErrorResponseParamsType {
  error: ErrorHtmlKeysType;
  codeError: HttpServerErrorCodeType;
}

export const fakedErrorResponse = ({
  error,
  codeError,
}: FakedErrorResponseParamsType): AxiosResponse => ({
  data: {
    code: codeError,
    message: ErrorHtmlKeys[error],
  },
  status: codeError,
  statusText: '',
  headers: {},
  // @ts-ignore
  config: {},
  request: {},
});
