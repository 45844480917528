export const customComponents = {
  borderWidth: 'strong',
  radius: {
    button: 'full',
    calendarContainer: 'weakest',
    card: 'weaker',
    dropdown: {
      menu: 'strong',
      option: 'weakest',
    },
    inputText: 'stronger',
    inputCheckbox: 'weakest',
    inputRadio: 'weak',
    inputRadioButton: 'weak',
    modal: 'weak',
    stepper: {
      list: 'stronger',
      listItem: 'full',
    },
    tooltip: 'weak',
  },
  inputSpacing: 'l',
} as const;
