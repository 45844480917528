import { createSelector } from 'reselect';
import get from 'lodash/get';

import { AppStateType } from 'src/store/appstate.type';
import { isProfessional } from 'src/hocs/App/business.helper';
import { EnergyEnum } from 'src/hocs/App/types';

import { FAMILY_SERVICES } from 'src/hocs/WithServices/constants';
import { ServiceType } from 'src/hocs/WithServices/types';
import { CustomerTypeEnum } from 'src/hocs/WithUserInformations/types';
import { ELECTRICITY, GAZ, WITH_DEVIS } from './constants';
import { QuotationEnhancedType, QuotationResponseType } from './types';

const selectDevis = (state: AppStateType) => state?.[WITH_DEVIS];

/**
 * Takes a devis and returns a dictionary of computed prices depending on whether user is
 * professional or not
 */
const getAmountsDependingOnCustomerType = (
  devis: QuotationResponseType,
  isProfessionalCustomer: boolean,
): Partial<QuotationEnhancedType> => {
  if (isProfessionalCustomer) {
    return {
      totalAnnualAmountWithServices: devis.totalAnnualAmountWithServicesExclVAT,
      totalAnnualAmountSavedWithServicesExclVAT: devis.totalAnnualAmountSavedWithServicesExclVAT,
      totalAnnualAmountSaved: devis.totalAnnualAmountSavedExclVAT,
      electricityAnnualAmount: devis.electricityAnnualAmountExclVAT,
      gasAnnualAmount: devis.gasAnnualAmountExclVAT,
      totalAnnualAmountServices: devis.totalAnnualAmountServicesExclVAT,
      electricitySubscriptionPrice: devis.electricitySubscriptionPriceExclTaxes,
      electricityConsumptionBasePrice: devis.electricityConsumptionBasePriceExclTaxes,
      electricityConsumptionLowPrice: devis.electricityConsumptionLowPriceExclTaxes,
      gasConsumptionBasePrice: devis.gasConsumptionBasePriceExclTaxes,
      gasSubscriptionPrice: devis.gasSubscriptionPriceExclTaxes,
    };
  }

  return {
    totalAnnualAmountWithServices: devis.totalAnnualAmountWithServicesInclVAT,
    totalAnnualAmountSavedWithServicesInclVAT: devis.totalAnnualAmountSavedWithServicesInclVAT,
    totalAnnualAmountSaved: devis.totalAnnualAmountSavedInclVAT,
    electricityAnnualAmount: devis.electricityAnnualAmountInclVAT,
    gasAnnualAmount: devis.gasAnnualAmountInclVAT,
    totalAnnualAmountServices: devis.totalAnnualAmountServicesInclVAT,

    electricitySubscriptionPrice: devis.electricitySubscriptionPriceInclTaxes,
    electricityConsumptionBasePrice: devis.electricityConsumptionBasePriceInclTaxes,
    electricityConsumptionLowPrice: devis.electricityConsumptionLowPriceInclTaxes,
    gasConsumptionBasePrice: devis.gasConsumptionBasePriceInclTaxes,
    gasSubscriptionPrice: devis.gasSubscriptionPriceInclTaxes,
  };
};

/**
 * Takes a devis and returns a list of options with their computed amount depending
 * on whether user is professional or not
 */
const getOptionsAmountForDevis = (
  devis: QuotationResponseType | null | undefined,
  isProfessionalCustomer: boolean,
): Array<QuotationResponseType['options']> => {
  if (!devis || !devis.options) {
    return [];
  }

  return devis.options.map((option) => ({
    ...option,
    annualAmount: isProfessionalCustomer ? option.annualAmountExclVAT : option.annualAmountInclVAT,
  }));
};

export type CurrentQuotationEnhancedType = QuotationResponseType &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Partial<QuotationEnhancedType> & { options: any };

export const selectCurrentDevis = createSelector(
  selectDevis,
  (substate): CurrentQuotationEnhancedType | null => {
    if (!substate.devis) return null;

    const { devis } = substate;
    const isProfessionalCustomer = isProfessional(devis.customerType);
    const computedAmounts: Partial<QuotationEnhancedType> = getAmountsDependingOnCustomerType(
      devis,
      isProfessionalCustomer,
    );
    const options = getOptionsAmountForDevis(devis, isProfessionalCustomer);

    return {
      ...devis,
      ...computedAmounts,
      ...(options.length > 0 && { options }),
    };
  },
);

export const makeSelectDevis = () => selectCurrentDevis;
export const selectDevisNew = createSelector(selectDevis, (subState) => subState.devis);
export const makeSelectDevisNew = () => selectDevisNew;

export const selectDevisServices = createSelector(selectDevisNew, (substate) =>
  get(substate, 'services', []),
);

const selectReductionServices = createSelector(
  selectDevisServices,
  (subState): Array<ServiceType> =>
    subState.filter((item) => item.familleservices === FAMILY_SERVICES.REDUCTION),
);

export const makeSelectReductionServices = () => selectReductionServices;

const selectAssuranceServices = createSelector(
  selectDevisServices,
  (subState): Array<ServiceType> =>
    subState.filter((item) => item.familleservices === FAMILY_SERVICES.ASSURANCE),
);

export const makeSelectAssuranceServices = () => selectAssuranceServices;

const selectDevisServicesIds = createSelector(
  selectDevisServices,
  (subState): Array<string> => subState.reduce((acc, c) => acc.concat(c.id), []),
);

export const makeSelectDevisServicesIds = () => selectDevisServicesIds;

const selectMensuality = createSelector(selectDevis, (substate) => {
  if (!substate.devis) return null;
  const isProfessionalCustomer = substate.devis.customerType === CustomerTypeEnum.PROFESSIONAL;
  const priceType = isProfessionalCustomer ? 'ht' : 'ttc';
  const mens = substate.devis.mensuality[priceType];
  let res = mens[ELECTRICITY] || mens[GAZ];
  if (mens[ELECTRICITY] && mens[GAZ]) {
    res = mens[ELECTRICITY].map((m, i) => m + mens[GAZ][i]);
  }
  return res;
});

export const makeSelectMensuality = () => selectMensuality;

export const makeSelectLoading = () => createSelector(selectDevis, (substate) => substate.loading);

export const makeSelectEnergy = () =>
  createSelector(selectDevis, (substate) => substate?.devis?.energy);

export const selectMensualityIndexFromDevis = () =>
  createSelector(selectDevis, selectMensuality, ({ devis }, mensualities) => {
    const totalMensuality = get(devis, 'electricityMensuality', 0) + get(devis, 'gasMensuality', 0);
    const mensualityIndex = mensualities?.indexOf(totalMensuality);
    if (mensualityIndex === -1) {
      return Math.floor(mensualities.length / 2);
    }
    return mensualityIndex;
  });

const selectAnnualMonthlyFee = createSelector(
  selectCurrentDevis,
  (subState: QuotationEnhancedType): number => {
    const { energy } = subState;
    const electricityAnnualMonthlyFee = subState.mensuality.annualMonthlyFee.ttc.ELECTRICITY;
    const gasAnnualMonthlyFee = subState.mensuality.annualMonthlyFee.ttc.GAS;
    let sum;
    switch (energy) {
      case EnergyEnum.DUAL:
        sum = electricityAnnualMonthlyFee + gasAnnualMonthlyFee;
        break;
      case EnergyEnum.ELECTRICITY:
        sum = electricityAnnualMonthlyFee;
        break;
      case EnergyEnum.GAS:
        sum = gasAnnualMonthlyFee;
        break;
      default:
        sum = 0;
    }
    return sum;
  },
);

export const makeSelectAnnualMonthlyFee = () => selectAnnualMonthlyFee;

const selectMensualityIndex = createSelector(
  selectDevis,
  (substate) => substate.mensualityIndex || null,
);

export const makeSelectMensualityIndex = () => selectMensualityIndex;
