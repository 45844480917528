import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type {
  ProductInformation,
  ProductsInformationResponse,
  ProductsInformationReducer,
  ProductsDataByEnergy,
  ApiErrorResponse,
} from './products-information.types';
import { PRODUCTS_INFORMATION } from './products-information.constants';
import { getProductsInformation } from './products-information.services';

export const initialState: ProductsInformationReducer = {
  electricity: null,
  gas: null,
  isLoading: false,
  error: null,
};

const productsInformationSlice = createSlice({
  name: PRODUCTS_INFORMATION,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsInformation.pending, (state: ProductsInformationReducer) => {
        state.isLoading = true;
        state.error = null;
        state.electricity = null;
        state.gas = null;
      })
      .addCase(
        getProductsInformation.fulfilled,
        (
          state: ProductsInformationReducer,
          action: PayloadAction<{ data: ProductsInformationResponse }>,
        ) => {
          const { productsInformation } = action.payload.data;

          const result: ProductsDataByEnergy = productsInformation.reduce(
            (accumulator: ProductsDataByEnergy, currentProduct: ProductInformation) => {
              const { energy, duration, itemType, name, renewablePercent } = currentProduct;
              const lowerCasedEnergy = energy.toLowerCase();
              const lowerCasedItemType = itemType.toLowerCase();
              const updatedAccumulator = { ...accumulator };

              if (!updatedAccumulator[lowerCasedEnergy]) {
                updatedAccumulator[lowerCasedEnergy] = {};
              }
              if (!updatedAccumulator[lowerCasedEnergy].name) {
                updatedAccumulator[lowerCasedEnergy] = { name };
              }
              updatedAccumulator[lowerCasedEnergy][lowerCasedItemType] = {
                duration,
                renewablePercent,
              };
              return updatedAccumulator;
            },
            { electricity: null, gas: null },
          );

          state.isLoading = false;
          state.electricity = result.electricity;
          state.gas = result.gas;
        },
      )
      .addCase(getProductsInformation.rejected, (state: ProductsInformationReducer, action) => {
        state.isLoading = false;
        state.error = action.payload as ApiErrorResponse;
      });
  },
});

export default productsInformationSlice.reducer;
