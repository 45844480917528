import { createSelector } from 'reselect';

import { AppStateType } from 'src/store/appstate.type';

import { WITHADDRESS } from './constants';
import * as t from './types';

/**
 * Direct selector to the withAddress state domain
 */
const selectWithAddressDomain = (state: AppStateType) => state?.[WITHADDRESS];

/**
 * Other specific selectors
 */
export const selectWithAddress = createSelector(
  selectWithAddressDomain,
  (substate): t.CurrentAddressType => substate?.currentAddress,
);

/**
 * Default selector used by WithAddress
 */

export const makeSelectWithAddress = () => selectWithAddress;

export const makeSelectIsFullAddress = () => selectIsFullAddress;

/**
 * Returns whether the user has entered a full address or not
 */
const selectIsFullAddress = createSelector(
  selectWithAddressDomain,
  (substate): boolean => !!substate?.currentAddress?.streetName,
);
