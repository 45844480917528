import {
  SAVE_INFO_SERVICES_OPTIONS,
  REQUEST_INFO_ELEC_CENTRAL_SUCCESS,
  UPDATE_INFO_SERVICES_OPTIONS,
  SET_CENTRAL_VALIDATION,
  GET_CLOSEST_POWER_STATION,
  SWITCH_ELECTRICITY_OPTION,
} from './constants';

export function saveServiceOptionAction(data) {
  return {
    type: SAVE_INFO_SERVICES_OPTIONS,
    payload: data,
  };
}

export function updateServiceOptionAction(data) {
  return {
    type: UPDATE_INFO_SERVICES_OPTIONS,
    payload: data,
  };
}

export function requestElecCentralSuccessAction(data) {
  return {
    type: REQUEST_INFO_ELEC_CENTRAL_SUCCESS,
    payload: data,
  };
}

export function setPowerStationValidationAction(data) {
  return {
    type: SET_CENTRAL_VALIDATION,
    payload: data,
  };
}

export function getClosestPowerStationAction(data) {
  return {
    type: GET_CLOSEST_POWER_STATION,
    payload: data,
  };
}

export const switchElectricityOptionAction = (data) => ({
  type: SWITCH_ELECTRICITY_OPTION,
  payload: data,
});
