export const radius = {
  none: '0',
  '2xs': '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '40px',
  full: '9999px',
};
