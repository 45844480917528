import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOADING_REDUCER } from './LoadingModal.constants';

export interface LoaderReducerType {
  loading: boolean;
}

export const initialState: LoaderReducerType = {
  loading: false,
};

export const loaderSlice = createSlice({
  name: LOADING_REDUCER,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = loaderSlice.actions;
export default loaderSlice.reducer;
