import { SaveProgressionReducerType } from 'src/components/organisms/SaveProgressionModal/types';
import { LOADING, CLOSE_WINDOW, OPEN_WINDOW, SAVE_EMAIL_SUCCESS } from './constants';

export const initialState: SaveProgressionReducerType = {
  open: false,
  loading: false,
};

// Todo: refactoring - replace local redux state by useState (or move to App reducer if not possible)
function SaveProgressionReducer(state: SaveProgressionReducerType = initialState, action) {
  switch (action.type) {
    case CLOSE_WINDOW: {
      return {
        open: false,
      };
    }
    case OPEN_WINDOW: {
      return {
        open: true,
      };
    }
    case SAVE_EMAIL_SUCCESS: {
      return {
        ...state,
        hasSaved: action.payload,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
}

export default SaveProgressionReducer;
