import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import { GetMandateRequestType } from 'src/hocs/WithBilling/types';
import { PaymentDayEnum } from 'src/hocs/WithSubscriptionNew/types';

export const WITH_BILLING = 'WithBilling';

export const GET_BILLING_METHODS_REQUEST = 'app/WithBilling/GET_BILLING_METHODS_REQUEST';
export const GET_BILLING_METHODS_SUCCESS = 'app/WithBilling/GET_BILLING_METHODS_SUCCESS';
export const GET_BILLING_METHODS_FAILURE = 'app/WithBilling/GET_BILLING_METHODS_FAILURE';

export const GET_MONTHLY_BILL_REQUEST = 'app/WithBilling/GET_MONTHLY_BILL_REQUEST';
export const GET_MONTHLY_BILL_SUCCESS = 'app/WithBilling/GET_MONTHLY_BILL_SUCCESS';
export const GET_MONTHLY_BILL_FAILURE = 'app/WithBilling/GET_MONTHLY_BILL_FAILURE';

export const GET_MANDATE_REQUEST = 'app/WithBilling/GET_MANDATE_REQUEST';
export const GET_MANDATE_SUCCESS = 'app/WithBilling/GET_MANDATE_SUCCESS';
export const GET_MANDATE_FAILURE = 'app/WithBilling/GET_MANDATE_FAILURE';
export const RESET_MANDATE = 'app/WithBilling/RESET_MANDATE';

const buildGetMandateFields = fieldsTypeCheckerFactory<GetMandateRequestType>();
export const GET_MANDATE_FIELDS = buildGetMandateFields({
  IBAN: 'iban',
  SUBSCRIPTION_ID: 'subscriptionId',
});

export const LEVY_DAYS = [PaymentDayEnum.DAY_5, PaymentDayEnum.DAY_15, PaymentDayEnum.DAY_25];
