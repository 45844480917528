import { fieldsTypeCheckerFactory } from 'src/utils/typescript/typescript.helper';
import * as t from './types';
import { PromoCodeTypeEnum, PromoCodeTypeOfPromoEnum } from './types';

/**
 * REDUX
 */

export const WITH_PROMO_CODE = 'WithPromoCode';

export const CREATE_PROMO_CODE_REQUEST = 'app/PromoCode/CREATE_PROMO_CODE_REQUEST';
export const CREATE_PROMO_CODE_SUCCESS = 'app/PromoCode/CREATE_PROMO_CODE_SUCCESS';
export const CREATE_PROMO_CODE_FAILURE = 'app/PromoCode/CREATE_PROMO_CODE_FAILURE';

export const SET_CODE = 'app/PromoCode/SET_CODE';
export const TRIGGER_PROMO_CODE_REQUEST = 'app/PromoCode/TRIGGER_PROMO_CODE_REQUEST';

/**
 * API FIELDS
 */
const buildPromoCodeFields = fieldsTypeCheckerFactory<t.PromoCodeRequestType>();

export const PROMO_CODE_FIELDS = buildPromoCodeFields({
  CODE: 'code',
  CUSTOMER_TYPE: 'customerType',
} as const);

export const PROMO_CODE_MESSAGE_NAMES = {
  [PromoCodeTypeOfPromoEnum.EURO]: PromoCodeTypeOfPromoEnum.EURO,
  [PromoCodeTypeOfPromoEnum.MEMBERSHIP]: PromoCodeTypeOfPromoEnum.MEMBERSHIP,
  [PromoCodeTypeOfPromoEnum.KWH]: PromoCodeTypeOfPromoEnum.KWH,
  [PromoCodeTypeEnum.PREPAYMENT]: PromoCodeTypeEnum.PREPAYMENT,
} as const;
