import { useEffect, useRef } from 'react';

type HookReturnType = () => boolean;

/**
 * A custom useEffect hook that tell if the component is mounted
 */
export const useIsComponentMounted = (): HookReturnType => {
  const isMounted = useRef<boolean>(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  return () => isMounted?.current;
};
