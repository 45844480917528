/**
 * Taken from https://github.com/Chris-Baker/Emotion-RGBA
 */

interface RGB {
  red: number;
  green: number;
  blue: number;
}

// compile this once
const nonHexChars = /[^#a-f\d]/gi;

/**
 * Convert a hex color to an RGB object. Shorthand hex (#fff) or expanded hex (#ffffff) are supported.
 * from https://github.com/Chris-Baker/Emotion-RGBA
 */
function hexRgb(colorHex: string): RGB {
  let hex = colorHex;
  // sanitise our inputs
  if (
    !hex ||
    !(hex.length === 4 || hex.length === 7) || // Cheap check for length
    hex[0] !== '#' || // Cheap check for correct first char
    nonHexChars.test(hex) // More expensive check for any non hex chars
  ) {
    throw new Error('Expected a valid hex string');
  }

  // remove the # from the start of the string
  hex = hex.substring(1);

  // if we have a shorthand hex then expand it
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert into our rbg values
  const num = parseInt(hex, 16);
  // eslint-disable-next-line no-bitwise
  const red = num >> 16;
  // eslint-disable-next-line no-bitwise
  const green = (num >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const blue = num & 255;

  return { red, green, blue };
}

/**
 * Convert a hex or named color and alpha into an rgba string directly usable in CSS.
 * For example: rgba('red', 1) -> 'rgba(255, 0, 0, 1)'
 *
 * @param {string} color the color to convert, this can be a hex value or a known HTML color string
 * @param {number} alpha the opacity of the color in the range 0 to 1 with 0.5 being 50%
 * @return {string} an rgba string of the form 'rgba(255, 255, 255, 1)'
 * @throws {Error} will throw an error if color is not a valid hex or named color
 *
 * from https://github.com/Chris-Baker/Emotion-RGBA
 */
export function convertHexColorToRgba(color: string, alpha: number): string {
  // 1. match the color to either and known color string or a hex value
  // look at first character to see if it is a #. If not then we need to look up the hex value
  const hexColor = color;

  // 2. make sure our opacity is in a sensible range 0 to 1
  if (alpha < 0 || alpha > 1) {
    throw new Error(`Invalid opacity '${alpha}'. Opacity should be in the range 0 to 1`);
  }

  // 3. convert our hex value to rgb
  try {
    const rgb: RGB = hexRgb(hexColor);
    return `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${alpha})`;
  } catch (error) {
    console.error('Something went wrong in convertHexColorToRgba() - color', error);
    throw new Error(
      `Invalid color '${hexColor}'. Color should be a valid hexadecimal value, for example '#FFFFFF'`,
    );
  }
}

/**
 * This function is used to set which color (a light one or a dark one) a child component has to use, within the parent component background color.
 * If the YIQ is above 128, it means that the color used in parameter is light, so we will have to use a dark color on it (that's the type of color the child component will have to use), or the contrast won't be ok.
 * If the YIQ is under 128, we will have to use a light color for the child component.
 *
 * @param {[key: string]: string} color the parent background color to check. It has to be a hexa value with or without #
 * @returns {boolean} a boolean to know if the child where the function is uses has to use a light color. If not, it will use a dark one. A condition is set in its style component to get the good color value.
 */
export const isLightColorContrasted = ({ parentHexaColor }: { [key: string]: string }): boolean => {
  // Remove leading #
  let colorOnly;
  if (parentHexaColor.slice(0, 1) === '#') {
    colorOnly = parentHexaColor.slice(1);
  } else {
    colorOnly = parentHexaColor;
  }

  // Convert to RGB value
  const r = parseInt(colorOnly.substr(0, 2), 16);
  const g = parseInt(colorOnly.substr(2, 2), 16);
  const b = parseInt(colorOnly.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq: number = (r * 299 + g * 587 + b * 114) / 1000;

  return !(yiq >= 128);
};