import * as c from './constants';
import * as t from './types';

export const initialState: t.ServicesReducerType = {
  loading: false,
  serviceIdLoading: null,
  error: null,
  services: null,
};

function withServicesReducer(state = initialState, { type, payload }): t.ServicesReducerType {
  switch (type) {
    case c.GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case c.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: payload,
        loading: false,
      };
    case c.GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case c.SET_SERVICE_ID_LOADING: {
      return {
        ...state,
        serviceIdLoading: payload,
      };
    }
    default:
      return state;
  }
}

export default withServicesReducer;
