const API_LEGACY_ROUTES = {
  billing: 'billing',
  companyInfo: 'company_info/:siret',
  consents: 'consents-to-collect',
  closestPowerStation: 'closestPowerStation',
  devis: 'devis',
  eldContact: 'eld_contact',
  eligibility: 'eligibility',
  indexIntegrity: 'index_integrity',
  ineligibility: 'ineligibility',
  estimate: 'estimate',
  options: 'options',
  paymentSuccess: ':api/billing/payment/result?redirect_success=:url',
  podInfo: 'pod_info',
  prefill: 'encryption/decrypt-subscription-link-with-data/:data',
  quotations: 'quotations',
  services: 'services',
  subscriptions: 'subscriptions',
  subscriptionByType: 'subscription_type',
  preSubscriptions: 'pre-subscriptions',
};

const API_V1_ROUTES = {
  productsInformation: 'v1/products-information',
};

const API_ROUTES: Record<string, string> = { ...API_LEGACY_ROUTES, ...API_V1_ROUTES };

export default API_ROUTES;
