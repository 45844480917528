import { FormDataType } from 'src/utils/form/form.helper.types';
import { EnergyChoiceFormData } from 'src/components/pages/ChoixEnergieNew';

export enum EnergyCardsType {
  CONSUMPTION_CARD = 'CONSUMPTION_CARD',
  CONSUMPTION_CARD_POD = 'CONSUMPTION_CARD_POD',
  ESTIMATE_CARD = 'ESTIMATE_CARD',
  CONSUMPTION_FORM = 'CONSUMPTION_FORM',
  CONSUMPTION_BY_NAME_FORM = 'CONSUMPTION_BY_NAME_FORM',
  POD_FORM = 'POD_FORM',
  PMES_CARD = 'PMES_CARD',
}

export type FormCardsType =
  | EnergyCardsType.CONSUMPTION_FORM
  | EnergyCardsType.POD_FORM
  | EnergyCardsType.CONSUMPTION_BY_NAME_FORM
  | EnergyCardsType.ESTIMATE_CARD;

export interface GasCardProps {
  formData: FormDataType<EnergyChoiceFormData>;
  setGasCard: (newCard: EnergyCardsType) => void;
  editGasCard: () => void;
  card: EnergyCardsType;
  hasCheckbox: boolean;
  isProfessional: boolean;
  onClickAnonymousProConsumptionByNameLink: () => void;
}
export interface ElectricityCardProps {
  formData: FormDataType<EnergyChoiceFormData>;
  card: EnergyCardsType;
  setElectricityCard: (newCard: EnergyCardsType) => void;
  editElectricityCard: () => void;
  hasCheckbox: boolean;
  isProfessional: boolean;
  onClickAnonymousProConsumptionByNameLink: () => void;
}
