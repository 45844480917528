import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ESTIMATION_FORM_INPUT,
  ESTIMATION_FORM_REDUCER,
  HotWaterUse,
  LightningType,
  OccupationTime,
  TariffOption,
} from 'src/components/pages/Estimation/constants';
import { EstimatorFormType, EstimatorReducer } from './EstimationForm.types';

export const initialState: EstimatorReducer = {
  // STEP 1: LODGMENT
  [ESTIMATION_FORM_INPUT.HOUSING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.SURFACE]: '',
  [ESTIMATION_FORM_INPUT.CONSTRUCTION_YEAR]: '',
  [ESTIMATION_FORM_INPUT.HAS_INSULATION_WORK]: '',
  [ESTIMATION_FORM_INPUT.INSULATION_YEAR]: '',
  [ESTIMATION_FORM_INPUT.PEOPLE_COUNT]: '',
  [ESTIMATION_FORM_INPUT.OCCUPATION_TIME]: OccupationTime.ALWAYS, // Not handle in the form

  // STEP 2: USAGE
  [ESTIMATION_FORM_INPUT.HEATING_TEMPERATURE]: '',
  [ESTIMATION_FORM_INPUT.HOT_WATER_USE]: HotWaterUse.SHOWER_BATH, // Not handle in the form
  [ESTIMATION_FORM_INPUT.HOUSE_HEATING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.ALTERNATIVE_HEATING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.ELECTRIC_HEATING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.WATER_HEATING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.HOUSING_COOKING]: '',
  [ESTIMATION_FORM_INPUT.LIGHTNING_TYPE]: LightningType.NORMAL, // Not handle in the form

  // STEP 3: METER & EQUIPMENT
  [ESTIMATION_FORM_INPUT.SUBSCRIBED_POWER]: '',
  [ESTIMATION_FORM_INPUT.TARIFF_OPTION]: TariffOption.BASE,
  [ESTIMATION_FORM_INPUT.WASHING_MACHINE]: false, // Not handle in the form
  [ESTIMATION_FORM_INPUT.FREEZER]: false, // Not handle in the form
  [ESTIMATION_FORM_INPUT.DISHWASHER]: false, // Not handle in the form
  [ESTIMATION_FORM_INPUT.DRYER]: false, // Not handle in the form
  [ESTIMATION_FORM_INPUT.GAS_OVEN]: false, // Not handle in the form
  [ESTIMATION_FORM_INPUT.AIR_CONDITIONING_TYPE]: '',
  [ESTIMATION_FORM_INPUT.HAS_ELECTRIC_VEHICLE]: '',
  [ESTIMATION_FORM_INPUT.ELECTRIC_VEHICLE_CHARGING]: '',
  [ESTIMATION_FORM_INPUT.HAS_POOLS]: '',
  [ESTIMATION_FORM_INPUT.POOL_HEATING_TYPE]: '',
};

const updateForm = (state: EstimatorFormType, action: PayloadAction<EstimatorFormType>) => {
  Object.assign(state, action.payload);
};

const resetFieldState = (state: EstimatorFormType, action: PayloadAction<string>) => {
  const fieldName = action.payload;
  state[fieldName] = initialState[fieldName];
};

const estimationFormSlice = createSlice({
  name: ESTIMATION_FORM_REDUCER,
  initialState,
  reducers: {
    updateEstimationForm: updateForm,
    resetField: resetFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestEstimation: (_state, _action) => {},
  },
});

export const { updateEstimationForm, resetField, requestEstimation } = estimationFormSlice.actions;
export default estimationFormSlice.reducer;
