/* eslint-disable import/no-duplicates */
/**
 * Take a date or a valid or not DateString and return a
 * DateString with a correct format for the Input HTMLElement
 * @param {Date|DateString} date
 */

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isWithinRange from 'date-fns/isWithinInterval';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';

import { ERRORS_DATE } from 'src/utils/constants';

export function formatDate(date) {
  if (!date || date === 'Invalid Date') return null; // eslint-disable-line
  return format(new Date(date), 'dd/MM/yyyy');
}

/**
 * Converts a date string with dd/MM/yyyy format to Date object
 * In cae of error, returns either null or a Date
 *
 * @param dateStr string with dd/MM/yyyy
 * @returns Date
 */
export function convertDisplayToDate(dateStr: string): Date | null {
  if (!dateStr || dateStr === ERRORS_DATE.INVALID_DATE) return null;
  const parsedDate = parse(dateStr, 'dd/MM/yyyy', new Date());
  if (parsedDate.toString() === ERRORS_DATE.INVALID_DATE) return new Date(1980, 1, 1);
  return parsedDate;
}

export function convertApiToDisplay(dateStr) {
  if (!dateStr || dateStr === 'Invalid Date') return null;
  const realDate = parse(dateStr, 'yyyy-MM-dd', new Date());
  return formatDate(realDate);
}

export function convertDisplayToAPI(dateStr) {
  if (!dateStr || dateStr === 'Invalid Date') return null;
  const realDate = convertDisplayToDate(dateStr);
  return format(realDate, 'yyyy-MM-dd');
}

export function convertApiDateToDate(dateStr) {
  if (!dateStr || dateStr === ERRORS_DATE.INVALID_DATE) return null;
  const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
  if (parsedDate.toString() === ERRORS_DATE.INVALID_DATE) return null;
  return parsedDate;
}

// Todo: test if time is aligned to French time independent of browser time
export const isBusinessHour = () => {
  const now = new Date();
  let startHour = new Date().setHours(9, 30);
  let endHour = new Date().setHours(19, 0);
  // deal with weekend hours
  if (isSunday(now)) return false;
  if (isSaturday(now)) {
    startHour = new Date().setHours(10, 0);
    endHour = new Date().setHours(18, 0);
  }
  return isWithinRange(now, { start: startHour, end: endHour });
};

/**
 * Takes a Date and converts it to 'dd/mm/YYYY' string
 * @param date
 * @returns string in the format dd/mm/yyyy
 */
export const formatCalendarDate = (date: Date | string): string => {
  if (date == null || date == 'Invalid Date') return null; // eslint-disable-line
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[day, month, year].join('/')}`;
};
