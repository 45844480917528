import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectEligibility, makeSelectPODType } from './selectors';
import * as t from './types';

function getDisplayName<T>(WrappedComponent: React.ComponentType<T>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type PropsType = any;

/* @deprecated in favor of react-redux hooks useSelector and useDispatch */
const WithEligibility = (
  WrappedComponent: React.ComponentType,
): React.ComponentType<WithEligibilityProps> => {
  class HOC extends React.PureComponent<PropsType> {
    // eslint-disable-next-line react/static-property-placement
    displayName = `WithEligibility(${getDisplayName(WrappedComponent)})`;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return compose(withConnect)(HOC);
};

interface WithEligibilityProps {
  eligibility: t.EligibilityResponseType;
  PODType: t.PodRegexResponseType;
}

const mapStateToProps = () =>
  createStructuredSelector({
    eligibility: makeSelectEligibility(),
    PODType: makeSelectPODType(),
  });

const withConnect = connect(mapStateToProps);

export default WithEligibility;
