import * as t from './types';
import * as c from './constants';

export const initialState: t.WithPromoCodeReducerType = {
  promoCode: null,
  error: null,
  loading: false,
  code: '',
};

// TODO inject the reducer
export function WithPromoCodeReducer(
  state = initialState,
  action: t.PromoCodeActionsType,
): t.WithPromoCodeReducerType {
  switch (action?.type) {
    case c.CREATE_PROMO_CODE_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case c.CREATE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        promoCode: action?.payload,
      };
    }
    case c.CREATE_PROMO_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case c.SET_CODE: {
      return {
        ...state,
        code: action?.payload,
      };
    }
    default:
      return state;
  }
}
