import * as T from './types';
import * as C from './constants';

export const initialState: T.WithPaymentsReducerType = {
  payments: {
    deferredAmountToPay: 75,
    immediateAmountToPay: 125,
    details: [],
  },
};

function WithPaymentsReducer(state = initialState, action): T.WithPaymentsReducerType {
  switch (action.type) {
    case C.REQUEST_PAYMENTS_SUCCESS_NEW:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
}

export default WithPaymentsReducer;
