import { ServicesOptionsReducerType } from 'src/components/pages/ServicesOptions/types';
import {
  REQUEST_INFO_ELEC_CENTRAL_SUCCESS,
  SET_CENTRAL_VALIDATION,
  GET_CLOSEST_POWER_STATION,
  SWITCH_ELECTRICITY_OPTION,
} from './constants';

export const initialState: ServicesOptionsReducerType = {
  options: null,
  centralValidation: false,
  closestPowerStation: null,
};

// Todo: refactoring - replace local redux state by useState (or move to App reducer if not possible)
function ServiceOptionReducer(state = initialState, action) {
// TODO type this more strictly
// function ServiceOptionReducer(state = initialState, action): ServicesOptionsReducerType {
  switch (action.type) {
    case REQUEST_INFO_ELEC_CENTRAL_SUCCESS:
      return {
        ...state,
        options: action.payload,
      };
    case SET_CENTRAL_VALIDATION:
      return {
        ...state,
        centralValidation: action.payload,
      };
    case GET_CLOSEST_POWER_STATION:
      return {
        ...state,
        closestPowerStation: action.payload,
      };
    case SWITCH_ELECTRICITY_OPTION:
      return {
        ...state,
        electricityOption: action.payload,
      };
    default:
      return state;
  }
}

export default ServiceOptionReducer;
