import { SET_CURRENT_ADDRESS } from './constants';
import * as t from './types';

// Todo: this reducer should be moved into App/currentAddress reducer
export const initialState: t.WithAddressReducerType = {
  currentAddress: {
    streetNumber: null,
    streetName: null,
    zipCode: null,
    city: null,
    codeInsee: null,
    supplierInterventionDate: null,
    supplierInterventionDuration: null,
    supplierInterventionType: null,
    firstName: null,
    lastName: null,
  },
};

function withAddressReducer(state = initialState, action): t.WithAddressReducerType {
  switch (action.type) {
    case SET_CURRENT_ADDRESS: {
      return {
        ...state,
        currentAddress: action.payload,
      };
    }
    default:
      return state;
  }
}

export default withAddressReducer;
